import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useHistory, useParams, Link } from "react-router-dom";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "../../../components/Card/MainCard";
import axios from "axios";
import ConfirmationModal from "../../ui-elements/basic/ConfirmationModal";
import { usePermissions } from "../../../permissions";
import config from "../../../config";
import DataTable from "react-data-table-component";
import PiVac from "./BasicCollapse06";

const SamplePage = () => {
  const account = useSelector((state) => state.account);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [feedCost, setFeedCost] = useState(0);
  const [selectedFeed, setSelectedFeed] = useState(null);
  const [feedOptions, setFeedOptions] = useState([]);
  const formRef = useRef(null);
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [accounts, setAccounts] = useState([]);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedSup, setSelectedSup] = useState([]);
  const generateTransactionId = () => {
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 1000);
    return `SF-${random}`;
  };
  const [farmOptions, setFarmOptions] = useState([]);
  const [farm, setSelectedFarm] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { createPermission, deletePermission, editPermission } =
    usePermissions();
  const [searchText, setSearchText] = useState("");
  const [activityLog, setActivityLog] = useState([]);
  const [isPopupVisible, setPopupVisible] = useState(false);

  const memoizedApiUrl1 = useMemo(() => {
    return `${apiUrl}/weaners_group_list`;
  }, [apiUrl]);

  useEffect(() => {
    axios
      .get(memoizedApiUrl1)
      .then((response) => {
        setActivityLog(response.data.reverse());
      })
      .catch((error) => {
        setError("Error fetching activity log:", error);
      });
  }, [apiUrl, activityLog]);

  const columns = [
    // {
    //     name: 'SIRe Id',
    //     selector: (row) => row.tagNumber,
    //     sortable: true
    // },
    {
      name: "Timestamp",
      selector: (row) => new Date(row?.createdAt).toLocaleString(),
      // selector: (row) => new Date(row.recorded).toDateString(),
      sortable: true,
      wrap: true,
    },
    {
      name: "Recorded At",
      selector: (row) => new Date(row.recorded).toDateString(),
      sortable: true,
      wrap: true,
    },
    {
      name: "Tag No",
      selector: (row) => row.tags,
      sortable: true,
      wrap: true,
    },

    {
      name: "Group",
      selector: (row) => row.weanersGroup,
      sortable: true,
    },
    {
      name: "House",
      selector: (row) => row.houseNumber,
      sortable: true,
      wrap: true,
    },
    {
      name: "Capacity",
      selector: (row) => row.pigletsSired,
      sortable: true,
      wrap: true,
    },

    {
      name: "DOB",
      selector: (row) => new Date(row.dob).toLocaleDateString(),
      sortable: true,
      wrap: true,
    },
  ];

  const hidePopup = () => {
    setPopupVisible(false);
  };

  const showPopup = () => {
    setPopupVisible(true);
  };

  const filteredData = searchText
    ? activityLog.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchText.toLowerCase()),
        ),
      )
    : activityLog;

  return (
    <React.Fragment>
      <Row className="btn-page">
        <Col>
          <div className="d-flex justify-content-between align-center m-b-10">
            <div>
              <span> </span>
            </div>
            {createPermission && (
              <Button onClick={showPopup} className="mb-10">
                <span className="feather icon-plus-circle"></span> Move Piglets
              </Button>
            )}
            {isPopupVisible && <PiVac onCancel={hidePopup} />}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Form.Group
              as={Col}
              md={1}
              sm={12}
              lg={1}
              controlId="formBasicName"
            >
              <Form.Label>Search</Form.Label>
            </Form.Group>
            <Form.Group
              as={Col}
              md={3}
              sm={12}
              lg={3}
              controlId="formBasicName"
            >
              <Form.Control
                type="text"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Form.Group>
          </Row>
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            highlightOnHover
            striped
            // noHeader
            dense
            bordered
            paginationPerPage={20}
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
            paginationComponentOptions={{
              rowsPerPageText: "Rows per page:",
            }}
            paginationResetDefaultPage={true}
            paginationTotalRows={activityLog.length}
            // expandableRows
            selectableRows
            onSelectedRowsChange={(selectedRows) => console.log(selectedRows)}
            customStyles={{
              headRow: {
                style: {
                  backgroundColor: "#279e6c", // Green background
                  color: "#fff", // White text
                  textTransform: "uppercase", // Uppercase text
                  // fontSize: '10px', // Adjust font size for header row
                  minHeight: "1.5rem",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                },
              },
            }}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SamplePage;
