import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  Row,
  Col,
  Form,
  Button,
  Table,
  Alert,
  Tabs,
  Tab,
  Modal,
  Pagination,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import Card from "../../components/Card/MainCard.js";
import config from "../../config.js";
import Receipt from "./GeneralReceipt01.js";
import { Link, useHistory } from "react-router-dom";
import { usePermissions } from "../../permissions";
import DataTable from "react-data-table-component";
import PiVac from "./SamplePage200.js";
import DirectExpenses from "./SamplePage0152.js";

const TransactionHistory = () => {
  const [error, setError] = useState(null);
  const formRef = useRef(null);
  const history = useHistory();
  const [success, setSuccess] = useState(null);
  const [transactionData, setTransactionData] = useState([]);
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [receiptData, setReceiptData] = useState(null);
  const [activityLog, setActivityLog] = useState([]);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { createPermission, deletePermission, editPermission, viewPermission } =
    usePermissions();

  useEffect(() => {
    const fetchTransactionData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/general-expense`);
        setActivityLog(response.data);
      } catch (error) {
        console.error("Error fetching stock history:", error);
        toast.error("Error fetching stock history.");
      }
    };

    fetchTransactionData();
  }, [apiUrl, activityLog]);

  const csvLinkRef = useRef();

  const exportToCSV = () => {
    const csvData = [
      ["Description", "Supplier", "Amount", "For", "Date"],
      ...filteredData.map((item) => [
        item.remarks,
        item.sup_name,
        item.cost?.toLocaleString(),
        item.water_user,
        item.createdAt,
      ]),
    ];

    const csvContent = csvData.map((row) => row.join(",")).join("\n");
    const blob = new Blob(["\ufeff", csvContent], {
      type: "text/csv;charset=utf-8;",
    });

    const csvUrl = URL.createObjectURL(blob);
    csvLinkRef.current.href = csvUrl;
    csvLinkRef.current.download = "sfarms.csv";
    csvLinkRef.current.click();
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [["Description", "Supplier", "Amount", "For", "Date"]],
      body: filteredData.map((user) => [
        user.remarks,
        user.sup_name,
        user.cost?.toLocaleString(),
        user.water_user,
        new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date(user.createdAt)),
      ]),
    });
    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl);
  };

  const handleView = (id, description) => {
    const transactionToUpdate = transactionData.find(
      (transaction) => transaction.id === id,
    );
    history.push(`stock-expense/view/${id}/${description}`, {
      transaction: transactionToUpdate,
    });
  };

  const handleViewFile = (image) => {
    window.open(`${apiUrl}/uploads/${image}`, "_blank");
  };

  const columns = [
    {
      name: "Timestamp",
      selector: (row) => new Date(row?.createdAt).toLocaleString(),
      // selector: (row) => new Date(row.recorded).toDateString(),
      cell: (row) => (
        <Link to={`/general-expense/view/${row.id}`}>
          {new Date(row.createdAt).toLocaleString()}
        </Link>
      ),
      sortable: true,
      wrap: true,
    },

    {
      name: "For",
      selector: (row) => row.water_user,
      cell: (row) => (
        <Link to={`/general-expense/view/${row.id}`}>{row.water_user}</Link>
      ),
      sortable: true,
      wrap: true,
    },

    {
      name: "AMOUNT",
      selector: (row) => row.cost,
      sortable: true,
      cell: (row) => (
        <Link to={`/general-expense/view/${row.id}`}>
          {row.cost?.toLocaleString("en-US", { minimumFractionDigits: 2 })}
        </Link>
      ),
    },
    {
      name: "Supplier",
      selector: (row) => row.sup_name,
      cell: (row) => (
        <Link to={`/general-expense/view/${row.id}`}>{row.sup_name}</Link>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Description",
      selector: (row) => row.remarks,
      cell: (row) => (
        <Link to={`/general-expense/view/${row.id}`}>{row.remarks}</Link>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Date",
      selector: (row) => new Date(row.recorded).toLocaleDateString(),
      sortable: true,
      cell: (row) => (
        <Link to={`/general-expense/view/${row.id}`}>
          {new Date(row.recorded).toLocaleDateString()}
        </Link>
      ),
      wrap: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          {/* {editPermission && (
                    <Button disabled={!editPermission} size="sm" onClick={() => handleUpdate(row.id)}>
                        <span className="feather icon-edit"></span> Edit
                    </Button>
                )}{' '} */}

          {viewPermission && row.image ? (
            <Button
              disabled={!viewPermission}
              size="sm"
              variant="info"
              onClick={() => handleViewFile(row.image)}
            >
              <span className="feather icon-eye"></span> View Reciept
            </Button>
          ) : (
            <Button disabled={!viewPermission} size="sm" variant="secondary">
              <span className="feather icon-eye"></span> No Reciept
            </Button>
          )}

          {/* {deletePermission && (
                    <Button
                        variant="danger"
                        disabled={!deletePermission}
                        size="sm"
                        onClick={() => handleDelete(row.id, row.asset_name)}
                    >
                        <span className="feather icon-trash-2"></span> Delete
                    </Button>
                )} */}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      // button: true,
      // right: true
    },
  ];

  const hidePopup = () => {
    setPopupVisible(false);
  };

  const showPopup = () => {
    setPopupVisible(true);
  };

  const filteredData = searchText
    ? activityLog.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchText.toLowerCase()),
        ),
      )
    : activityLog;

  return (
    <React.Fragment>
      <Tabs defaultActiveKey="timeline">
        <Tab eventKey="timeline" title="GENERAL EXPENSES" className="mt-4">
          <Row className="btn-page">
            <Col>
              <div className="d-flex justify-content-between align-center m-b-10">
                {createPermission && (
                  <Button onClick={showPopup} className="mb-10">
                    <span className="feather icon-plus-circle"></span> Record
                    Expense
                  </Button>
                )}
                {isPopupVisible && <PiVac onCancel={hidePopup} />}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {filteredData && filteredData.length > 0 && (
                <Row>
                  <Form.Group
                    as={Col}
                    md={6}
                    sm={12}
                    lg={6}
                    controlId="formBasicName"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Search..."
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={6}
                    sm={12}
                    lg={6}
                    controlId="formBasicName"
                  >
                    <div className="d-flex">
                      <Button onClick={exportToCSV}>
                        <span className="feather icon-file"></span> Export CSV
                      </Button>
                      <a ref={csvLinkRef} style={{ display: "none" }} />
                      <a
                        href="#"
                        className="btn btn-primary"
                        onClick={exportToPDF}
                      >
                        <span className="feather icon-file-text"></span> Export
                        PDF
                      </a>
                    </div>
                  </Form.Group>
                </Row>
              )}
              <Row>
                <Col>
                  <DataTable
                    columns={columns}
                    data={filteredData}
                    pagination
                    highlightOnHover
                    striped
                    // noHeader
                    dense
                    bordered
                    paginationPerPage={20}
                    paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                    paginationComponentOptions={{
                      rowsPerPageText: "Rows per page:",
                    }}
                    paginationResetDefaultPage={true}
                    paginationTotalRows={activityLog.length}
                    // expandableRows
                    selectableRows
                    onSelectedRowsChange={(selectedRows) =>
                      console.log(selectedRows)
                    }
                    customStyles={{
                      headRow: {
                        style: {
                          backgroundColor: "#279e6c", // Green background
                          color: "#fff", // White text
                          textTransform: "uppercase", // Uppercase text
                          // fontSize: '10px', // Adjust font size for header row
                          minHeight: "1.5rem",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                        },
                      },
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="direct" title="DIRECT EXPENSES" className="mt-4">
          <DirectExpenses />
        </Tab>
      </Tabs>
    </React.Fragment>
  );
};

export default TransactionHistory;
