import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Form,
  Tab,
  Tabs,
  Alert,
  Button,
  Modal,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../../config";
import ConfirmationModal from "./ConfirmationModal";
import Card from "../../../components/Card/MainCard";
import axios from "axios";
import { usePermissions } from "../../../permissions";

const SamplePage = ({ onCancel }) => {
  const account = useSelector((state) => state.account);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const formRef = useRef(null);
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { createPermission, deletePermission, editPermission } =
    usePermissions();

  const memoizedApiUrl1 = useMemo(() => {
    return `${apiUrl}/farm`;
  }, [apiUrl]);

  const handleInput = (e) => {
    const { name, value } = e.target;

    setData({
      ...data,
      [name]: value,
      userId: account.user.id,
      userName: account.user.firstname,
    });
    console.log(data);
  };

  const handleGoat = async (e) => {
    e.preventDefault();
    setShowConfirmationModal(true);
  };

  const handleConfirmation = async (e) => {
    if (!createPermission) {
      return;
    }
    try {
      const response = await axios.post(`${apiUrl}/new-farm`, data);

      if (response.data.message === "Farm already exists") {
        toast.error(`${data.name} already exists`);
      } else {
        setSuccess(`${data.name} added successfully`);
        setTimeout(() => {
          setSuccess(null);
        }, 10000);
        toast.success(`${data.name} added successfully`);
        formRef.current.reset();
        onCancel();
        setShowConfirmationModal(false);
      }
    } catch (error) {
      toast.error(error.message);
      setError(error.message);
      console.log(error.message);
      setShowConfirmationModal(false);
    }
  };

  const handleCancelConfirmation = () => {
    // Handle cancel action if needed
    setShowConfirmationModal(false);
  };

  return (
    <React.Fragment>
      <Modal show={true} onHide={onCancel} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Farm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              {success && (
                <>
                  <Alert
                    variant="success"
                    onClose={() => setSuccess(null)}
                    dismissible
                  >
                    {success}
                  </Alert>
                </>
              )}
              {error && (
                <>
                  <Alert
                    variant="danger"
                    onClose={() => setError(null)}
                    dismissible
                  >
                    {error}
                  </Alert>
                </>
              )}
              <ToastContainer />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form onSubmit={handleGoat} ref={formRef}>
                <Row>
                  <Form.Group
                    as={Col}
                    md={4}
                    sm={12}
                    lg={4}
                    controlId="formBasicAddress"
                  >
                    <Form.Label>Farm Name:</Form.Label>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={8}
                    sm={12}
                    lg={8}
                    controlId="formBasicAddress"
                  >
                    <Form.Control
                      required
                      onChange={handleInput}
                      type="text"
                      name="name"
                    />
                  </Form.Group>
                </Row>
                <Modal.Footer>
                  {createPermission && <Button type="submit">Submit</Button>}
                  <Button variant="secondary" onClick={onCancel}>
                    Cancel
                  </Button>
                </Modal.Footer>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <ConfirmationModal
        show={showConfirmationModal}
        onHide={handleCancelConfirmation}
        onConfirm={handleConfirmation}
      />
    </React.Fragment>
  );
};

export default SamplePage;
