import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import config from "../../../config";
import Card from "../../../components/Card/MainCard";
import Select from "react-select";
import axios from "axios";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  Row,
  Col,
  Form,
  Button,
  Table,
  Alert,
  Tabs,
  Tab,
  Modal,
  Pagination,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";

const LoginLogs = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchTrialBalance();
  }, [apiUrl, data]);

  const fetchTrialBalance = async () => {
    try {
      const response = await axios.get(`${apiUrl}/logs`);
      const logs = response.data;
      setData(logs);
      // console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  const csvLinkRef = useRef();

  const exportToCSV = () => {
    const csvData = [
      ["User Name", "IP Address", "Browser", "userId", "Action", "Date/Time"],
      ...filteredData.map((item) => [
        item.username,
        item.ip,
        item.browser,
        item.userId,
        item.action,
        item.timestamp,
      ]),
    ];

    const csvContent = csvData.map((row) => row.join(",")).join("\n");
    const blob = new Blob(["\ufeff", csvContent], {
      type: "text/csv;charset=utf-8;",
    });

    const csvUrl = URL.createObjectURL(blob);
    csvLinkRef.current.href = csvUrl;
    csvLinkRef.current.download = "sfarms.csv";
    csvLinkRef.current.click();
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        ["User Name", "IP Address", "Browser", "userId", "Action", "Date/Time"],
      ],
      body: filteredData.map((user) => [
        user.username,
        user.ip,
        user.browser,
        user.userId,
        user.action,
        new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date(user.timestamp)),
      ]),
    });
    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl);
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row?.id,
      sortable: true,
      wrap: true,
    },
    {
      name: "Timestamp",
      selector: (row) => new Date(row.timestamp).toLocaleString(),
      sortable: true,
      wrap: true,
    },
    {
      name: "User Name",
      selector: (row) => row.username,
      sortable: true,
      wrap: true,
    },

    {
      name: "userId",
      selector: (row) => row?.userId,
      sortable: true,
      wrap: true,
    },
    {
      name: "ip Address",
      selector: (row) => row?.ip,
      sortable: true,
      wrap: true,
    },
    {
      name: "action",
      selector: (row) => row?.action,
      sortable: true,
      wrap: true,
    },
  ];

  const filteredData = searchText
    ? data.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchText.toLowerCase()),
        ),
      )
    : data;

  const ExpandableRow = ({ data }) => {
    let location;
    try {
      location = data?.location ? JSON.parse(data.location) : null;
    } catch (error) {
      console.error("Error parsing location JSON:", error);
      location = null;
    }

    if (location && location.time_zone) {
      return (
        <div style={{ padding: "10px 20px" }}>
          <p className="m-0">
            <strong className="text-c-blue">City:</strong> {location.city}
          </p>
          <p className="m-0">
            <strong className="text-c-blue">Region:</strong>{" "}
            {location.state_prov}
          </p>
          <p className="m-0">
            <strong className="text-c-blue">Country:</strong>{" "}
            {location.country_name}
          </p>
          <p className="m-0">
            <strong className="text-c-blue">Timezone:</strong>{" "}
            {location.time_zone.name}
          </p>

          {/* <p className="m-0">
                        <strong>Country flag:</strong> <img src={location.country_flag}/>
                    </p> */}
          <p className="m-0">
            <strong className="text-c-blue">ISP:</strong> {location.isp}
          </p>
          <p className="m-0">
            <strong className="text-c-blue">Latitude/Longitude:</strong> [
            {location.latitude}, {location.longitude}]
          </p>
          <p className="m-0">
            <strong className="text-c-blue">Zip Code:</strong>{" "}
            {location.zipcode || "--"}
          </p>
          <p className="m-0">
            <strong className="text-c-blue">Browser:</strong> {data.browser}
          </p>
        </div>
      );
    } else {
      return (
        <div style={{ padding: "10px 20px" }}>
          <p className="m-0">
            <strong className="text-c-blue">Browser:</strong> {data.browser}
          </p>
          <p className="m-0">No location data available</p>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      <Row className="btn-page">
        <Col>
          {data && data.length > 0 && (
            <Row>
              <Form.Group
                as={Col}
                md={6}
                sm={12}
                lg={6}
                controlId="formBasicName"
              >
                <Form.Control
                  type="text"
                  placeholder="Search..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                md={6}
                sm={12}
                lg={6}
                controlId="formBasicName"
              >
                <div className="d-flex">
                  <Button onClick={exportToCSV}>
                    <span className="feather icon-file"></span> Export CSV
                  </Button>
                  <a ref={csvLinkRef} style={{ display: "none" }} />
                  <a href="#" className="btn btn-primary" onClick={exportToPDF}>
                    <span className="feather icon-file-text"></span> Export PDF
                  </a>
                </div>
              </Form.Group>
            </Row>
          )}
          <Row>
            <Col>
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                highlightOnHover
                striped
                // noHeader
                dense
                bordered
                paginationPerPage={20}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                paginationComponentOptions={{
                  rowsPerPageText: "Rows per page:",
                }}
                paginationResetDefaultPage={true}
                paginationTotalRows={data.length}
                expandableRows
                expandableRowsComponent={ExpandableRow}
                selectableRows
                onSelectedRowsChange={(selectedRows) =>
                  console.log(selectedRows)
                }
                customStyles={{
                  headRow: {
                    style: {
                      backgroundColor: "#279e6c", // Green background
                      color: "#fff", // White text
                      textTransform: "uppercase", // Uppercase text
                      // fontSize: '10px', // Adjust font size for header row
                      minHeight: "1.5rem",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                    },
                  },
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default LoginLogs;
