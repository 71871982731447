import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  Alert,
  Table,
  Badge,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModuleNotification from "../../../components/Widgets/Statistic/Notification";
import config from "../../../config";
import axios from "axios";
import Calendar from "./Calendar";
import EventForm from "./EventForm";
import { usePermissions } from "../../../permissions";
import DataTable from "react-data-table-component";

const TaskEvents = () => {
  const account = useSelector((state) => state.account);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const formRef = useRef(null);
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const { createPermission, deletePermission, editPermission } =
    usePermissions();
  const [showEventForm, setShowEventForm] = useState(false);
  const userId = account.user.id;
  const [searchText, setSearchText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedEventType, setSelectedEventType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [rowToUpdate, setRowToUpdate] = useState(null);

  // useEffect(() => {
  //     axios
  //         .get(`${apiUrl}/events`)
  //         .then((response) => {
  //             setEvents(response.data);
  //             // console.log(data);
  //         })
  //         .catch((error) => {
  //             console.error(error);
  //         });
  // }, [events, apiUrl]);

  //     useEffect(() => {
  //         const fetchEvents = async () => {
  //           try {
  //             let url = `${apiUrl}/events`;

  //             // Construct query parameters based on userId and event type
  //             const params = {
  //               userId: userId
  //             };

  //             // Append query parameters to the URL
  //             url += `?${new URLSearchParams(params).toString()}`;

  //             // const response = await axios.get(url);
  //             const response = await axios.get(url);
  // console.log('API Response:', response.data); // Log API response for debugging

  //             const filteredEvents = response.data.filter((event) => {
  //               if (event.eventType === 'event' && event.alerts === true) {
  //                 return true; // Include events of type 'event' with alerts
  //               } else if (event.eventType === 'task' && event.employeeSelectOption) {
  //                 // Check if the event's employeeSelectOption contains userId
  //                 return event.employeeSelectOption.includes(userId);
  //               }
  //               return false;
  //             });

  //             setEvents(filteredEvents);
  //             console.log(filteredEvents)
  //           } catch (error) {
  //             console.error('Error fetching events:', error);
  //           }
  //         };

  //         fetchEvents();
  //     }, [userId, apiUrl]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(apiUrl + "/events");
        console.log("response Events:", response.data);
        const filteredEvents = response.data.filter((event) => {
          console.log("Processing event:", event);
          if (event.eventType === "event" && event.alerts === 1) {
            console.log("Included: eventType is event and alerts are true");
            return true;
          } else if (
            event.eventType === "task" &&
            event.employeeSelectOption &&
            event.employeeSelectOption.includes(userId)
          ) {
            console.log(
              "Included: eventType is task and userId is in employeeSelectOption",
            );
            return true;
          } else if (event.userId === userId) {
            console.log(
              "Included: eventType is task and userId is in employeeSelectOption",
            );
            return true;
          }
          console.log("Excluded event:", event);
          return false;
        });

        console.log("Filtered Events:", filteredEvents);

        // Set the filtered events in state
        setEvents(filteredEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents(); // Fetch events when component mounts

    // Specify dependencies in the array to re-run effect on changes
  }, [userId, apiUrl]);

  const addOrEditEvent = async (event) => {
    try {
      if (event.id) {
        if (!editPermission) {
          return;
        }

        await axios.put(`${apiUrl}/events/${event.id}`, event);
      } else {
        if (!createPermission) {
          return;
        }
        await axios.post(`${apiUrl}/events/new`, event);
      }
      const updatedEvents = [...events];
      const existingEventIndex = events.findIndex((e) => e.id === event.id);
      if (existingEventIndex !== -1) {
        updatedEvents[existingEventIndex] = event;
      } else {
        updatedEvents.push(event);
      }

      setSuccess("Event added/edited successfully");
      setTimeout(() => {
        setSuccess(null);
      }, 10000);
      toast.success("Event added/edited successfully");
      setShowEventForm(false);
    } catch (error) {
      setError(error.message);
    }
  };

  const deleteEvent = async (eventId) => {
    try {
      const confirmed = window.confirm(
        `Are you sure you want to delete this event ${eventId}?`,
      );
      if (confirmed) {
        await axios.delete(`${apiUrl}/events/${eventId}`);
        const updatedEvents = events.filter((event) => event.id !== eventId);
        setEvents(updatedEvents);
        setSuccess("Event deleted successfully");
        setTimeout(() => {
          setSuccess(null);
        }, 10000);
        toast.success("Event deleted successfully");
        setShowEventForm(false);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleShowEventForm = () => {
    setShowEventForm(true);
  };

  const handleCloseEventForm = () => {
    setShowEventForm(false);
    setSelectedEvent(null);
  };

  const handleEventClick = (item) => {
    console.log("Before setting selectedEvent:", selectedEvent);
    setSelectedEvent(item);
    console.log("After setting selectedEvent:", selectedEvent);
    setShowEventForm(true);
  };

  const currentDate = new Date();

  const getEventStatus = (event) => {
    const fromDate = new Date(event.fromDate);
    const toDate = new Date(event.toDate);

    const isUpcoming = fromDate > currentDate;
    const isOngoing = fromDate <= currentDate && toDate >= currentDate;
    const isEnded = toDate < currentDate;

    if (isUpcoming) {
      return { color: "primary", text: "Upcoming" };
    } else if (isOngoing) {
      return { color: "success", text: "Ongoing" };
    } else {
      return { color: "secondary", text: "Ended" };
    }
  };

  const handleCheckboxClick = (row) => {
    setRowToUpdate(row);
    setShowModal(true);
  };

  const handleCheckboxChange = async (rowId) => {
    try {
      const response = await axios.post(`${apiUrl}/events/${rowId}/complete`);
      if (response.status === 200) {
        toast.success(response.data.message);
      }
    } catch (error) {
      if (error.response.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Failed to update, kindly try later");
        console.error("Error updating row:", error);
      }
    }
  };

  const handleConfirm = () => {
    if (rowToUpdate) {
      handleCheckboxChange(rowToUpdate);
    }
    setShowModal(false);
  };

  const columns = [
    {
      name: "Timestamp",
      selector: (row) => (
        <>
          <div className="">
            <>
              <strong className="text-c-blue">FROM:</strong>{" "}
              {new Date(row.fromDate).toLocaleString()}
            </>
          </div>

          <div className="">
            <>
              <strong className="text-c-blue">TO:</strong>{" "}
              {new Date(row.toDate).toLocaleString()}
            </>
          </div>
        </>
      ),
      sortable: true,
      minWidth: "300px",
    },
    {
      name: "Type",
      selector: (row) => (
        <Badge variant={row.eventType === "task" ? "warning" : "primary"}>
          {row.eventType}
        </Badge>
      ),
      sortable: true,
    },
    // {
    //     name: 'Title',
    //     selector: (row) => row.title,
    //     sortable: true,
    //     wrap: true
    // },

    {
      name: "Status",
      selector: (row) => {
        const { color, text } = getEventStatus(row);
        return <Badge variant={color}>{text}</Badge>;
      },
      sortable: true,
    },
    {
      name: "Completed",
      cell: (row) => (
        <>
          {row.completed ? (
            <div>YES</div>
          ) : (
            <>
              {row.eventType === "task" ? (
                <input
                  disabled={!editPermission}
                  type="checkbox"
                  checked={row.completed}
                  onChange={(e) =>
                    handleCheckboxClick(row.id, "completed", e.target.checked)
                  }
                />
              ) : (
                "-"
              )}
            </>
          )}
        </>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          {editPermission ? (
            <Button
              size="sm"
              disabled={!editPermission}
              variant="info"
              onClick={() => handleEventClick(row)}
            >
              <span className="feather icon-edit"></span> Edit
            </Button>
          ) : (
            "-"
          )}
          {deletePermission ? (
            <Button
              size="sm"
              disabled={!deletePermission}
              variant="danger"
              onClick={() => deleteEvent(row.id)}
            >
              <span className="feather icon-trash"></span> Delete
            </Button>
          ) : (
            "-"
          )}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      minWidth: "150px",
      // button: true,
      // right: true
    },
  ];

  const ExpandableComponent = ({ data }) => (
    <div style={{ padding: "10px 20px" }}>
      {/* <h6>Details</h6> */}
      <p className="m-0">
        <strong className="text-c-blue">Title:</strong> {data?.title || "-"}
      </p>
      <p className="m-0">
        <strong className="text-c-blue">Description:</strong>{" "}
        {data?.description || "-"}
      </p>
      {/* <p className='m-0'><strong>Assigned:</strong> {data?.employeeSelectOption || '-'}</p> */}
    </div>
  );

  const filteredData = events.filter((item) => {
    // Check if the item matches the search text
    const matchesSearchText =
      !searchText ||
      Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchText.toLowerCase()),
      );

    // Check if the item matches the selected status filter
    const matchesStatusFilter =
      !selectedStatus ||
      getEventStatus(item).text.toLowerCase() === selectedStatus.toLowerCase();

    // Check if the item matches the selected eventType filter
    const matchesEventTypeFilter =
      !selectedEventType ||
      item.eventType.toLowerCase() === selectedEventType.toLowerCase();

    return matchesSearchText && matchesStatusFilter && matchesEventTypeFilter;
  });

  return (
    <React.Fragment>
      <Row>
        <Col sm={12}>
          {success && (
            <Alert
              variant="success"
              onClose={() => setSuccess(null)}
              dismissible
            >
              {success}
            </Alert>
          )}
          {error && (
            <Alert variant="danger" onClose={() => setError(null)} dismissible>
              {error}
            </Alert>
          )}
          <ToastContainer />
        </Col>
      </Row>

      <Row>
        <Col sm="12">
          <Row>
            <Col sm={4} lg={3} className="mb-2">
              {createPermission && (
                <Button
                  disabled={!createPermission}
                  variant="primary"
                  onClick={() => setShowEventForm(true)}
                >
                  <span className="feather icon-plus"></span> Add Event
                </Button>
              )}
              {showEventForm && (
                <EventForm
                  event={selectedEvent}
                  onAddOrEditEvent={addOrEditEvent}
                  onDeleteEvent={deleteEvent}
                  onClose={handleCloseEventForm}
                />
              )}
            </Col>
            <Col sm={4} lg={3} className="mb-2">
              <Form.Group controlId="eventTypeFilter">
                <Dropdown>
                  <Dropdown.Toggle variant="primary" id="dropdown-event-type">
                    {selectedEventType || "Filter by Event Type"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => setSelectedEventType("event")}
                    >
                      Event
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setSelectedEventType("task")}>
                      Task
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setSelectedEventType("")}>
                      Clear Filter
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            </Col>
            <Col sm={4} lg={3} className="mb-2">
              <Form className="mb-3">
                <Form.Group controlId="statusFilter">
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-status">
                      {selectedStatus || "Filter by Status"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => setSelectedStatus("Upcoming")}
                      >
                        Upcoming
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setSelectedStatus("Ongoing")}
                      >
                        Ongoing
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setSelectedStatus("Ended")}>
                        Ended
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setSelectedStatus("")}>
                        Clear Filter
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
              </Form>
            </Col>
            <Col sm={12} lg={3} className="mb-2">
              <Form.Group
                as={Col}
                md={12}
                sm={12}
                lg={12}
                controlId="formBasicName"
              >
                <Form.Control
                  type="text"
                  placeholder="Search..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                highlightOnHover
                striped
                expandableRows
                expandableRowsComponent={ExpandableComponent}
                // noHeader
                dense
                bordered
                paginationPerPage={20}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                paginationComponentOptions={{
                  rowsPerPageText: "Rows per page:",
                }}
                paginationResetDefaultPage={true}
                paginationTotalRows={events.length}
                onSelectedRowsChange={(selectedRows) =>
                  console.log(selectedRows)
                }
                customStyles={{
                  headRow: {
                    style: {
                      backgroundColor: "#279e6c", // Green background
                      color: "#fff", // White text
                      textTransform: "uppercase", // Uppercase text
                      // fontSize: '10px', // Adjust font size for header row
                      minHeight: "1.5rem",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                    },
                  },
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Completion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to mark this as completed?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default TaskEvents;
