import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Button, Alert, Modal } from "react-bootstrap";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config";
import Card from "../../components/Card/MainCard";
import axios from "axios";
// import Receipt from './Receipt2';
import ConfirmationModal from "../ui-elements/basic/ConfirmationModal";
import { usePermissions } from "../../permissions";

const SamplePage = ({ onCancel }) => {
  const account = useSelector((state) => state.account);
  const dispatcher = useDispatch();
  const [transactionId, setTransactionId] = useState(null);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [selectedFeed, setSelectedFeed] = useState(null);
  const [feedOptions, setFeedOptions] = useState([]);
  const [vaccineOptions, setVaccineOptions] = useState([]);
  const [selectedVaccine, setSelectedVaccine] = useState(null);
  const [vetOptions, setVetOptions] = useState([]);
  const [selectedVet, setSelectedVet] = useState(null);
  const [vaccineCost, setVaccineCost] = useState(0);
  const [showReceipt, setShowReceipt] = useState(false);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const formRef = useRef(null);
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [farmOptions, setFarmOptions] = useState([]);
  const [farm, setSelectedFarm] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { createPermission, deletePermission, editPermission } =
    usePermissions();

  const memoizedApiUrl1 = useMemo(() => {
    return `${apiUrl}/farm`;
  }, [apiUrl]);

  const memoizedApiUrl2 = useMemo(() => {
    return `${apiUrl}/vaccines`;
  }, [apiUrl]);

  const memoizedApiUrl3 = useMemo(() => {
    return `${apiUrl}/suppliers`;
  }, [apiUrl]);

  useEffect(() => {
    const fetchFarms = async () => {
      try {
        const response = await axios.get(memoizedApiUrl1);
        const farmData = response.data;
        const options = farmData.map((farm) => ({
          value: farm.name,
          label: farm.name,
        }));

        setFarmOptions(options);
      } catch (error) {
        console.error("Error fetching Farms:", error);
      }
    };

    fetchFarms();
  }, [memoizedApiUrl1]);

  const handleInpute = (fieldName, value) => {
    if (fieldName === "farm") {
      setSelectedFarm(value);
      setData((prevData) => ({ ...prevData, [fieldName]: value }));
    } else {
      setData((prevData) => ({ ...prevData, [fieldName]: value }));
    }
    console.log(data);
  };

  useEffect(() => {
    const fetchFeeds = async () => {
      try {
        const response = await axios.get(`${apiUrl}/sheep`);
        const data = response.data;
        const filteredData = data.filter((tag) => tag.farm === farm);
        const options = filteredData.map((feed) => ({
          value: feed.siloNumber,
          label: feed.siloNumber,
        }));
        setFeedOptions(options);
      } catch (error) {
        console.error("Error fetching feeds:", error);
      }
    };

    fetchFeeds();
  }, [farm, feedOptions, apiUrl]);

  const handleInput = (fieldName, value) => {
    if (fieldName === "siloVaccinated") {
      setSelectedFeed(value);
      const siloVaccinated = value ? value.map((option) => option.value) : [];
      setData((prevData) => ({
        ...prevData,
        [fieldName]: value,
        siloVaccinated,
        servedBy: account.user.firstname,
        userName: account.user.firstname,
        userId: account.user.id,
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [fieldName]: value,
        servedBy: account.user.firstname,
        userName: account.user.firstname,
        userId: account.user.id,
      }));
    }
    console.log(data);
  };

  useEffect(() => {
    const fetchVaccines = async () => {
      try {
        const response = await axios.get(memoizedApiUrl2);
        const data = response.data;
        // const filteredData = data.filter((tag) => tag.farm === farm);
        const options = data.map((vaccine) => ({
          value: vaccine.vaccine,
          label: vaccine.vaccine,
        }));
        console.log(options);
        setVaccineOptions(options);
      } catch (error) {
        console.error("Error fetching vaccines:", error);
      }
    };

    fetchVaccines();
  }, [memoizedApiUrl2]);

  const handleEggs = async (e) => {
    e.preventDefault();
    setShowConfirmationModal(true);
  };

  const handleConfirmation = async (e) => {
    if (!createPermission) {
      return;
    }
    axios
      .post(`${apiUrl}/sheep-vaccination`, data)
      .then((response) => {
        if (response.data) {
          setSuccess(
            `Successfully vaccinated silo ${data.siloVaccinated} using ${data.vaccineName}`,
          );
          toast.success(
            `Successfully vaccinated silo ${data.siloVaccinated} using ${data.vaccineName}`,
          );
          setTimeout(() => {
            setSuccess(null);
          }, 10000);
          const transactionId = response.data.transactionId;
          setTransactionId(transactionId);
          setShowReceipt(true);
          onCancel();
          setShowReceiptModal(true);
          formRef.current.reset();
          setSelectedFeed(null);
          setSelectedVet(null);
          setVaccineCost(null);
          setSelectedVaccine(null);
          setShowConfirmationModal(false);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setError(`${error.response.data.message}`);
          toast.error(`${error.response.data.message}`);
          setShowConfirmationModal(false);
        }
        toast.error(`Error vaccinating silo ${data.siloVaccinated}`);
        setError(`Error vaccinating silo ${data.siloVaccinated}`);
        setShowConfirmationModal(false);
        console.log(error);
        setShowConfirmationModal(false);
      });
  };

  const handleCancelConfirmation = () => {
    // Handle cancel action if needed
    setShowConfirmationModal(false);
  };

  return (
    <React.Fragment>
      <Modal show={true} onHide={onCancel} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Vaccinate Sheep</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              {success && (
                <Alert
                  variant="success"
                  onClose={() => setSuccess(null)}
                  dismissible
                >
                  {success}
                </Alert>
              )}
              {error && (
                <Alert
                  variant="danger"
                  onClose={() => setError(null)}
                  dismissible
                >
                  {error}
                </Alert>
              )}
              <ToastContainer />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form onSubmit={handleEggs} ref={formRef}>
                <Row>
                  <Form.Group as={Col} md={4} sm={12} lg={4}>
                    <Form.Label>Farm</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md={8} sm={12} lg={8}>
                    <Select
                      options={farmOptions}
                      onChange={(selectedOption) =>
                        handleInpute("farm", selectedOption.value)
                      }
                      isSearchable
                      name="farm"
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={4}
                    sm={12}
                    lg={64}
                    controlId="formBasic"
                  >
                    <Form.Label>Vaccine Name</Form.Label>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={8}
                    sm={12}
                    lg={8}
                    controlId="formBasic"
                  >
                    <Select
                      value={selectedVaccine}
                      onChange={(selectedOption) =>
                        setSelectedVaccine(
                          handleInput("vaccineName", selectedOption.value),
                        )
                      }
                      options={vaccineOptions}
                      isSearchable
                      name="vaccineName"
                      placeholder="--Select Vaccine--"
                      required
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={4}
                    sm={12}
                    lg={4}
                    controlId="formBasic"
                  >
                    <Form.Label>Silo Number</Form.Label>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={8}
                    sm={12}
                    lg={8}
                    controlId="formBasic"
                  >
                    <Select
                      value={selectedFeed}
                      onChange={(selectedOption) =>
                        setSelectedFeed(
                          handleInput("siloVaccinated", selectedOption),
                        )
                      }
                      options={feedOptions}
                      isSearchable
                      isMulti
                      name="siloVaccinated"
                      placeholder="-- Silo to Vaccinate --"
                      required
                    />
                  </Form.Group>
                  {/* <Form.Group as={Col} md={6} sm={12} lg={6} controlId="formBasic">
                            <Form.Label>Vetenary Officer</Form.Label>
                            <Select 
                                value={selectedVet}
                                onChange={(selectedOption) => setSelectedVet(handleInput('vetenary', selectedOption.value))}
                                options={vetOptions}
                                isSearchable
                                name="vetenary"
                                placeholder="-- Select Vetenary --"
                                required
                            />
                        </Form.Group> */}
                  <Form.Group
                    as={Col}
                    md={4}
                    sm={12}
                    lg={4}
                    controlId="formBasic"
                  >
                    <Form.Label>Vaccine Quantity</Form.Label>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={8}
                    sm={12}
                    lg={8}
                    controlId="formBasic"
                  >
                    <Form.Control
                      size="sm"
                      onChange={(e) =>
                        handleInput("vaccineQuantity", e.target.value)
                      }
                      name="vaccineQuantity"
                      required
                      type="number"
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={4}
                    sm={12}
                    lg={4}
                    controlId="formBasic"
                  >
                    <Form.Label>Date</Form.Label>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={8}
                    sm={12}
                    lg={8}
                    controlId="formBasic"
                  >
                    <Form.Control
                      size="sm"
                      onChange={(e) => handleInput("recorded", e.target.value)}
                      name="recorded"
                      required
                      type="date"
                    />
                  </Form.Group>
                </Row>
                <Modal.Footer>
                  {createPermission && <Button type="submit">Submit</Button>}
                  <Button variant="secondary" onClick={onCancel}>
                    Cancel
                  </Button>
                </Modal.Footer>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <ConfirmationModal
        show={showConfirmationModal}
        onHide={handleCancelConfirmation}
        onConfirm={handleConfirmation}
      />
    </React.Fragment>
  );
};

export default SamplePage;
