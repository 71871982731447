import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import {
  Row,
  Col,
  Form,
  Button,
  Table,
  Alert,
  Tabs,
  Tab,
  Modal,
  Pagination,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Font,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import Card from "../../components/Card/MainCard";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import config from "../../config";
import Receipt from "./GeneralReceipt011.js";
import { Link } from "react-router-dom";
import { usePermissions } from "../../permissions/index.js";
import DataTable from "react-data-table-component";
import PiVac from "./Sample0.js";
import { PDFContent } from "../../components/Receipts";

// const ReceiptPDF = ({ receiptData, onDownloadButtonClick }) => {
//     return (
//         <PDFViewer style={{ width: '100%', height: '100vh' }}>
//             <Document>
//                 <Page style={styles.page}>
//                     <View style={styles.section}>
//                         <Text style={styles.heading}>SFARMS SALES RECEIPT</Text>
//                         <Text style={styles.heading}>RECEIPT: #{receiptData.transactionId}</Text>
//                         <Text style={styles.heading}>DATE/TIME: {new Date(receiptData.createdAt).toLocaleString()}</Text>
//                         {receiptData.cus_name && (
//                             <View>
//                                 <Text style={styles.label}>Customer:</Text>
//                                 <Text style={styles.value}>{receiptData.cus_name}</Text>
//                             </View>
//                         )}
//                         {receiptData.birds && (
//                             <View>
//                                 <Text style={styles.label}>No. of Birds:</Text>
//                                 <Text style={styles.value}>{receiptData.birds}</Text>
//                             </View>
//                         )}
//                         {receiptData.trays && (
//                             <View>
//                                 <Text style={styles.label}>No. of Trays:</Text>
//                                 <Text style={styles.label}>{receiptData.trays}</Text>
//                             </View>
//                         )}
//                         {receiptData.eggs && (
//                             <View>
//                                 <Text style={styles.label}>No. of Eggs:</Text>
//                                 <Text style={styles.label}>{receiptData.eggs}</Text>
//                             </View>
//                         )}
//                         {receiptData.tagNumber && (
//                             <View>
//                                 <Text style={styles.label}>Tag No.:</Text>
//                                 <Text style={styles.value}>{receiptData.tagNumber}</Text>
//                             </View>
//                         )}
//                         {receiptData.goats && (
//                             <View>
//                                 <Text style={styles.label}>No. of Goats:</Text>
//                                 <Text style={styles.value}>{receiptData.goats}</Text>
//                             </View>
//                         )}
//                         {receiptData.pigs && (
//                             <View>
//                                 <Text style={styles.label}>No. of Pigs:</Text>
//                                 <Text style={styles.value}>{receiptData.pigs}</Text>
//                             </View>
//                         )}
//                         {receiptData.price && (
//                             <View>
//                                 <Text style={styles.label}>Price:</Text>
//                                 <Text style={styles.value}>Ksh. {receiptData.price.toLocaleString()}</Text>
//                             </View>
//                         )}
//                         {receiptData.pay_method && (
//                             <View>
//                                 <Text style={styles.label}>Payment Mode:</Text>
//                                 <Text style={styles.value}>{receiptData.pay_method}</Text>
//                             </View>
//                         )}
//                         {receiptData.delivery && (
//                             <View>
//                                 <Text style={styles.label}>Delivery Mode:</Text>
//                                 <Text style={styles.value}>{receiptData.delivery}</Text>
//                             </View>
//                         )}
//                         {receiptData.del_guy && (
//                             <View>
//                                 <Text style={styles.label}>Delivery Guy:</Text>
//                                 <Text style={styles.value}>{receiptData.del_guy}</Text>
//                             </View>
//                         )}
//                         {receiptData.pay_reference && (
//                             <View>
//                                 <Text style={styles.label}>Payment Reference:</Text>
//                                 <Text style={styles.value}>{receiptData.pay_reference}</Text>
//                             </View>
//                         )}
//                         {receiptData.transactionId && (
//                             <View>
//                                 <Text style={styles.label}>Transaction ID :</Text>
//                                 <Text style={styles.value}>{receiptData.transactionId}</Text>
//                             </View>
//                         )}
//                         {receiptData.createdAt && (
//                             <View>
//                                 <Text style={styles.label}>Date / Time :</Text>
//                                 <Text style={styles.value}>{new Date(receiptData.createdAt).toLocaleString()}</Text>
//                             </View>
//                         )}
//                         <View style={styles.footer}>
//                             <Text>Welcome Again</Text>
//                         </View>
//                     </View>
//                 </Page>
//             </Document>
//         </PDFViewer>
//     );
// };

const TransactionHistory = () => {
  const [error, setError] = useState(null);
  const formRef = useRef(null);
  const [success, setSuccess] = useState(null);
  const [transactionData, setTransactionData] = useState([]);
  const [showReceipt, setShowReceipt] = useState(false);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [receiptData, setReceiptData] = useState(null);
  const { createPermission, deletePermission, editPermission } =
    usePermissions();
  const [searchText, setSearchText] = useState("");
  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleModal = async (transactionId) => {
    // console.log('View Button Clicked with Transaction ID:', transactionId);
    setSelectedTransaction(transactionId);
    setShowReceiptModal(true);
    setShowReceipt(true);
    setTransactionId(transactionId);
  };

  const handleDownloadButtonClick = (fileUrl) => {
    axios
      .get(fileUrl, { responseType: "blob" })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileUrl.split("/").pop());
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
        toast.error("Error downloading file.");
      });
  };

  useEffect(() => {
    const fetchTransactionData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/emergency_vaccination_receipt/${transactionId}`,
        );
        setReceiptData(response.data.receiptData);
        // console.log(response.data.receiptData);
      } catch (error) {
        console.error("Error retrieving receipt data:", error);
      }
    };

    fetchTransactionData();
  }, [transactionId]);

  useEffect(() => {
    const fetchTransactionData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/emergency_vaccination`);
        setTransactionData(response.data);
        // toast.success('Data fetched successfully.');
      } catch (error) {
        console.error("Error fetching transaction history:", error);
        toast.error("Error fetching transaction history.");
      }
    };

    fetchTransactionData();
  }, [apiUrl]);

  const handleCloseReceiptModal = () => {
    setShowReceiptModal(false);
    // window.location.reload();
  };

  const handlePrint = () => {
    window.print();
  };

  const csvLinkRef = useRef();

  const exportToCSV = () => {
    const csvData = [
      [
        "Transaction ID",
        "Vaccinated",
        "Amount",
        "Vetenary",
        "Date",
        "GeneratedBy",
        "Farm",
      ],
      ...filteredData.map((item) => [
        item.transactionId,
        item.animal,
        item.totalCost,
        item.vetenary,
        item.createdAt,
        item.servedBy,
        item.farm,
      ]),
    ];

    const csvContent = csvData.map((row) => row.join(",")).join("\n");
    const blob = new Blob(["\ufeff", csvContent], {
      type: "text/csv;charset=utf-8;",
    });

    const csvUrl = URL.createObjectURL(blob);
    csvLinkRef.current.href = csvUrl;
    csvLinkRef.current.download = "sfarms.csv";
    csvLinkRef.current.click();
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        [
          "Transaction ID",
          "Vaccinated",
          "Amount",
          "Vetenary",
          "Date",
          "GeneratedBy",
          "Farm",
        ],
      ],
      body: filteredData.map((user) => [
        user.transactionId,
        user.animal,
        user.totalCost,
        user.vetenary,
        new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date(user.createdAt)),
        user.servedBy,
        user.farm,
      ]),
    });
    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl);
  };

  const hidePopup = () => {
    setPopupVisible(false);
  };

  const showPopup = () => {
    setPopupVisible(true);
  };

  const columns = [
    {
      name: "Transaction ID",
      selector: (row) => row.transactionId,
      sortable: true,
    },
    {
      name: "Vaccinated",
      selector: (row) => row.animal,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) =>
        row.totalCost !== null
          ? row.totalCost.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "-",
      sortable: true,
    },
    {
      name: "vetenary",
      selector: (row) => row.vetenary,
      sortable: true,
    },
    {
      name: "Timestamp",
      selector: (row) => new Date(row.recorded).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "By",
      selector: (row) => row.servedBy,
      sortable: true,
    },
    {
      name: "Farm",
      selector: (row) => row.farm,
      sortable: true,
    },
    {
      name: "RECIEPT",
      cell: (row) => (
        <Button
          size="sm"
          variant="info"
          onClick={() => handleModal(row.transactionId)}
        >
          <i className="feather icon-eye" /> View
        </Button>
      ),
      sortable: true,
    },
  ];

  const filteredData = searchText
    ? transactionData.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchText.toLowerCase()),
        ),
      )
    : transactionData;

  return (
    <React.Fragment>
      <Row>
        <Col sm={12}>
          {success && (
            <Alert
              variant="success"
              onClose={() => setSuccess(null)}
              dismissible
            >
              {success}
            </Alert>
          )}
          {error && (
            <Alert variant="danger" onClose={() => setError(null)} dismissible>
              {error}
            </Alert>
          )}
          <ToastContainer />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            <div className="pb-3">
              {createPermission && (
                <Button onClick={showPopup} className="mb-10">
                  <span className="feather icon-plus-circle"></span> Administer
                  Emergency Vaccination
                </Button>
              )}
              {isPopupVisible && <PiVac onCancel={hidePopup} />}
            </div>
          </div>
          <Card title="Emergency Vaccination Receipts" isOption>
            {filteredData && filteredData.length > 0 && (
              <Row>
                <Form.Group
                  as={Col}
                  md={6}
                  sm={12}
                  lg={6}
                  controlId="formBasicName"
                >
                  <Form.Control
                    type="text"
                    placeholder="Search..."
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </Form.Group>

                <Form.Group
                  as={Col}
                  md={6}
                  sm={12}
                  lg={6}
                  controlId="formBasicName"
                >
                  <div className="d-flex">
                    <Button onClick={exportToCSV}>Export as CSV</Button>
                    <a ref={csvLinkRef} style={{ display: "none" }} />
                    <a
                      href="#"
                      className="btn btn-primary"
                      onClick={exportToPDF}
                    >
                      Export PDF
                    </a>
                  </div>
                </Form.Group>
              </Row>
            )}
            <Row>
              <Col>
                <DataTable
                  columns={columns}
                  data={filteredData}
                  pagination
                  highlightOnHover
                  striped
                  // noHeader
                  dense
                  bordered
                  paginationPerPage={20}
                  paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                  paginationComponentOptions={{
                    rowsPerPageText: "Rows per page:",
                  }}
                  paginationResetDefaultPage={true}
                  paginationTotalRows={transactionData.length}
                  // expandableRows
                  selectableRows
                  onSelectedRowsChange={(selectedRows) =>
                    console.log(selectedRows)
                  }
                  customStyles={{
                    headRow: {
                      style: {
                        backgroundColor: "#279e6c", // Green background
                        color: "#fff", // White text
                        textTransform: "uppercase", // Uppercase text
                        // fontSize: '10px', // Adjust font size for header row
                        minHeight: "1.5rem",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                      },
                    },
                  }}
                />
              </Col>
            </Row>
          </Card>
          {showReceipt && (
            <Modal
              show={showReceiptModal}
              onHide={handleCloseReceiptModal}
              size="lg"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Receipt</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Receipt transactionId={transactionId} />
              </Modal.Body>
              <Modal.Footer>
                {receiptData && receiptData.transactionId && (
                  <PDFDownloadLink
                    className="btn btn-success mr-2"
                    document={
                      <PDFContent
                        id={receiptData?.id}
                        transactionData={receiptData}
                      />
                    }
                    fileName={`${receiptData?.transactionId}_${receiptData?.createdAt}.pdf`}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        "Loading..."
                      ) : (
                        <span className="feather icon-printer"> Print</span>
                      )
                    }
                  </PDFDownloadLink>
                )}
                <Button variant="secondary" onClick={handleCloseReceiptModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default TransactionHistory;
