import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Form,
  Tab,
  Tabs,
  Alert,
  Button,
  Modal,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import config from "../../config";
import Card from "../../components/Card/MainCard";
import axios from "axios";
import ConfirmationModal from "../ui-elements/basic/ConfirmationModal";
import { usePermissions } from "../../permissions";

const SamplePage = ({ onCancel }) => {
  const account = useSelector((state) => state.account);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [selectedTag, setSelectedTag] = useState("");
  const [tagOptions, setTagOptions] = useState([]);
  const [siloNumber, setSiloNumber] = useState("");
  const formRef = useRef(null);
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [farmOptions, setFarmOptions] = useState([]);
  const [farm, setSelectedFarm] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { createPermission, deletePermission, editPermission } =
    usePermissions();

  const memoizedApiUrl1 = useMemo(() => {
    return `${apiUrl}/farm`;
  }, [apiUrl]);

  const memoizedApiUrl2 = useMemo(() => {
    return `${apiUrl}/accounts`;
  }, [apiUrl]);

  const memoizedApiUrl3 = useMemo(() => {
    return `${apiUrl}/suppliers`;
  }, [apiUrl]);

  useEffect(() => {
    const fetchFarms = async () => {
      try {
        const response = await axios.get(memoizedApiUrl1);
        const farmData = response.data;
        const options = farmData.map((farm) => ({
          value: farm.name,
          label: farm.name,
        }));

        setFarmOptions(options);
      } catch (error) {
        console.error("Error fetching Farms:", error);
      }
    };

    fetchFarms();
  }, [memoizedApiUrl1]);

  const handleInpute = (fieldName, value) => {
    if (fieldName === "farm") {
      setSelectedFarm(value);
      setData((prevData) => ({ ...prevData, [fieldName]: value }));
    } else {
      setData((prevData) => ({ ...prevData, [fieldName]: value }));
    }
    console.log(data);
  };

  const handleInput = (fieldName, value) => {
    if (fieldName === "tagNumber") {
      setSelectedTag(value);
      fetchSiloNumber(value);
      setData((prevData) => ({
        ...prevData,
        [fieldName]: value,
        userName: account.user.firstname,
        userId: account.user.id,
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [fieldName]: value,
        userName: account.user.firstname,
        userId: account.user.id,
      }));
    }
    console.log(data);
  };

  const fetchSiloNumber = async (tagNumber) => {
    try {
      const response = await axios.get(`${apiUrl}/goats`);
      const data = response.data;
      const selectedPig = data.find((pig) => pig.tagNumber === tagNumber);
      const siloNumber = selectedPig ? selectedPig.siloNumber : "";
      setSiloNumber(siloNumber);
      console.log(siloNumber);
    } catch (error) {
      console.error("Error fetching silo number:", error);
    }
  };

  const handleGoat = async (e) => {
    e.preventDefault();
    setShowConfirmationModal(true);
  };

  const handleConfirmation = async (e) => {
    const updatedData = {
      ...data,
      siloNumber: siloNumber,
    };

    if (!createPermission) {
      return;
    }

    axios
      .post(`${apiUrl}/kids/sired`, updatedData)
      .then((response) => {
        if (response.data) {
          setSuccess(`Kids Sired recorded successfully`);
          setTimeout(() => {
            setSuccess(null);
          }, 10000);
          toast.success(`Kids Sired recorded successfully`);
          formRef.current.reset();
          onCancel();
          setSelectedTag(null);
          setSiloNumber(null);
          setShowConfirmationModal(false);
        }

        console.log(response);
      })
      .catch((error) => {
        setError(error);
        toast.error(`Error recording Kids Sired`);
        console.log(error.message);
        setShowConfirmationModal(false);
      });
  };

  useEffect(() => {
    fetchTags();
  }, [farm, tagOptions, apiUrl]);

  const fetchTags = async () => {
    try {
      const response = await axios.get(`${apiUrl}/goats`);
      const data = response.data;
      const filteredData = data.filter((tag) => tag.farm === farm);
      const options = filteredData.map((tag) => ({
        value: tag.tagNumber,
        label: tag.tagNumber,
      }));
      setTagOptions(options);
    } catch (error) {
      console.error("Error fetching Tags:", error);
    }
  };

  const handleCancelConfirmation = () => {
    // Handle cancel action if needed
    setShowConfirmationModal(false);
  };

  return (
    <React.Fragment>
      <Modal show={true} onHide={onCancel} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Record kids_sired Sired</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              {success && (
                <Alert
                  variant="success"
                  onClose={() => setSuccess(null)}
                  dismissible
                >
                  {success}
                </Alert>
              )}
              {error && (
                <Alert
                  variant="danger"
                  onClose={() => setError(null)}
                  dismissible
                >
                  {error.message}
                </Alert>
              )}
              <ToastContainer />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form onSubmit={handleGoat} ref={formRef}>
                <Row>
                  <Form.Group as={Col} md={4} sm={12} lg={4}>
                    <Form.Label>Farm</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md={8} sm={12} lg={8}>
                    <Select
                      options={farmOptions}
                      onChange={(selectedOption) =>
                        handleInpute("farm", selectedOption.value)
                      }
                      isSearchable
                      name="farm"
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={4}
                    sm={12}
                    lg={4}
                    controlId="formBasicName"
                  >
                    <Form.Label>Mother Tag Number</Form.Label>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={8}
                    sm={12}
                    lg={8}
                    controlId="formBasicName"
                  >
                    <Select
                      value={selectedTag}
                      onChange={(selectedOption) =>
                        setSelectedTag(
                          handleInput("tagNumber", selectedOption.value),
                        )
                      }
                      options={tagOptions}
                      isSearchable
                      // isMulti
                      name="tagNumber"
                      placeholder="-- Select Tag Number --"
                      required
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={4}
                    sm={12}
                    lg={4}
                    controlId="formBasicTel"
                  >
                    <Form.Label>Silo Number</Form.Label>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={8}
                    sm={12}
                    lg={8}
                    controlId="formBasicTel"
                  >
                    <Form.Control
                      size="sm"
                      required
                      onChange={(e) =>
                        handleInput("siloNumber", e.target.value)
                      }
                      value={siloNumber}
                      type="text"
                      name="siloNumber"
                      disabled
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={4}
                    sm={12}
                    lg={4}
                    controlId="formBasicAddress"
                  >
                    <Form.Label>No. of kids Sired</Form.Label>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={8}
                    sm={12}
                    lg={8}
                    controlId="formBasicAddress"
                  >
                    <Form.Control
                      size="sm"
                      required
                      onChange={(e) => handleInput("kidsSired", e.target.value)}
                      type="number"
                      name="kidsSired"
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={4}
                    sm={12}
                    lg={4}
                    controlId="formBasicAddress"
                  >
                    <Form.Label>Date Sired</Form.Label>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={8}
                    sm={12}
                    lg={8}
                    controlId="formBasicAddress"
                  >
                    <Form.Control
                      size="sm"
                      required
                      onChange={(e) => handleInput("dateSired", e.target.value)}
                      type="date"
                      n
                      ame="dateSired"
                    />
                  </Form.Group>
                </Row>
                <Modal.Footer>
                  {createPermission && <Button type="submit">Submit</Button>}
                  <Button variant="secondary" onClick={onCancel}>
                    Cancel
                  </Button>
                </Modal.Footer>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <ConfirmationModal
        show={showConfirmationModal}
        onHide={handleCancelConfirmation}
        onConfirm={handleConfirmation}
      />
    </React.Fragment>
  );
};

export default SamplePage;
