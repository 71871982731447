import React, { useState, useRef, useEffect, useMemo } from "react";
import axios from "axios";
import Select from "react-select";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  Row,
  Col,
  Form,
  Button,
  Table,
  Alert,
  Tabs,
  Tab,
  Modal,
  Pagination,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import Card from "../../components/Card/MainCard";
import config from "../../config";
import Receipt from "./GeneralReceipt01.js";
import { Link, useHistory } from "react-router-dom";
import ConfirmationModal from "../ui-elements/basic/ConfirmationModal.js";
import DataTable from "react-data-table-component";
import { usePermissions } from "../../permissions/index.js";

// Register a custom font (optional)
Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  heading: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 5,
  },
  label: {
    fontSize: 12,
    marginBottom: 3,
  },
  value: {
    fontSize: 12,
  },
  footer: {
    fontSize: 12,
    marginTop: 10,
    textAlign: "center",
  },
});

const ReceiptPDF = ({ receiptData, onDownloadButtonClick }) => {
  return (
    <PDFViewer style={{ width: "100%", height: "100vh" }}>
      <Document>
        <Page style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.heading}>SFARMS SALES RECEIPT</Text>
            <Text style={styles.heading}>
              RECEIPT: #{receiptData.transactionId}
            </Text>
            <Text style={styles.heading}>
              DATE/TIME: {new Date(receiptData.createdAt).toLocaleString()}
            </Text>
            {receiptData.cus_name && (
              <View>
                <Text style={styles.label}>Customer:</Text>
                <Text style={styles.value}>{receiptData.cus_name}</Text>
              </View>
            )}
            {receiptData.birds && (
              <View>
                <Text style={styles.label}>No. of Birds:</Text>
                <Text style={styles.value}>{receiptData.birds}</Text>
              </View>
            )}
            {receiptData.trays && (
              <View>
                <Text style={styles.label}>No. of Trays:</Text>
                <Text style={styles.label}>{receiptData.trays}</Text>
              </View>
            )}
            {receiptData.eggs && (
              <View>
                <Text style={styles.label}>No. of Eggs:</Text>
                <Text style={styles.label}>{receiptData.eggs}</Text>
              </View>
            )}
            {receiptData.tagNumber && (
              <View>
                <Text style={styles.label}>Tag No.:</Text>
                <Text style={styles.value}>{receiptData.tagNumber}</Text>
              </View>
            )}
            {receiptData.goats && (
              <View>
                <Text style={styles.label}>No. of Goats:</Text>
                <Text style={styles.value}>{receiptData.goats}</Text>
              </View>
            )}
            {receiptData.pigs && (
              <View>
                <Text style={styles.label}>No. of Pigs:</Text>
                <Text style={styles.value}>{receiptData.pigs}</Text>
              </View>
            )}
            {receiptData.price && (
              <View>
                <Text style={styles.label}>Price:</Text>
                <Text style={styles.value}>
                  Ksh. {receiptData.price.toLocaleString()}
                </Text>
              </View>
            )}
            {receiptData.pay_method && (
              <View>
                <Text style={styles.label}>Payment Mode:</Text>
                <Text style={styles.value}>{receiptData.pay_method}</Text>
              </View>
            )}
            {receiptData.delivery && (
              <View>
                <Text style={styles.label}>Delivery Mode:</Text>
                <Text style={styles.value}>{receiptData.delivery}</Text>
              </View>
            )}
            {receiptData.del_guy && (
              <View>
                <Text style={styles.label}>Delivery Guy:</Text>
                <Text style={styles.value}>{receiptData.del_guy}</Text>
              </View>
            )}
            {receiptData.pay_reference && (
              <View>
                <Text style={styles.label}>Payment Reference:</Text>
                <Text style={styles.value}>{receiptData.pay_reference}</Text>
              </View>
            )}
            {receiptData.transactionId && (
              <View>
                <Text style={styles.label}>Transaction ID :</Text>
                <Text style={styles.value}>{receiptData.transactionId}</Text>
              </View>
            )}
            {receiptData.createdAt && (
              <View>
                <Text style={styles.label}>Date / Time :</Text>
                <Text style={styles.value}>
                  {new Date(receiptData.createdAt).toLocaleString()}
                </Text>
              </View>
            )}
            <View style={styles.footer}>
              <Text>Welcome Again</Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

const TransactionHistory = () => {
  const [error, setError] = useState(null);
  const formRef = useRef(null);
  const history = useHistory();
  const [success, setSuccess] = useState(null);
  const [transactionData, setTransactionData] = useState([]);
  const [showReceipt, setShowReceipt] = useState(false);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [receiptData, setReceiptData] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationData, setConfirmationData] = useState({
    id: null,
    description: null,
  });
  const [searchText, setSearchText] = useState("");
  const { createPermission, deletePermission, editPermission } =
    usePermissions();

  const handleDownloadButtonClick = (fileUrl) => {
    axios
      .get(fileUrl, { responseType: "blob" })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileUrl.split("/").pop());
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
        toast.error("Error downloading file.");
      });
  };

  useEffect(() => {
    const fetchTransactionData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/animal-acquisition-history`,
        );
        setTransactionData(response.data);
        // toast.success('Transaction history fetched successfully.');
      } catch (error) {
        console.error("Error fetching transaction history:", error);
        toast.error("Error fetching transaction history.");
      }
    };

    fetchTransactionData();
  }, [apiUrl]);

  const csvLinkRef = useRef();

  const exportToCSV = () => {
    const csvData = [
      ["Description", "Supplier", "Amount", "Quantity", "Date", "Farm"],
      ...filteredData.map((item) => [
        item.description,
        item.supplier,
        item.amount,
        item.quantity,
        item.createdAt,
        item.farm,
      ]),
    ];

    const csvContent = csvData.map((row) => row.join(",")).join("\n");
    const blob = new Blob(["\ufeff", csvContent], {
      type: "text/csv;charset=utf-8;",
    });

    const csvUrl = URL.createObjectURL(blob);
    csvLinkRef.current.href = csvUrl;
    csvLinkRef.current.download = "sfarms.csv";
    csvLinkRef.current.click();
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [["Description", "Supplier", "Amount", "Quantity", "Date", "Farm"]],
      body: filteredData.map((user) => [
        user.description,
        user.supplier,
        user.amount,
        user.quantity,
        new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date(user.createdAt)),
      ]),
    });
    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl);
  };

  const getLabelColorClass = (description) => {
    switch (description) {
      case "Goats Purchase":
        return "label-orange";
      case "Eggs Purchase":
        return "label-blue";
      case "Pigs Purchase":
        return "label-till";
      case "Poultry Purchase":
        return "label-brown";
      case "Sheep Purchase":
        return "label-yellow";
      case "Broken Eggs":
        return "label-kink";
      default:
        return "";
    }
  };

  const handleView = (id, description) => {
    const transactionToUpdate = transactionData.find(
      (transaction) => transaction.id === id,
    );
    history.push(`animal-acquisition/view/${id}/${description}`, {
      transaction: transactionToUpdate,
    });
  };

  const handleViewFile = (image) => {
    window.open(`${apiUrl}/uploads/${image}`, "_blank");
  };

  const handleArchive = (id, description) => {
    setConfirmationData({ id, description });
    setShowConfirmationModal(true);
    console.log(id, description);
  };

  const handleConfirmation = async () => {
    const { id, description } = confirmationData;

    axios
      .post(`${apiUrl}/archive-transaction/${id}/${description}`)
      .then(() => {
        // Update the frontend state to reflect the archived status
        setTransactionData((prevData) =>
          prevData.map((transaction) => {
            if (transaction.id === id) {
              return { ...transaction, archived: true };
            }
            return transaction;
          }),
        );
        toast.success("Transaction archived successfully.");
        setShowConfirmationModal(false);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          // Display the error message to the user
          toast.error(error.response.data.error);
          setShowConfirmationModal(false);
        } else {
          // Handle other types of errors
          console.error("Error archiving transaction:", error);
          toast.error("Error archiving transaction.");
          setShowConfirmationModal(false);
        }
      });
  };

  const handleCancelConfirmation = () => {
    // Handle cancel action if needed
    setShowConfirmationModal(false);
  };

  const columns = [
    // {
    //     name: 'id',
    //     selector: (row) => row?.id,
    //     sortable: true,
    //     wrap: true,
    //     minWidth: '100px',
    //     maxWidth: '100px'
    // },

    {
      name: "Description",
      selector: (row) => row.description,
      cell: (row) => (
        <span className={getLabelColorClass(row.description)}>
          {row.description}
        </span>
      ),
      sortable: true,
      wrap: true,
      minWidth: "120px",
    },

    {
      name: "supplier",
      selector: (row) => row?.supplier,
      sortable: true,
      wrap: true,
      minWidth: "180px",
    },
    {
      name: "Amount",
      selector: (row) => row?.amount,
      cell: (row) =>
        row.amount !== null
          ? row.amount.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "",
      sortable: true,
      wrap: true,
      minWidth: "120px",
    },
    {
      name: "quantity",
      selector: (row) => row?.quantity,
      sortable: true,
      wrap: true,
      minWidth: "100px",
    },
    {
      name: "Timestamp",
      selector: (row) => new Date(row?.createdAt).toLocaleString(),
      sortable: true,
      wrap: true,
      minWidth: "180px",
    },
    {
      name: "Farm",
      selector: (row) => row?.farm,
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          {editPermission ? (
            <Button onClick={() => handleView(row.id, row.description)}>
              <i className="feather icon-eye" />
              {/* View */}
            </Button>
          ) : (
            "-"
          )}
          {editPermission
            ? row.image && (
                <Button onClick={() => handleViewFile(row.image)}>
                  <i className="feather icon-download-cloud" />
                  {/* Receipt */}
                </Button>
              )
            : "-"}
          {deletePermission ? (
            row.archived === 1 ? (
              <Button variant="secondary">
                <i className="feather icon-slash" />
                {/* Archived */}
              </Button>
            ) : (
              <Button
                variant="danger"
                onClick={() => handleArchive(row.id, row.description)}
              >
                <i className="feather icon-x-square" />
                {/* Archive */}
              </Button>
            )
          ) : (
            "-"
          )}
        </div>
      ),
      wrap: true,
      minWidth: "180px",
    },
  ];

  const filteredData = searchText
    ? transactionData.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchText.toLowerCase()),
        ),
      )
    : transactionData;

  const dataWithUniqueKeys = filteredData?.map((item, index) => ({
    ...item,
    uniqueKey: `${item.createdAt || "defaultKey"}-${index}`, // Combine `createdAt` and index
  }));

  return (
    <React.Fragment>
      <Row>
        <Col sm={12}>
          {success && (
            <Alert
              variant="success"
              onClose={() => setSuccess(null)}
              dismissible
            >
              {success}
            </Alert>
          )}
          {error && (
            <Alert variant="danger" onClose={() => setError(null)} dismissible>
              {error}
            </Alert>
          )}
          <ToastContainer />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            {createPermission && (
              <div className="pb-3">
                <Link to="/poultry/new-poultry">
                  <Button variant="primary" className="">
                    <span className="feather icon-plus-circle"></span> Purchase
                    Birds
                  </Button>
                </Link>
                <Link to="/pigs/add-pig">
                  <Button variant="primary" className="">
                    <span className="feather icon-plus-circle"></span> Purchase
                    Pigs
                  </Button>
                </Link>
                <Link to="/goats/new-goats">
                  <Button variant="primary" className="">
                    <span className="feather icon-plus-circle"></span> Purchase
                    Goats
                  </Button>
                </Link>
                <Link to="/sheep/new-sheep">
                  <Button variant="primary" className="">
                    <span className="feather icon-plus-circle"></span> Purchase
                    Sheep
                  </Button>
                </Link>
              </div>
            )}
          </div>
          <Row className="btn-page">
            <Col>
              {transactionData && transactionData.length > 0 && (
                <Row>
                  <Form.Group
                    as={Col}
                    md={6}
                    sm={12}
                    lg={6}
                    controlId="formBasicName"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Search..."
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={6}
                    sm={12}
                    lg={6}
                    controlId="formBasicName"
                  >
                    <div className="d-flex">
                      <Button onClick={exportToCSV}>
                        <span className="feather icon-file"></span> Export CSV
                      </Button>
                      <Button onClick={exportToPDF}>
                        <span className="feather icon-file"></span> Export PDF
                      </Button>
                    </div>
                  </Form.Group>
                </Row>
              )}
              <Row>
                <Col>
                  <DataTable
                    columns={columns}
                    data={dataWithUniqueKeys}
                    keyField="uniqueKey"
                    pagination
                    highlightOnHover
                    striped
                    dense
                    bordered
                    paginationPerPage={20}
                    paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                    paginationComponentOptions={{
                      rowsPerPageText: "Rows per page:",
                    }}
                    paginationResetDefaultPage={true}
                    paginationTotalRows={transactionData.length}
                    // expandableRows
                    // expandableRowsComponent={ExpandableRow}
                    selectableRows
                    onSelectedRowsChange={(selectedRows) =>
                      console.log(selectedRows)
                    }
                    customStyles={{
                      headRow: {
                        style: {
                          backgroundColor: "#279e6c", // Green background
                          color: "#fff", // White text
                          textTransform: "uppercase", // Uppercase text
                          // fontSize: '10px', // Adjust font size for header row
                          minHeight: "1.5rem",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                        },
                      },
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <ConfirmationModal
        show={showConfirmationModal}
        onHide={handleCancelConfirmation}
        onConfirm={handleConfirmation}
      />
    </React.Fragment>
  );
};

export default TransactionHistory;
