import React, { useState } from "react";
import axios from "axios";
import { read, utils, write } from "xlsx";
import { saveAs } from "file-saver";
import config from "../../config";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const ChartOfAccountsUpload = ({ onCancel }) => {
  const [file, setFile] = useState(null);
  const [parsedData, setParsedData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;

  // Validate Excel structure
  const validateSheet = (data) => {
    const newErrors = [];
    const validGroups = new Set([
      "Current assets",
      "Long term assets",
      "Other assets",
      "Current liabilities",
      "Long term liabilities",
      "Equity",
      "Revenue",
      "Cost of sales",
      "Expense",
    ]);

    data.forEach((row, index) => {
      if (!row["Account Name"])
        newErrors.push(`Row ${index + 1}: Missing Account Name`);
      if (!/^\d{4}$/.test(row.Code))
        newErrors.push(`Row ${index + 1}: Invalid Code format`);
      if (
        !["Balance sheet", "Income Statement"].includes(
          row["Financial Statement"],
        )
      ) {
        newErrors.push(`Row ${index + 1}: Invalid Financial Statement`);
      }
      if (!validGroups.has(row.Group))
        newErrors.push(`Row ${index + 1}: Invalid Group`);
    });

    return newErrors;
  };

  // Handle file upload
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const wb = read(e.target.result, { type: "array" });
      const ws = wb.Sheets[wb.SheetNames[0]];
      const data = utils.sheet_to_json(ws);

      // Filter out section headers
      const filteredData = data.filter(
        (row) => row.Code && !isNaN(row.Code) && row["Account Name"],
      );

      const validationErrors = validateSheet(filteredData);

      if (validationErrors.length > 0) {
        setErrors(validationErrors);
        setParsedData([]);
      } else {
        setErrors([]);
        setParsedData(filteredData);
      }
    };
    reader.readAsArrayBuffer(file);
    setFile(file);
  };

  // console.log(file);

  // Handle submit
  const handleSubmit = async () => {
    setIsProcessing(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${apiUrl}/api/upload-chart`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        },
      );

      if (response.data) {
        console.log(response.data);
        toast.success(response.data.message);
        setFile(null);
        setParsedData([]);
      }
    } catch (error) {
      console.log(error.response?.data?.message || "Upload failed");
      toast.error(`Error: ${error.response?.data?.message || "Upload failed"}`);
    } finally {
      setIsProcessing(false);
      onCancel();
    }
    // setIsProcessing(false);
  };

  // Download template
  const downloadTemplate = () => {
    const templateData = [
      ["Account Name", "Code", "Financial Statement", "Group"],
      ["Bank account", "1000", "Balance sheet", "Current assets"],
      ["Accounts payable", "2000", "Balance sheet", "Current liabilities"],
    ];

    const ws = utils.aoa_to_sheet(templateData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Template");
    const buffer = write(wb, { bookType: "xlsx", type: "array" });
    saveAs(new Blob([buffer]), "chart_of_accounts_template.xlsx");
  };

  return (
    <React.Fragment>
      <Modal show={true} onHide={onCancel} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Upload Chart of Accounts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="upload-container">
            <div className="upload-instructions">
              <Button onClick={downloadTemplate} className="template-btn">
                Download Template
              </Button>

              <div className="requirements">
                <code>File Requirements:</code>
                <ul>
                  <li>Excel (.xlsx) format only</li>
                  <li>
                    Maintain column order: Account Name, Code, Financial
                    Statement, Group
                  </li>
                  <li>Codes must be 4-digit numbers</li>
                  <li>
                    Section headers (ASSETS, LIABILITIES) will be automatically
                    ignored
                  </li>
                </ul>
              </div>
            </div>

            <div className="file-input">
              <input
                type="file"
                accept=".xlsx"
                onChange={handleFileUpload}
                disabled={isProcessing}
              />
              {file && <span>{file.name}</span>}
            </div>

            {errors.length > 0 && (
              <div className="error-list">
                <h4>Validation Errors:</h4>
                {errors.map((error, index) => (
                  <div key={index} className="error-item">
                    • {error}
                  </div>
                ))}
              </div>
            )}

            {parsedData.length > 0 && (
              <div className="data-preview">
                <h4>Preview (First 5 Rows):</h4>
                <table>
                  <thead>
                    <tr>
                      <th>Account Name</th>
                      <th>Code</th>
                      <th>Financial Statement</th>
                      <th>Group</th>
                    </tr>
                  </thead>
                  <tbody>
                    {parsedData.slice(0, 5).map((row, index) => (
                      <tr key={index}>
                        <td>{row["Account Name"]}</td>
                        <td>{row.Code}</td>
                        <td>{row["Financial Statement"]}</td>
                        <td>{row.Group}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            {parsedData.length > 0 && !errors.length && (
              <Button
                onClick={handleSubmit}
                disabled={isProcessing}
                // className="submit-btn"
              >
                {isProcessing ? "Processing..." : "Confirm Upload"}
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ChartOfAccountsUpload;
