import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Card, Table, Button } from "react-bootstrap";
// import { Link, useNavigate } from 'react-router-dom';
import config from "./../../../config";
import axios from "axios";
import ModuleNotification from "../../../components/Widgets/Statistic/Notification";
import AmChartEarnings from "./chart/AmChartEarnings";
import AmChartStatistics6 from "./chart/AmChartStatistics6";
import BarDiscreteChart from "../../../views/charts/nvd3-chart/chart/BarDiscreteChart.js";
import MultiBarChart from "../../../views/charts/nvd3-chart/chart/MultiBarChart.js";
import { Link } from "react-router-dom";

const DashDefault = () => {
  const account = useSelector((state) => state.account);
  const [data, setData] = useState({});
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalEggsSold, setTotalEggsSold] = useState(0);
  const [totalEggsCollected, setTotalEggsCollected] = useState(0);
  const [totalTraysCollected, setTotalTraysCollected] = useState(0);
  const [totalBirdsSold, setTotalBirdsSold] = useState(0);
  const [totalSpoilt, setTotalSpoilt] = useState(0);
  const [totalDead, setTotalDead] = useState(0);
  const [totalpigsSired, setPigsSired] = useState(0);
  const [totalFeedQuantity, setTotalFeedQuantity] = useState(0);
  const [totalPoultry, setTotalPoultry] = useState(0);
  const [totalCapacity, setTotalCapacity] = useState(0);
  const [totalRefill, setTotalRefill] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalAdmins, setTotalAdmins] = useState(0);
  const [totalPigs, setTotalPigs] = useState(0);
  const [totalpigsSold, setTotalPigsSold] = useState(0);
  const [totalGoatsSold, setTotalGoatsSold] = useState(0);
  const [totalPigsDead, setTotalPigsDead] = useState(0);
  const [totalGoats, setTotalGoats] = useState(0);
  const [totalSheep, setTotalSheep] = useState(0);
  const [totalGoatsDead, setTotalGoatsDead] = useState(0);
  const [totalSired, setTotalGoatsSired] = useState(0);
  const [earnings, setEarnings] = useState({ totalEarnings: 0 });
  const [dead, setDead] = useState({ totalDead: 0 });
  const [water, setWater] = useState({ totalWater: 0 });
  const [feed, setFeed] = useState({ totalFeed: 0 });
  const [expenses, setExpenses] = useState({ totalExpenses: 0 });
  const [goatExpenses, setGoatExpenses] = useState({ totalGoatExpenses: 0 });
  const [pigsExpenses, setPigsExpenses] = useState({ totalPigsExpenses: 0 });
  const [salary, setSalary] = useState({ totalSalary: 0 });
  const [poultryExpenses, setPoultryExpenses] = useState({
    totalPoultryExpenses: 0,
  });
  const [feedExpenses, setFeedExpenses] = useState({ totalFeedExpenses: 0 });
  const [feedRestock, setFeedRestock] = useState({ totalFeedRestock: 0 });
  const [waterRefill, setWaterRefill] = useState({ totalWaterRefill: 0 });
  const [waterConsumption, setWaterConsumption] = useState({
    totalWaterConsumption: 0,
  });

  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const space = account.user.is_admin;

  useEffect(() => {
    fetch(`${apiUrl}/earnings`)
      .then((response) => response.json())
      .then((data) => setEarnings(data))
      .catch((error) => console.error("Error:", error));
  }, []);

  //   useEffect(() => {
  //     fetch(`${apiUrl}/water/consumption`)
  //       .then((response) => response.json())
  //       .then((data) => setWaterConsumption(data))
  //       .catch((error) => console.error('Error:', error));
  //   }, []);

  //   useEffect(() => {
  //     fetch(`${apiUrl}/pigs/expenses`)
  //       .then((response) => response.json())
  //       .then((data) => setPigsExpenses(data))
  //       .catch((error) => console.error('Error:', error));
  //   }, []);

  //   useEffect(() => {
  //     fetch(`${apiUrl}/water/expenses`)
  //       .then((response) => response.json())
  //       .then((data) => setWaterRefill(data))
  //       .catch((error) => console.error('Error:', error));
  //   }, []);

  //   useEffect(() => {
  //     fetch(`${apiUrl}/restock/expenses`)
  //       .then((response) => response.json())
  //       .then((data) => setFeedRestock(data))
  //       .catch((error) => console.error('Error:', error));
  //   }, []);

  //   useEffect(() => {
  //     fetch(`${apiUrl}/feeds/expenses`)
  //       .then((response) => response.json())
  //       .then((data) => setFeedExpenses(data))
  //       .catch((error) => console.error('Error:', error));
  //   }, []);

  //   useEffect(() => {
  //     fetch(`${apiUrl}/poultry/expenses`)
  //       .then((response) => response.json())
  //       .then((data) => setPoultryExpenses(data))
  //       .catch((error) => console.error('Error:', error));
  //   }, []);

  useEffect(() => {
    fetch(`${apiUrl}/expenses`)
      .then((response) => response.json())
      .then((expenses) => setExpenses(expenses))
      .catch((error) => console.error("Error:", error));
  }, []);

  //   useEffect(() => {
  //     fetch(`${apiUrl}/goat/expenses`)
  //       .then((response) => response.json())
  //       .then((goatExpenses) => setGoatExpenses(goatExpenses))
  //       .catch((error) => console.error('Error:', error));
  //   }, []);

  useEffect(() => {
    fetch(`${apiUrl}/users/salary`)
      .then((response) => response.json())
      .then((salary) => setSalary(salary))
      .catch((error) => console.error("Error:", error));
  }, [apiUrl]);

  //   useEffect(() => {
  //     fetch(`${apiUrl}/waterConsumption`)
  //       .then((response) => response.json())
  //       .then((data) => setWater(data))
  //       .catch((error) => console.error('Error:', error));
  //   }, []);

  useEffect(() => {
    fetch(`${apiUrl}/feedConsumption`)
      .then((response) => response.json())
      .then((data) => setFeed(data))
      .catch((error) => console.error("Error:", error));
  }, [apiUrl]);

  //   useEffect(() => {
  //     fetch(`${apiUrl}/dead`)
  //       .then((response) => response.json())
  //       .then((data) => setDead(data))
  //       .catch((error) => console.error('Error:', error));
  //   }, []);

  useEffect(() => {
    axios
      .get(`${apiUrl}/poultry/total`)
      .then((response) => {
        setTotalPoultry(response.data.totalQuantity);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [apiUrl]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/water/total`)
      .then((response) => {
        setTotalCapacity(response.data.totalCapacity);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [apiUrl]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/feeds/total`)
      .then((response) => {
        setTotalFeedQuantity(response.data.totalFeedQuantity);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [apiUrl]);
  useEffect(() => {
    axios
      .get(`${apiUrl}/water/refill`)
      .then((response) => {
        setTotalRefill(response.data.totalRefill);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [apiUrl]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/goats/total`)
      .then((response) => {
        setTotalGoats(response.data.totalGoats);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [apiUrl]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/sheep/total`)
      .then((response) => {
        setTotalSheep(response.data.totalSheep);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [apiUrl]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/total/pigs`)
      .then((response) => {
        setTotalPigs(response.data.totalPigs);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [apiUrl]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/users/total`)
      .then((response) => {
        setTotalUsers(response.data.totalUsers);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [apiUrl]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/admins/total`)
      .then((response) => {
        setTotalAdmins(response.data.totalAdmins);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [apiUrl]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/poultry/total`)
      .then((response) => {
        setTotalQuantity(response.data.totalQuantity);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [apiUrl]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/eggsSold/total`)
      .then((response) => {
        setTotalEggsSold(response.data.totalEggsSold);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [apiUrl]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/eggsCollected/total`)
      .then((response) => {
        setTotalEggsCollected(response.data.totalCollected);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [apiUrl]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/traysCollected/total`)
      .then((response) => {
        setTotalTraysCollected(response.data.totalTrays);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [apiUrl]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/totalBirdsSold`)
      .then((response) => {
        setTotalBirdsSold(response.data.totalBirdsSold);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [apiUrl]);
  useEffect(() => {
    axios
      .get(`${apiUrl}/goatsSold/total`)
      .then((response) => {
        setTotalGoatsSold(response.data.totalGoatsSold);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [apiUrl]);
  useEffect(() => {
    axios
      .get(`${apiUrl}/totalGoatsDead`)
      .then((response) => {
        setTotalGoatsDead(response.data.totalGoatsDead);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [apiUrl]);
  useEffect(() => {
    axios
      .get(`${apiUrl}/goatsSired/total`)
      .then((response) => {
        setTotalGoatsSired(response.data.totalSired);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [apiUrl]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/pigsSold/total`)
      .then((response) => {
        setTotalPigsSold(response.data.totalpigsSold);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [apiUrl]);
  useEffect(() => {
    axios
      .get(`${apiUrl}/pigsDead/total`)
      .then((response) => {
        setTotalPigsDead(response.data.totalPigsDead);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [apiUrl]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/eggsBroken/total`)
      .then((response) => {
        setTotalSpoilt(response.data.totalSpoilt);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [apiUrl]);
  useEffect(() => {
    axios
      .get(`${apiUrl}/dead/total`)
      .then((response) => {
        setTotalDead(response.data.totalDead);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [apiUrl]);
  useEffect(() => {
    axios
      .get(`${apiUrl}/pigsSired/total`)
      .then((response) => {
        setPigsSired(response.data.totalpigsSired);
        // console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [apiUrl]);

  return (
    <React.Fragment>
      <Row>
        <Col md={6} xl={3} xs={6}>
          <Link to="/poultry/poultry">
            <Card style={{}}>
              <Card.Body className="">
                <h6 className="mb-4 ">Poultry</h6>
                <div className="row d-flex align-items-center">
                  <div className="col-9">
                    {totalPoultry && (
                      <h5 className="f-w-300 d-flex align-items-center m-b-0 ">
                        {/* <i className="feather icon-arrow-up text-c-green f-30 m-r-5" />  */}
                        {totalPoultry.toLocaleString()}
                      </h5>
                    )}
                  </div>

                  <div className="col-3 text-right">
                    {/* <i class="fa fa-leaf f-20 text-c-grey m-r-5" aria-hidden="true"></i> */}
                    <img
                      src="/assets/cock.png"
                      alt="Chicken"
                      style={{ minWidth: "10px", maxWidth: "30px" }}
                    />
                  </div>
                </div>
                <div
                  className="progress m-t-30"
                  style={{ height: "7px" }}
                ></div>
              </Card.Body>
            </Card>
          </Link>
        </Col>

        <Col md={6} xl={3} xs={6}>
          <Link to="/pigs/pigs">
            <Card style={{}}>
              <Card.Body className="">
                <h6 className="mb-4 ">Pigs</h6>
                <div className="row d-flex align-items-center">
                  <div className="col-9">
                    {totalPigs && (
                      <h5 className="f-w-300 d-flex align-items-center m-b-0 ">
                        {/* <i className="feather icon-arrow-down text-c-red f-30 m-r-5" />  */}
                        {totalPigs.toLocaleString()}
                      </h5>
                    )}
                  </div>
                  <div className="col-3 text-right">
                    {/* <i class="fa fa-bug f-20 text-c-grey m-r-5" aria-hidden="true"></i> */}
                    <img
                      src="/assets/pig (1).png"
                      alt="Chicken"
                      style={{ minWidth: "10px", maxWidth: "30px" }}
                    />
                  </div>
                </div>
                <div className="progress m-t-30" style={{ height: "7px" }}>
                  {/* <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ minWidth: "10px", maxWidth: `${progressminWidth: "10px", maxWidth}%`, backgroundColor: progressBarColor }}
                            aria-valuenow={progressminWidth: "10px", maxWidth}
                            aria-valuemin="0"
                            aria-valuemax="100"
                        /> */}

                  {/* <div
                                    className="progress-bar progress-c-theme"
                                    role="progressbar"
                                    style={{ minWidth: "10px", maxWidth: '70%' }}
                                    aria-valuenow="70"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                /> */}
                </div>
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col md={6} xl={3} xs={6}>
          <Link to="/goats/goats">
            <Card style={{}}>
              <Card.Body className="">
                <h6 className="mb-4 ">Goats</h6>
                <div className="row d-flex align-items-center">
                  <div className="col-9">
                    {totalGoats && (
                      <h5 className="f-w-300 d-flex align-items-center m-b-0 ">
                        {/* <i className="feather icon-arrow-up text-c-green f-30 m-r-5" />  */}
                        {totalGoats.toLocaleString()}
                      </h5>
                    )}
                  </div>

                  <div className="col-3 text-right">
                    {/* <i class="fa fa-tags f-20 text-c-grey m-r-5" aria-hidden="true"></i> */}
                    <img
                      src="/assets/goat (1).png"
                      alt="Chicken"
                      style={{ minWidth: "10px", maxWidth: "30px" }}
                    />
                  </div>
                </div>
                <div className="progress m-t-30" style={{ height: "7px" }}>
                  {/* <div
                                    className="progress-bar progress-c-theme"
                                    role="progressbar"
                                    style={{ minWidth: "10px", maxWidth: '70%' }}
                                    aria-valuenow="70"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                /> */}
                </div>
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col md={6} xl={3} xs={6}>
          {/* {space !== "User" ? ( */}

          {/* <Link to="/users/employees"> */}
          <Link to="/sheep/sheep">
            <Card style={{}}>
              <Card.Body className="">
                <h6 className="mb-4">Sheep</h6>
                <div className="row d-flex align-items-center">
                  <div className="col-9">
                    {totalSheep && (
                      <h5 className="f-w-300 d-flex align-items-center m-b-0">
                        {totalSheep?.toLocaleString()}
                      </h5>
                    )}
                  </div>
                  <div className="col-3 text-right">
                    {/* <i class="fa fa-users f-20 text-c-grey m-r-5" aria-hidden="true"></i> */}
                    <img
                      src="/assets/sheep1.png"
                      alt="Chicken"
                      style={{ minWidth: "10px", maxWidth: "30px" }}
                    />
                  </div>
                </div>
                <div
                  className="progress m-t-30"
                  style={{ height: "7px" }}
                ></div>
              </Card.Body>
            </Card>
          </Link>
        </Col>

        <Col md={12} xl={12} sm={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h5">Animal Management</Card.Title>
            </Card.Header>
            <Card.Body style={{ padding: "0 25px" }}>
              <Row>
                {/* Poultry */}
                <Col sm={12} md={3} xs={6}>
                  <p className="m-t-10">Poultry</p>
                  {apiUrl && (
                    <ModuleNotification
                      message={"Total Birds"}
                      className="feather icon-trending-up"
                      count={totalQuantity.toLocaleString()}
                    />
                  )}
                  {apiUrl && (
                    <ModuleNotification
                      message="Birds Sold"
                      count={totalBirdsSold.toLocaleString()}
                    />
                  )}
                  {apiUrl && (
                    <ModuleNotification
                      message="Birds Mortality"
                      count={totalDead.toLocaleString()}
                    />
                  )}
                </Col>

                {/* Pigs */}
                <Col sm={12} md={3} xs={6}>
                  <p className="m-t-10">
                    Total Eggs - {totalEggsCollected.toLocaleString()}
                  </p>
                  {apiUrl && (
                    <ModuleNotification
                      message="Trays Available"
                      count={totalTraysCollected.toLocaleString()}
                    />
                  )}
                  {apiUrl && (
                    <ModuleNotification
                      message="Trays Sold"
                      count={totalEggsSold.toLocaleString()}
                    />
                  )}
                  {apiUrl && (
                    <ModuleNotification
                      message={"Broken Eggs"}
                      className="feather icon-trending-up"
                      count={totalSpoilt.toLocaleString()}
                    />
                  )}
                </Col>

                {/* Goats */}
                <Col sm={12} md={3} xs={6}>
                  <p className="m-t-10">Goats</p>
                  {apiUrl && (
                    <ModuleNotification
                      message="Goats Sold"
                      count={totalGoatsSold.toLocaleString()}
                    />
                  )}
                  {apiUrl && (
                    <ModuleNotification
                      message="Kids Sired"
                      count={totalSired.toLocaleString()}
                    />
                  )}
                  {apiUrl && (
                    <ModuleNotification
                      message={"Goats Mortality"}
                      className="feather icon-trending-up"
                      count={totalGoatsDead.toLocaleString()}
                    />
                  )}
                </Col>

                {/* Something els */}
                <Col sm={12} md={3} xs={6}>
                  <p className="m-t-10">Pigs</p>
                  {apiUrl && (
                    <ModuleNotification
                      message="Pigs Sold"
                      count={totalpigsSold.toLocaleString()}
                    />
                  )}
                  {apiUrl && (
                    <ModuleNotification
                      message="Piglets Sired"
                      count={totalpigsSired.toLocaleString()}
                    />
                  )}
                  {apiUrl && (
                    <ModuleNotification
                      message="Pigs Mortality"
                      count={totalPigsDead.toLocaleString()}
                    />
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        {/* {account.user.is_admin === 'Admin' ? ( */}
        <Col md={6} xl={3} sm={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h5">Tentative Profit & Loss</Card.Title>
            </Card.Header>
            <Row>
              {space !== "User" ? (
                <Col md={12} sm={12}>
                  <Link to="/earnings">
                    <Card.Body className="border-bottom">
                      <div className="row d-flex align-items-center">
                        <div className="col-2">
                          {" "}
                          <img
                            src="/assets/income.png"
                            alt="Earnings"
                            style={{ width: "30px" }}
                          />
                        </div>

                        <div className="col-10 mt-2">
                          <span className="d-block text-uppercase">
                            Total Earnings
                          </span>
                          {/* <i className="feather icon-trending-up text-c-green f-30 m-r-5" />  */}
                          {earnings && (
                            <h5 className="">
                              Ksh.{" "}
                              {earnings.totalEarnings !== null
                                ? earnings.totalEarnings.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    },
                                  )
                                : ""}
                            </h5>
                          )}
                        </div>
                      </div>
                    </Card.Body>
                  </Link>
                </Col>
              ) : (
                <Col md={12} sm={12}>
                  <Card.Body className="border-bottom">
                    <div className="row d-flex align-items-center">
                      <div className="col-2">
                        {" "}
                        <img
                          src="/assets/income.png"
                          alt="Earnings"
                          style={{ width: "30px" }}
                        />
                      </div>

                      <div className="col-10 mt-2">
                        <span className="d-block text-uppercase">
                          Total Earnings
                        </span>
                        {/* <i className="feather icon-trending-up text-c-green f-30 m-r-5" />  */}
                        {earnings && <h5 className="">Ksh. 0.00</h5>}
                      </div>
                    </div>
                  </Card.Body>
                </Col>
              )}
              {space !== "User" ? (
                <Col md={12} sm={12}>
                  <Link to="/expenditure">
                    <Card.Body className="border-bottom">
                      <div className="row d-flex align-items-center">
                        <div className="col-2">
                          <img
                            src="/assets/expenses.png"
                            alt="Expense"
                            style={{ width: "30px" }}
                          />
                        </div>
                        <div className="col-10 mt-2">
                          <span className="d-block text-uppercase">
                            Total Expenditure
                          </span>
                          {/* <i className="feather icon-trending-down text-c-green f-30 m-r-5" />  */}
                          {expenses && (
                            <h5 className="">
                              Ksh.{" "}
                              {expenses.totalExpenses !== null
                                ? expenses.totalExpenses.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    },
                                  )
                                : ""}
                            </h5>
                          )}
                        </div>
                      </div>
                    </Card.Body>
                  </Link>
                </Col>
              ) : (
                <Col md={12} sm={12}>
                  <Card.Body className="border-bottom">
                    <div className="row d-flex align-items-center">
                      <div className="col-2">
                        <img
                          src="/assets/expenses.png"
                          alt="Expense"
                          style={{ width: "30px" }}
                        />
                      </div>
                      <div className="col-10 mt-2">
                        <span className="d-block text-uppercase">
                          Total Expenditure
                        </span>
                        {/* <i className="feather icon-trending-down text-c-green f-30 m-r-5" />  */}
                        {expenses && <h5 className="">Ksh. 0.00</h5>}
                      </div>
                    </div>
                  </Card.Body>
                </Col>
              )}
            </Row>
          </Card>
        </Col>
        {/* ) : null} */}

        <Col md={6} xl={3} sm={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h5">Water</Card.Title>
            </Card.Header>
            <Link to="/water">
              <Card.Body className="border-bottom">
                <div className="row d-flex align-items-center">
                  <div className="col-2">
                    <img
                      src="/assets/water.png"
                      alt="water"
                      style={{ width: "30px" }}
                    />
                  </div>
                  <div className="col-10 mt-2">
                    <span className="d-block text-uppercase">
                      available water
                    </span>

                    <h5>
                      {totalCapacity && (
                        <h5>{totalCapacity.toLocaleString()} Litres</h5>
                      )}
                    </h5>
                  </div>
                </div>
              </Card.Body>
            </Link>
            <Link to="/water/water-refill">
              <Card.Body className="border-bottom">
                <div className="row d-flex align-items-center">
                  <div className="col-2">
                    <img
                      src="/assets/water.png"
                      alt="water"
                      style={{ width: "30px" }}
                    />
                  </div>
                  <div className="col-10 mt-2">
                    <span className="d-block text-uppercase">
                      water refilled
                    </span>
                    <h5>
                      {totalRefill && (
                        <h5>{totalRefill.toLocaleString()} Litres</h5>
                      )}
                    </h5>
                  </div>
                </div>
              </Card.Body>
            </Link>
          </Card>
        </Col>

        <Col md={6} xl={3} sm={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h5">Feeds</Card.Title>
            </Card.Header>
            <Link to="/feeds">
              <Card.Body className="border-bottom">
                <div className="row d-flex align-items-center">
                  <div className="col-2">
                    <img
                      src="/assets/feed.png"
                      alt="Chicken"
                      style={{ width: "30px" }}
                    />
                  </div>
                  <div className="col-10 mt-2">
                    <span className="d-block text-uppercase">
                      available feeds
                    </span>

                    {totalFeedQuantity && (
                      <h5>{totalFeedQuantity.toLocaleString()} Kgs</h5>
                    )}
                  </div>
                </div>
              </Card.Body>
            </Link>
            <Link to="/feeds/consumed-feeds">
              <Card.Body className="border-bottom">
                <div className="row d-flex align-items-center">
                  <div className="col-2">
                    <img
                      src="/assets/feed.png"
                      alt="Chicken"
                      style={{ width: "30px" }}
                    />
                  </div>
                  <div className="col-10 mt-2">
                    <span className="d-block text-uppercase">
                      Feeds consumed
                    </span>
                    <h5>{feed.totalFeed.toLocaleString()} Kgs</h5>
                  </div>
                </div>
              </Card.Body>
            </Link>
          </Card>
        </Col>

        {space === "Admin" ? (
          <Col md={6} xl={3} sm={12}>
            <Card>
              <Card.Header>
                <Card.Title as="h5">Users</Card.Title>
              </Card.Header>
              <Link to="/users/employees">
                <Card.Body className="border-bottom">
                  <div className="row d-flex align-items-center">
                    <div className="col-2">
                      <img
                        src="/assets/group.png"
                        alt="Chicken"
                        style={{ width: "30px" }}
                      />
                    </div>
                    <div className="col-10 mt-2">
                      <span className="d-block text-uppercase">Employees</span>

                      {totalUsers && <h5>{totalUsers.toLocaleString()}</h5>}
                    </div>
                  </div>
                </Card.Body>
              </Link>
              <Link to="/users/admins">
                <Card.Body className="border-bottom">
                  <div className="row d-flex align-items-center">
                    <div className="col-2">
                      <img
                        src="/assets/group.png"
                        alt="Chicken"
                        style={{ width: "30px" }}
                      />
                    </div>
                    <div className="col-10 mt-2">
                      <span className="d-block text-uppercase">Admins</span>
                      {totalAdmins && <h5>{totalAdmins.toLocaleString()}</h5>}
                    </div>
                  </div>
                </Card.Body>
              </Link>
            </Card>
          </Col>
        ) : null}

        <Col md={6} xl={6} sm={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h5">Stock</Card.Title>
            </Card.Header>
            <Card.Body className="border-bottom">
              <MultiBarChart />
            </Card.Body>
          </Card>
        </Col>

        <Col md={6} xl={6} sm={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h5">Sales</Card.Title>
            </Card.Header>
            <Card.Body className="border-bottom">
              <BarDiscreteChart />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default DashDefault;
