import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Table, Button, Pagination, Form } from "react-bootstrap";
import Select from "react-select";
import Sup from "./Sample3";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import config from "../../config";
import { useParams, useHistory, Link } from "react-router-dom";
import Card from "../../components/Card/MainCard";
import { usePermissions } from "../../permissions";
import DataTable from "react-data-table-component";

const UserList = () => {
  const account = useSelector((state) => state.account);
  const [users, setUsers] = useState([]);
  const { id } = useParams();
  const history = useHistory();
  const [isPopupVisible, setPopupVisible] = useState(false);
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const { createPermission, deletePermission, editPermission } =
    usePermissions();
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/stakeholders`);
        setUsers(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [users, apiUrl]);

  const handleDelete = async (userId, name) => {
    try {
      const confirmed = window.confirm(
        `Are you sure you want to archive this Supplier ${name}?`,
      );
      if (confirmed) {
        await axios.put(`${apiUrl}/supplier/${userId}/archive`);
        alert("Supplier archiveed successfully!");
        window.location.reload();
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const handleUpdate = (userId) => {
    const userToUpdate = users.find((user) => user.id === userId);
    history.push(`stakeholder/update-stakeholder/${userId}`, {
      user: userToUpdate,
    });
  };

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);

    // Get the UTC values for year, month, and day
    const todayUTC = Date.UTC(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
    );
    const birthUTC = Date.UTC(
      birth.getFullYear(),
      birth.getMonth(),
      birth.getDate(),
    );

    // Calculate the age difference in milliseconds
    const ageDiffMillis = todayUTC - birthUTC;

    // Convert milliseconds to years, months, and days
    const yearDiff = Math.floor(ageDiffMillis / (1000 * 60 * 60 * 24 * 365));
    const monthDiff = Math.floor(
      (ageDiffMillis % (1000 * 60 * 60 * 24 * 365)) /
        (1000 * 60 * 60 * 24 * 30),
    );
    const dayDiff = Math.floor(
      (ageDiffMillis % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24),
    );

    if (yearDiff > 0) {
      return yearDiff === 1 ? `${yearDiff} year ago` : `${yearDiff} years ago`;
    } else if (monthDiff > 0) {
      return monthDiff === 1
        ? `${monthDiff} month ago`
        : `${monthDiff} months ago`;
    } else if (dayDiff > 0) {
      return dayDiff === 1 ? `${dayDiff} day ago` : `${dayDiff} days ago`;
    } else {
      return dayDiff === 0 ? `Today` : `${dayDiff} days ago`;
    }
  };

  const hidePopup = () => {
    setPopupVisible(false);
  };

  const showPopup = () => {
    setPopupVisible(true);
  };

  const csvLinkRef = useRef();

  const exportToCSV = () => {
    const csvData = [
      ["Name", "Email", "Phone No.", "Speciality", "Location"],
      ...filteredData.map((item) => [
        item.name,
        item.email,
        item.phone,
        item.speciality,
        item.location,
      ]),
    ];

    const csvContent = csvData.map((row) => row.join(",")).join("\n");
    const blob = new Blob(["\ufeff", csvContent], {
      type: "text/csv;charset=utf-8;",
    });

    const csvUrl = URL.createObjectURL(blob);
    csvLinkRef.current.href = csvUrl;
    csvLinkRef.current.download = "sfarms.csv";
    csvLinkRef.current.click();
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [["Name", "Email", "Phone No.", "Speciality", "Location"]],
      body: filteredData.map((user) => [
        user.name,
        user.email,
        user.phone,
        user.speciality,
        user.location,
      ]),
    });
    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl);
  };

  const columns = [
    // {
    //     name: 'ID',
    //     selector: (row) => row.id,
    //     sortable: true,
    //     minWidth: '100px',
    //     maxWidth: '100px'
    // },

    {
      name: "Name",
      selector: (row) => (
        <Link to={`stakeholders/view-stakeholder/${row.id}`}>{row.name}</Link>
      ),
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "email",
      selector: (row) => (
        <Link to={`stakeholders/view-stakeholder/${row.id}`}>{row?.email}</Link>
      ),
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "phone",
      selector: (row) => (
        <Link to={`stakeholders/view-stakeholder/${row.id}`}>{row?.phone}</Link>
      ),
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },

    // {
    //     name: 'location',
    //     selector: (row) => row.location,
    //     sortable: true,
    //     wrap: true,
    //     minWidth: '150px'
    // },
    // {
    //     name: 'speciality',
    //     selector: (row) => row.speciality || "N/A",
    //     sortable: true,
    //     wrap: true,
    //     minWidth: '150px'
    // },
    {
      name: "type",
      selector: (row) => (
        <Link to={`stakeholders/view-stakeholder/${row.id}`}>
          <span className="text-capitalize">{row.type}</span>
        </Link>
      ),
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "Timestamp",
      selector: (row) => (
        <Link to={`stakeholders/view-stakeholder/${row.id}`}>
          {calculateAge(row.created_at)}
        </Link>
      ),
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "Action",
      cell: (row) => (
        <td>
          {editPermission ? (
            <Button size="sm" onClick={() => handleUpdate(row.id)}>
              <span className="feather icon-edit"></span> Edit
            </Button>
          ) : (
            "-"
          )}
          {deletePermission ? (
            <Button
              variant="danger"
              size="sm"
              onClick={() => handleDelete(row.id, row.name)}
            >
              <span className="feather icon-delete"></span> Delete
            </Button>
          ) : (
            "-"
          )}
        </td>
      ),
      wrap: true,
      minWidth: "150px",
    },
  ];

  const filteredData = searchText
    ? users.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchText.toLowerCase()),
        ),
      )
    : users;

  return (
    <React.Fragment>
      <Row className="btn-page">
        <Col>
          <div className="d-flex justify-content-between align-items-center m-b-10">
            {createPermission && (
              <Button onClick={showPopup} className="mb-10">
                <span className="feather icon-plus-circle"></span> Add
                Stakeholder
              </Button>
            )}
            {isPopupVisible && <Sup onCancel={hidePopup} />}
          </div>

          <Row className="btn-page">
            <Col>
              {users && users.length > 0 && (
                <Row>
                  <Form.Group
                    as={Col}
                    md={6}
                    sm={12}
                    lg={6}
                    controlId="formBasicName"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Search..."
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={6}
                    sm={12}
                    lg={6}
                    controlId="formBasicName"
                  >
                    <div className="d-flex">
                      <Button onClick={exportToCSV}>
                        <span className="feather icon-file"></span> Export CSV
                      </Button>
                      <a ref={csvLinkRef} style={{ display: "none" }} />
                      <a
                        href="#"
                        className="btn btn-primary"
                        onClick={exportToPDF}
                      >
                        <span className="feather icon-file-text"></span> Export
                        PDF
                      </a>
                    </div>
                  </Form.Group>
                </Row>
              )}
              <Row>
                <Col>
                  <DataTable
                    columns={columns}
                    data={filteredData}
                    pagination
                    highlightOnHover
                    striped
                    // noHeader
                    dense
                    bordered
                    paginationPerPage={20}
                    paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                    paginationComponentOptions={{
                      rowsPerPageText: "Rows per page:",
                    }}
                    paginationResetDefaultPage={true}
                    paginationTotalRows={users.length}
                    // expandableRows
                    selectableRows
                    onSelectedRowsChange={(selectedRows) =>
                      console.log(selectedRows)
                    }
                    customStyles={{
                      headRow: {
                        style: {
                          backgroundColor: "#279e6c", // Green background
                          color: "#fff", // White text
                          textTransform: "uppercase", // Uppercase text
                          // fontSize: '10px', // Adjust font size for header row
                          minHeight: "1.5rem",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                        },
                      },
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserList;
