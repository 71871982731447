import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  Row,
  Col,
  Form,
  Button,
  Table,
  Alert,
  Tabs,
  Tab,
  Modal,
  Pagination,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import Card from "../../../components/Card/MainCard";
import config from "../../../config";
import Receipt from "../../extra/GeneralReceipt01.js";
import { Link, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import ConfirmationModal from "./ConfirmationModal.js";

// Register a custom font (optional)
Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  heading: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 5,
  },
  label: {
    fontSize: 12,
    marginBottom: 3,
  },
  value: {
    fontSize: 12,
  },
  footer: {
    fontSize: 12,
    marginTop: 10,
    textAlign: "center",
  },
});

const ReceiptPDF = ({ receiptData, onDownloadButtonClick }) => {
  return (
    <PDFViewer style={{ width: "100%", height: "100vh" }}>
      <Document>
        <Page style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.heading}>SFARMS SALES RECEIPT</Text>
            <Text style={styles.heading}>
              RECEIPT: #{receiptData.transactionId}
            </Text>
            <Text style={styles.heading}>
              DATE/TIME: {new Date(receiptData.createdAt).toLocaleString()}
            </Text>
            {receiptData.cus_name && (
              <View>
                <Text style={styles.label}>Customer:</Text>
                <Text style={styles.value}>{receiptData.cus_name}</Text>
              </View>
            )}
            {receiptData.birds && (
              <View>
                <Text style={styles.label}>No. of Birds:</Text>
                <Text style={styles.value}>{receiptData.birds}</Text>
              </View>
            )}
            {receiptData.trays && (
              <View>
                <Text style={styles.label}>No. of Trays:</Text>
                <Text style={styles.label}>{receiptData.trays}</Text>
              </View>
            )}
            {receiptData.eggs && (
              <View>
                <Text style={styles.label}>No. of Eggs:</Text>
                <Text style={styles.label}>{receiptData.eggs}</Text>
              </View>
            )}
            {receiptData.tagNumber && (
              <View>
                <Text style={styles.label}>Tag No.:</Text>
                <Text style={styles.value}>{receiptData.tagNumber}</Text>
              </View>
            )}
            {receiptData.goats && (
              <View>
                <Text style={styles.label}>No. of Goats:</Text>
                <Text style={styles.value}>{receiptData.goats}</Text>
              </View>
            )}
            {receiptData.pigs && (
              <View>
                <Text style={styles.label}>No. of Pigs:</Text>
                <Text style={styles.value}>{receiptData.pigs}</Text>
              </View>
            )}
            {receiptData.price && (
              <View>
                <Text style={styles.label}>Price:</Text>
                <Text style={styles.value}>
                  Ksh. {receiptData.price.toLocaleString()}
                </Text>
              </View>
            )}
            {receiptData.pay_method && (
              <View>
                <Text style={styles.label}>Payment Mode:</Text>
                <Text style={styles.value}>{receiptData.pay_method}</Text>
              </View>
            )}
            {receiptData.delivery && (
              <View>
                <Text style={styles.label}>Delivery Mode:</Text>
                <Text style={styles.value}>{receiptData.delivery}</Text>
              </View>
            )}
            {receiptData.del_guy && (
              <View>
                <Text style={styles.label}>Delivery Guy:</Text>
                <Text style={styles.value}>{receiptData.del_guy}</Text>
              </View>
            )}
            {receiptData.pay_reference && (
              <View>
                <Text style={styles.label}>Payment Reference:</Text>
                <Text style={styles.value}>{receiptData.pay_reference}</Text>
              </View>
            )}
            {receiptData.transactionId && (
              <View>
                <Text style={styles.label}>Transaction ID :</Text>
                <Text style={styles.value}>{receiptData.transactionId}</Text>
              </View>
            )}
            {receiptData.createdAt && (
              <View>
                <Text style={styles.label}>Date / Time :</Text>
                <Text style={styles.value}>
                  {new Date(receiptData.createdAt).toLocaleString()}
                </Text>
              </View>
            )}
            <View style={styles.footer}>
              <Text>Welcome Again</Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

const TransactionHistory = () => {
  const { selectedFarm } = useParams();
  const [error, setError] = useState(null);
  const formRef = useRef(null);
  const [success, setSuccess] = useState(null);
  const [transactionData, setTransactionData] = useState([]);
  const [showReceipt, setShowReceipt] = useState(false);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [receiptData, setReceiptData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationData, setConfirmationData] = useState({
    id: null,
    description: null,
  });

  const handleModal = async (transactionId) => {
    console.log("View Button Clicked with Transaction ID:", transactionId);
    setSelectedTransaction(transactionId);
    setShowReceiptModal(true);
    setShowReceipt(true);
    setTransactionId(transactionId);
  };

  const handleDownloadButtonClick = (fileUrl) => {
    axios
      .get(fileUrl, { responseType: "blob" })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileUrl.split("/").pop());
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
        toast.error("Error downloading file.");
      });
  };

  const handleArchive = (id, description) => {
    setConfirmationData({ id, description });
    setShowConfirmationModal(true);
    console.log(id, description);
  };

  const handleConfirmation = async () => {
    const { id, description } = confirmationData;

    axios
      .post(`${apiUrl}/archive-sales/${id}/${description}`)
      .then(() => {
        // Update the frontend state to reflect the archived status
        setTransactionData((prevData) =>
          prevData.map((transaction) => {
            if (transaction.id === id) {
              return { ...transaction, archived: true };
            }
            return transaction;
          }),
        );
        toast.success("Sales Transaction archived successfully.");
        setShowConfirmationModal(false);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          // Display the error message to the user
          toast.error(error.response.data.error);
          setShowConfirmationModal(false);
        } else {
          // Handle other types of errors
          console.error("Error archiving transaction:", error);
          toast.error("Error archiving transaction.");
          setShowConfirmationModal(false);
        }
      });
  };

  useEffect(() => {
    const fetchTransactionData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/all-summary-receipts/${transactionId}`,
        );
        setReceiptData(response.data.receiptData);
      } catch (error) {
        console.error("Error retrieving receipt data:", error);
      }
    };

    fetchTransactionData();
  }, [transactionId]);

  useEffect(() => {
    const fetchTransactionData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/all-transaction-history?farm=${selectedFarm}`,
        );
        setTransactionData(response.data);
        toast.success("Transaction history fetched successfully.");
      } catch (error) {
        console.error("Error fetching transaction history:", error);
        toast.error("Error fetching transaction history.");
      }
    };

    fetchTransactionData();
  }, [apiUrl, selectedFarm]);

  const handleCloseReceiptModal = () => {
    setShowReceiptModal(false);
  };

  const handlePrint = () => {
    window.print();
  };

  const getLabelColorClass = (description) => {
    switch (description) {
      case "Goats Sales":
        return "label-orange";
      case "Eggs Sales":
        return "label-blue";
      case "Pigs Sales":
        return "label-till";
      case "Poultry Sales":
        return "label-brown";
      case "Broken Eggs":
        return "label-kink";
      default:
        return "";
    }
  };

  const csvLinkRef = useRef();

  const exportToCSV = () => {
    const csvData = [
      [
        "Description",
        "TransactionID",
        "Customer",
        "Amount",
        "Payment Mode",
        "Reference",
        "Date/Time",
        "Generated By",
        "Farm",
      ],
      ...filteredData.map((item) => [
        item.description,
        item.transactionId,
        item.cus_name,
        item.amount,
        item.pay_method,
        item.pay_reference,
        item.createdAt,
        item.servedBy,
        item.farm,
      ]),
    ];

    const csvContent = csvData.map((row) => row.join(",")).join("\n");
    const blob = new Blob(["\ufeff", csvContent], {
      type: "text/csv;charset=utf-8;",
    });

    const csvUrl = URL.createObjectURL(blob);
    csvLinkRef.current.href = csvUrl;
    csvLinkRef.current.download = "sfarms.csv";
    csvLinkRef.current.click();
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        [
          "Description",
          "TransactionID",
          "Customer",
          "Amount",
          "Payment Mode",
          "Reference",
          "Date/Time",
          "Generated By",
          "Farm",
        ],
      ],
      body: filteredData.map((user) => [
        user.description,
        user.transactionId,
        user.cus_name,
        user.amount.toLocaleString(),
        user.pay_method,
        user.pay_reference,
        user.createdAt.toLocaleString(),
        user.servedBy,
        user.farm,
      ]),
    });
    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl);
  };

  const handleCancelConfirmation = () => {
    setShowConfirmationModal(false);
  };

  const handleViewFile = (image) => {
    window.open(`${apiUrl}/uploads/${image}`, "_blank");
  };

  const columns = [
    {
      name: "Description",
      selector: (row) => (
        <div className={getLabelColorClass(row.description)}>
          {row.description}
        </div>
      ),
      sortable: true,
      wrap: true,
      minWidth: "100px",
      maxWidth: "200px",
    },
    {
      name: "Transaction ID",
      selector: (row) => row.transactionId,
      sortable: true,
      wrap: true,
      minWidth: "100px",
      maxWidth: "200px",
    },
    {
      name: "Customer",
      selector: (row) => row.cus_name,
      sortable: true,
      wrap: true,
      minWidth: "120px",
      maxWidth: "220px",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      cell: (row) =>
        row.amount !== null
          ? row.amount.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "",
      sortable: true,
      wrap: true,
      minWidth: "100px",
      maxWidth: "200px",
    },
    {
      name: "Mode",
      selector: (row) => row.pay_method,
      sortable: true,
      wrap: true,
      minWidth: "100px",
      maxWidth: "200px",
    },
    {
      name: "Reference",
      selector: (row) => row.pay_reference,
      sortable: true,
      wrap: true,
      minWidth: "100px",
      maxWidth: "200px",
    },
    {
      name: "Timestamp",
      selector: (row) => new Date(row.createdAt).toLocaleString(),
      sortable: true,
      wrap: true,
      minWidth: "180px",
      maxWidth: "220px",
    },
    // { name: 'Generated By', selector: (row) => row.servedBy, sortable: true, wrap: true },
    // { name: 'Farm', selector: (row) => row.farm, sortable: true, wrap: true },
    {
      name: "Receipt",
      cell: (row) => (
        <div>
          <Button onClick={() => handleModal(row.transactionId)}>
            <i className="feather icon-eye" />
          </Button>
          {row.image && (
            <Button onClick={() => handleViewFile(row.image)}>
              <i className="feather icon-download-cloud" /> View
            </Button>
          )}
          {row.archive === 1 ? (
            <Button variant="secondary">
              <i className="feather icon-slash" />
              {/* Archived */}
            </Button>
          ) : (
            <Button
              variant="danger"
              onClick={() => handleArchive(row.id, row.description)}
            >
              <i className="feather icon-x-square" />
              {/* Archive */}
            </Button>
          )}
        </div>
      ),
      minWidth: "200px",
      maxWidth: "300px",
    },
  ];

  const filteredData = transactionData.filter((item) => {
    // Check if the item matches the search text
    const matchesSearchText =
      !searchText ||
      Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchText.toLowerCase()),
      );

    return matchesSearchText;
    // return matchesSearchText && matchesStatusFilter && matchesEventTypeFilter;
  });

  return (
    <React.Fragment>
      <Row>
        <Col sm={12}>
          {success && (
            <Alert
              variant="success"
              onClose={() => setSuccess(null)}
              dismissible
            >
              {success}
            </Alert>
          )}
          {error && (
            <Alert variant="danger" onClose={() => setError(null)} dismissible>
              {error}
            </Alert>
          )}
          <ToastContainer />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            <div className="pb-3">
              <Link to="/farms/manage">
                <Button variant="primary" className="">
                  <span className="feather icon-arrow-left"></span> Go Back
                </Button>
              </Link>
            </div>
          </div>
          <Row>
            <Form.Group
              as={Col}
              md={4}
              sm={12}
              lg={4}
              controlId="formBasicName"
            >
              <Form.Control
                type="text"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              md={4}
              sm={12}
              lg={4}
              controlId="formBasicName"
            >
              <Form.Control
                type="date"
                placeholder="From..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Form.Group>
            {filteredData && filteredData.length > 0 && (
              <Form.Group
                as={Col}
                md={4}
                sm={12}
                lg={4}
                controlId="formBasicName"
              >
                <Button onClick={exportToCSV}>Export as CSV</Button>
                <a ref={csvLinkRef} style={{ display: "none" }} />
                <a href="#" className="btn btn-primary" onClick={exportToPDF}>
                  Export PDF
                </a>
              </Form.Group>
            )}
          </Row>
          <Row>
            <Col>
              <DataTable
                // title="Combined Email Logs"
                columns={columns}
                data={filteredData}
                // expandableRows
                // expandableRowsComponent={ExpandableComponent}
                pagination
                highlightOnHover
                striped
                // noHeader
                dense
                bordered
                paginationPerPage={20}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                paginationComponentOptions={{
                  rowsPerPageText: "Rows per page:",
                }}
                paginationResetDefaultPage={true}
                paginationTotalRows={transactionData.length}
                // expandableRows
                // selectableRows
                onSelectedRowsChange={(selectedRows) =>
                  console.log(selectedRows)
                }
                customStyles={{
                  headRow: {
                    style: {
                      backgroundColor: "#279e6c", // Green background
                      color: "#fff", // White text
                      textTransform: "uppercase", // Uppercase text
                      // fontSize: '10px', // Adjust font size for header row
                      minHeight: "1.5rem",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                    },
                  },
                }}
              />
            </Col>
          </Row>
          {showReceipt && (
            <Modal
              show={showReceiptModal}
              onHide={handleCloseReceiptModal}
              size="lg"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Receipt</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Receipt transactionId={transactionId} />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="success" onClick={handlePrint}>
                  Print
                </Button>
                <Button variant="secondary" onClick={handleCloseReceiptModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </Col>
      </Row>
      <ConfirmationModal
        show={showConfirmationModal}
        onHide={handleCancelConfirmation}
        onConfirm={handleConfirmation}
      />
    </React.Fragment>
  );
};

export default TransactionHistory;
