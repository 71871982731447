import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Table, Button, Pagination, Form } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import config from "../../config";
import { useParams, useHistory } from "react-router-dom";
import Card from "../../components/Card/MainCard";
import { usePermissions } from "../../permissions";
import DataTable from "react-data-table-component";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const { id } = useParams();
  const history = useHistory();
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const { createPermission, deletePermission, editPermission } =
    usePermissions();
  const [searchText, setSearchText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedEventType, setSelectedEventType] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/consumption-feeds`);
        setUsers(response.data);
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [users, apiUrl]);

  const handleDelete = async (userId, firstname, lastname) => {
    try {
      const confirmed = window.confirm(
        `Are you sure you want to delete this user ${firstname} ${lastname}?`,
      );
      if (confirmed) {
        await axios.delete(`${apiUrl}/user/${userId}`);
        alert("User deleted successfully!");
        window.location.reload();
      }
    } catch (error) {
      alert(error.message);
    }
  };

  // Calculate index of the last user on the current page
  const indexOfLastUser = currentPage * usersPerPage;
  // Calculate index of the first user on the current page
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  // Get the current users to display on the current page
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePerPageChange = (selectedOption) => {
    setUsersPerPage(selectedOption.value);
    setCurrentPage(1); // Reset to the first page when changing the number of users per page
  };
  const options = [
    { value: 5, label: "5" },
    { value: 10, label: "10" },
    { value: 25, label: "25" },
    { value: 50, label: "50" },
    { value: 150, label: "150" },
    { value: 200, label: "200" },
  ];

  const totalPages = Math.ceil(users.length / usersPerPage);

  const handleUpdate = (userId) => {
    const userToUpdate = users.find((user) => user.id === userId);
    history.push(`update-user/${userId}`, { user: userToUpdate });
  };

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);

    // Get the UTC values for year, month, and day
    const todayUTC = Date.UTC(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
    );
    const birthUTC = Date.UTC(
      birth.getFullYear(),
      birth.getMonth(),
      birth.getDate(),
    );

    // Calculate the age difference in milliseconds
    const ageDiffMillis = todayUTC - birthUTC;

    // Convert milliseconds to years, months, and days
    const yearDiff = Math.floor(ageDiffMillis / (1000 * 60 * 60 * 24 * 365));
    const monthDiff = Math.floor(
      (ageDiffMillis % (1000 * 60 * 60 * 24 * 365)) /
        (1000 * 60 * 60 * 24 * 30),
    );
    const dayDiff = Math.floor(
      (ageDiffMillis % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24),
    );

    if (yearDiff > 0) {
      return yearDiff === 1 ? `${yearDiff} year ago` : `${yearDiff} years ago`;
    } else if (monthDiff > 0) {
      return monthDiff === 1
        ? `${monthDiff} month ago`
        : `${monthDiff} months ago`;
    } else {
      return dayDiff === 1 ? `${dayDiff} day ago` : `${dayDiff} days ago`;
    }
  };

  const columns = [
    // {
    //     name: 'ID',
    //     selector: (row) => row.id,
    //     sortable: true
    // },
    {
      name: "Type",
      selector: (row) => row.feed_type,
      sortable: true,
    },
    {
      name: "Feed NAme",
      selector: (row) => row.feed_name,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.consumed,
      sortable: true,
    },
    {
      name: "Date Consumed",
      selector: (row) => new Date(row.recorded).toLocaleDateString(),
      sortable: true,
    },
  ];

  const filteredData = searchText
    ? users.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchText.toLowerCase()),
        ),
      )
    : users;

  return (
    <React.Fragment>
      <Row className="btn-page">
        <Col>
          <div className="d-flex justify-content-between align-items-center m-b-10">
            <div>
              <span> </span>
            </div>
            <div>
              {createPermission && (
                <Link to="/feeds/new-feed">
                  <Button variant="primary" className="">
                    <span className="feather icon-plus-circle"></span> Add Feed
                  </Button>
                </Link>
              )}{" "}
              {/* {createPermission && (
                                <Link to="/animals/feeds-consumption">
                                    <Button variant="primary" className="">
                                        <span className="feather icon-plus-circle"></span> Consume Feed
                                    </Button>
                                </Link>
                            )} */}
            </div>
          </div>

          <Card title="Consumed Feeds" isOption>
            <Row>
              <Col sm={12} lg={3} className="mb-2">
                <Form.Group
                  as={Col}
                  md={12}
                  sm={12}
                  lg={12}
                  controlId="formBasicName"
                >
                  <Form.Control
                    type="text"
                    placeholder="Search..."
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <DataTable
                  columns={columns}
                  data={filteredData}
                  pagination
                  highlightOnHover
                  striped
                  // noHeader
                  dense
                  bordered
                  paginationPerPage={20}
                  paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                  paginationComponentOptions={{
                    rowsPerPageText: "Rows per page:",
                  }}
                  paginationResetDefaultPage={true}
                  paginationTotalRows={users.length}
                  // expandableRows
                  selectableRows
                  onSelectedRowsChange={(selectedRows) =>
                    console.log(selectedRows)
                  }
                  customStyles={{
                    headRow: {
                      style: {
                        backgroundColor: "#279e6c", // Green background
                        color: "#fff", // White text
                        textTransform: "uppercase", // Uppercase text
                        // fontSize: '10px', // Adjust font size for header row
                        minHeight: "1.5rem",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                      },
                    },
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserList;
