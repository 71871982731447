import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Badge,
  Button,
  Table,
  Alert,
  Tab,
  Tabs,
  Form,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SalaryCalculator from "./SalaryCalculator";
import SalaryPay from "./SalaryTable.js";
import LeaveManagement from "./Leave";
import config from "./../../../config";
import { useParams, useHistory, Link } from "react-router-dom";
import Card from "../../../components/Card/MainCard";
import axios from "axios";
import { usePermissions } from "../../../permissions/index.js";
import DataTable from "react-data-table-component";

const BasicBadges = () => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const history = useHistory();
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const { createPermission, deletePermission, editPermission } =
    usePermissions();
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await axios.get(`${apiUrl}/employees_salary`);
        setData(querySnapshot.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [apiUrl, data]);

  const handleUpdate = (salaryId) => {
    const userToUpdate = data.find((user) => user.id === salaryId);
    history.push(`edit-salary/${salaryId}`, { user: userToUpdate });
  };

  const handelDelete = async (salaryId, employee) => {
    try {
      const confirmed = window.confirm(
        `Are you sure you want to delete ${employee}'s salary ?`,
      );
      if (confirmed) {
        await axios.delete(`${apiUrl}/employees_salary/${salaryId}`);
        alert("Salary deleted successfully!");
        window.location.reload();
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const hidePopup = () => {
    setPopupVisible(false);
  };

  const showPopup = () => {
    setPopupVisible(true);
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row?.id,
      sortable: true,
      wrap: true,
      maxWidth: "100px",
      minWidth: "100px",
    },

    {
      name: "Full Names",
      selector: (row) => row.employee,
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },

    {
      name: "NSSF",
      selector: (row) =>
        row?.nssfDeduction !== null
          ? row.nssfDeduction.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "-",
      sortable: true,
      wrap: true,
      minWidth: "170px",
    },
    {
      name: "NHIF",
      selector: (row) =>
        row?.nhifDeduction !== null
          ? row.nhifDeduction.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "-",
      sortable: true,
      wrap: true,
      minWidth: "200px",
    },
    {
      name: "others",
      selector: (row) =>
        row?.otherDeduction !== null
          ? row.otherDeduction.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "-",
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "Salary",
      selector: (row) =>
        row?.salaryAmount !== null
          ? row.salaryAmount.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "-",
      sortable: true,
      wrap: true,
      minWidth: "100px",
    },
    {
      name: "Payable Salary",
      selector: (row) =>
        row.payableSalary !== null
          ? row.payableSalary.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "-",
      sortable: true,
      wrap: true,
      minWidth: "180px",
    },
    {
      name: "Updated",
      selector: (row) => new Date(row.createdAt).toLocaleString(),
      sortable: true,
      wrap: true,
      minWidth: "180px",
    },
    {
      name: "Actions",
      cell: (row) => (
        <td>
          <Button size="sm" onClick={() => handleUpdate(row.id)}>
            Edit
          </Button>
          <Button
            variant="danger"
            size="sm"
            onClick={() => handelDelete(row.id, row.employee)}
          >
            Delete
          </Button>
        </td>
      ),
      wrap: true,
      minWidth: "150px",
    },
  ];

  const filteredData = searchText
    ? data.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchText.toLowerCase()),
        ),
      )
    : data;

  return (
    <React.Fragment>
      <Row>
        <Col sm={12}>
          {success && (
            <Alert
              variant="success"
              onClose={() => setSuccess(null)}
              dismissible
            >
              {success}
            </Alert>
          )}
          {error && (
            <Alert variant="danger" onClose={() => setError(null)} dismissible>
              {error}
            </Alert>
          )}
          <ToastContainer />
        </Col>
      </Row>
      <Row className="btn-page">
        <Col>
          <Tabs defaultActiveKey="home">
            <Tab eventKey="home" title="PAYROLL" className="mt-4">
              {createPermission && (
                <Button onClick={showPopup} className="m-b-10">
                  <span className="feather icon-plus-circle"></span> Add Salary
                  and Deductions
                </Button>
              )}
              {isPopupVisible && <SalaryCalculator onCancel={hidePopup} />}
              <Row className="btn-page">
                <Col>
                  {data && data.length > 0 && (
                    <Row>
                      <Form.Group
                        as={Col}
                        md={6}
                        sm={12}
                        lg={6}
                        controlId="formBasicName"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Search..."
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md={6}
                        sm={12}
                        lg={6}
                        controlId="formBasicName"
                      >
                        {/* <div className="d-flex">
                                            <Button onClick={exportToCSV}>
                                                <span className="feather icon-file"></span> Export CSV
                                            </Button>
                                            <a ref={csvLinkRef} style={{ display: 'none' }} />
                                            <a href="#" className="btn btn-primary" onClick={exportToPDF}>
                                                <span className="feather icon-file-text"></span> Export PDF
                                            </a>
                                        </div> */}
                      </Form.Group>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        highlightOnHover
                        striped
                        // noHeader
                        dense
                        bordered
                        paginationPerPage={20}
                        paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                        paginationComponentOptions={{
                          rowsPerPageText: "Rows per page:",
                        }}
                        paginationResetDefaultPage={true}
                        paginationTotalRows={data.length}
                        // expandableRows
                        // expandableRowsComponent={ExpandableRow}
                        selectableRows
                        onSelectedRowsChange={(selectedRows) =>
                          console.log(selectedRows)
                        }
                        customStyles={{
                          headRow: {
                            style: {
                              backgroundColor: "#279e6c", // Green background
                              color: "#fff", // White text
                              textTransform: "uppercase", // Uppercase text
                              // fontSize: '10px', // Adjust font size for header row
                              minHeight: "1.5rem",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                            },
                          },
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Tab>

            <Tab eventKey="leaves" title="LEAVE MANAGEMENT" className="mt-4">
              {/* <Card title="Leave" isOption> */}
              <LeaveManagement />
              {/* </Card> */}
            </Tab>
            <Tab eventKey="salary" title="SALARY PAYMENT" className="mt-4">
              {/* <Card title="Salary" isOption> */}
              <SalaryPay />
              {/* </Card> */}
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BasicBadges;
