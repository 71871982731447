import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  Alert,
  ModalFooter,
} from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import config from "../../../config";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PaymentForm = ({ onSave, onCancel }) => {
  const account = useSelector((state) => state.account);
  const [paymentData, setPaymentData] = useState({
    invoiceNumber: "",
    paymentType: "",
    paymentMonth: "",
    payMethod: "",
    advancePaymentMonth: "",
    employee: "",
    totalPayableSalary: 0,
    otherDeduction: 0,
    // daysWorked: 0,
    bankAccount: "",
    notes: "",
    recordedBy: "",
    status: "Pending",
    farm: "",
  });
  const [employees, setEmployees] = useState([]);
  const [employeesFarm, setEmployeesFarm] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedAcc, setSelectedAcc] = useState([]);
  const [selectedPay, setSelectedPay] = useState([]);
  const [employeesSalary, setEmployeesSalary] = useState([]);
  const [advance, setAdvanceMonths] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [type, setType] = useState([]);
  const [leaveData, setLeaveData] = useState([]);
  const [error, setError] = useState(null);
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;

  useEffect(() => {
    axios
      .get(`${apiUrl}/employees_salary`)
      .then((response) => {
        const employeeData = response.data;
        setEmployeesSalary(employeeData);
        const employeeOptions = employeeData.map((employee) => ({
          value: employee.employee,
          label: employee.employee,
        }));
        setEmployees(employeeOptions);
      })
      .catch((error) => {
        console.error("Error fetching employee salary data:", error);
      });

    axios
      .get(`${apiUrl}/accounts`)
      .then((response) => {
        const accountsData = response.data;
        const filteredAccounts = accountsData.filter(
          (account) => account.accountName !== "Income",
        );

        const accountOptions = filteredAccounts.map((account) => ({
          value: account.description,
          label: `${account.description} - ${account.accountNumber}`,
        }));
        setAccounts(accountOptions);
      })
      .catch((error) => {
        console.error("Error fetching accounts data:", error);
      });
  }, []);

  useEffect(() => {
    if (paymentData.employee && paymentData.paymentMonth) {
      axios
        .get(`${apiUrl}/leave_request`, {
          params: {
            employee: paymentData.employee,
            month: paymentData.paymentMonth,
          },
        })
        .then((response) => {
          const leaveRequestData = response.data;

          // Filter leave requests for the selected employee and month
          const relevantLeaveRequests = leaveRequestData.filter(
            (leave) => leave.employee === paymentData.employee,
          );

          // Calculate the total number of days worked
          const daysWorked = relevantLeaveRequests.reduce(
            (totalDays, leave) => totalDays + leave.numberOfDays,
            0,
          );

          setLeaveData(relevantLeaveRequests);
          setPaymentData({
            ...paymentData,
            recordedBy: `${account.user.firstname} ${account.user.lastname}`,
            paymentType: type,
          });
        })
        .catch((error) => {
          console.error("Error fetching leave request data:", error);
        });
    }
  }, [paymentData.employee, paymentData.paymentMonth, type]);

  useEffect(() => {
    const randomInvoiceNumber = Math.floor(
      1000000000 + Math.random() * 9000000000,
    );
    setPaymentData({
      ...paymentData,
      invoiceNumber: `SF${randomInvoiceNumber}`,
    });
  }, []);

  // useEffect(() => {
  //   setPaymentData({ ...paymentData, recordedBy: `${account.user.firstname} ${account.user.lastname}` });

  // }, [paymentData.paymentType]);

  const handlePaymentTypeChange = (selectedOption) => {
    const paymentType = selectedOption.value;
    setType(paymentType);
    setPaymentData({ ...paymentData, paymentType: paymentType });

    const currentMonth = new Date().getMonth(); // Get the current month as a number (0 - 11)
    const currentMonths = new Date().toLocaleDateString("default", {
      month: "long",
    });
    const currentYear = new Date().getFullYear();

    if (paymentType === "Monthly Salary") {
      setPaymentData({
        ...paymentData,
        paymentMonth: `${currentMonths} ${currentYear}`,
        advancePaymentMonth: "",
      });
    } else if (paymentType === "Advance Salary") {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const nextMonthIndex = currentMonth === 11 ? 0 : currentMonth + 0;
      const advanceMonths = months.slice(nextMonthIndex, 12).map((month) => ({
        value: `${month} ${currentYear}`,
        label: `${month} ${currentYear}`,
      }));

      setAdvanceMonths(advanceMonths);

      setPaymentData({
        ...paymentData,
        advancePaymentMonth: advance,
        paymentMonth: `${currentMonths} ${currentYear}`,
      });
    }

    console.log(paymentType);
    // console.log(type)
  };

  const handleEmployeeChange = (selectedOption) => {
    const employeeId = selectedOption.value;
    setSelectedEmployees(employeeId);

    const selectedEmployeeData = employeesSalary.find(
      (employee) => employee.employee === employeeId,
    );

    if (selectedEmployeeData) {
      const totalDeductions =
        selectedEmployeeData.nhifDeduction +
        selectedEmployeeData.nssfDeduction +
        selectedEmployeeData.otherDeduction;

      setPaymentData({
        ...paymentData,
        employee: employeeId,
        salary: selectedEmployeeData.salaryAmount,
        totalPayableSalary: selectedEmployeeData.payableSalary,
        nhifDeduction: selectedEmployeeData.nhifDeduction,
        nssfDeduction: selectedEmployeeData.nssfDeduction,
        otherDeduction: selectedEmployeeData.otherDeduction,
        totalDeductions: totalDeductions,
        // farm: employeesFarm,
      });
    } else {
      console.error("Selected employee not found in the data.");
    }
  };

  const handleAccount = (selectedOption) => {
    const acc = selectedOption.value;
    setSelectedAcc(acc);
    setPaymentData({ ...paymentData, bankAccount: acc });
  };

  const handleMethod = (selectedOption) => {
    const pay = selectedOption.value;
    setSelectedPay(pay);
    setPaymentData({ ...paymentData, payMethod: pay });
  };

  const methods = [
    { value: "Cash", label: "Cash" },
    { value: "Bank", label: "Bank" },
    { value: "Cheque", label: "Cheque" },
    { value: "Mpesa", label: "Mpesa" },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const payUpdate = { ...paymentData };

    axios
      .post(`${apiUrl}/salary_payment`, payUpdate)
      .then((response) => {
        if (response.data.message) {
          toast.success(response.data.message);
          onCancel();
        }
        console.log(response);
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // Handle a 400 Bad Request error
          console.error("Error submitting salary payment:", error);
          toast.error(error.response.data.message); // Display the error message
          setError(error.response.data.message);
        } else {
          // Handle other errors
          console.error("An error occurred:", error);
        }
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPaymentData({ ...paymentData, [name]: value });
  };

  return (
    <Modal show={true} onHide={onCancel} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Record Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12}>
            {/* {success && <Alert variant="success" onClose={() => setSuccess(null)} dismissible >{success}</Alert>} */}
            {error && (
              <Alert
                variant="danger"
                onClose={() => setError(null)}
                dismissible
              >
                {error}
              </Alert>
            )}
            <ToastContainer />
          </Col>
        </Row>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Form.Group as={Col} md={6}>
              <Form.Label>Receipt Number</Form.Label>
              <Form.Control
                type="text"
                name="invoiceNumber"
                value={paymentData.invoiceNumber}
                readOnly
                required
              />
            </Form.Group>

            <Form.Group as={Col} md={6}>
              <Form.Label>Payment Type</Form.Label>
              <Select
                required
                name="paymentType"
                value={type.value}
                onChange={handlePaymentTypeChange}
                options={[
                  { value: "Monthly Salary", label: "Monthly Salary" },
                  { value: "Advance Salary", label: "Advance Salary" },
                ]}
              />
            </Form.Group>

            {type === "Advance Salary" && (
              <Form.Group as={Col} md={6}>
                <Form.Label>Advance Payment Month</Form.Label>
                <Select
                  required
                  name="advancePaymentMonth"
                  value={paymentData.advancePaymentMonth.value}
                  onChange={(selectedOption) =>
                    setPaymentData({
                      ...paymentData,
                      advancePaymentMonth: selectedOption.value,
                    })
                  }
                  options={advance} // Use the advanceMonths array here
                />
              </Form.Group>
            )}

            <Form.Group as={Col} md={6}>
              <Form.Label>Payment Month</Form.Label>
              <Form.Control
                type="text"
                name="paymentMonth"
                value={paymentData.paymentMonth}
                readOnly
                required
              />
            </Form.Group>

            <Form.Group as={Col} md={6}>
              <Form.Label>Employee</Form.Label>
              <Select
                required
                name="employee"
                value={selectedEmployees.value}
                onChange={handleEmployeeChange}
                options={employees}
              />
            </Form.Group>

            <Form.Group as={Col} md={6}>
              <Form.Label>Account</Form.Label>
              <Select
                name="bankAccount"
                value={selectedAcc.value}
                onChange={handleAccount}
                options={accounts}
                required
              />
            </Form.Group>

            {type === "Advance Salary" ? (
              <Form.Group as={Col} md={6}>
                <Form.Label>Advance Salary Amount</Form.Label>
                <Form.Control
                  type="text"
                  name="totalPayableSalary"
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            ) : (
              <>
                <Form.Group as={Col} md={6}>
                  <Form.Label>Salary</Form.Label>
                  <Form.Control
                    type="text"
                    name="salary"
                    value={paymentData.salary}
                    readOnly
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} md={6}>
                  <Form.Label>Total Deductions</Form.Label>
                  <Form.Control
                    type="text"
                    name="otherDeduction"
                    value={paymentData.totalDeductions}
                    readOnly
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} md={6}>
                  <Form.Label>Total Payable Salary</Form.Label>
                  <Form.Control
                    type="text"
                    name="totalPayableSalary"
                    value={paymentData.totalPayableSalary}
                    readOnly
                    required
                  />
                </Form.Group>
              </>
            )}

            <Form.Group as={Col} md={6}>
              <Form.Label>Payment Method</Form.Label>
              <Select
                name="payMethod"
                value={selectedPay.value}
                onChange={handleMethod}
                options={methods}
                required
              />
            </Form.Group>

            {/* <Form.Group as={Col} md={6}>
              <Form.Label>Days Worked</Form.Label>
              <Form.Control
                type="text"
                name="daysWorked"
                value={paymentData.daysWorked}
                readOnly
                required
              />
            </Form.Group> */}

            <Form.Group as={Col} md={12}>
              <Form.Label>Notes</Form.Label>
              <Form.Control
                as="textarea"
                name="notes"
                value={paymentData.notes}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
          </Row>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              Record Payment
            </Button>
            <Button variant="secondary" onClick={onCancel}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default PaymentForm;
