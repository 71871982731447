import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Button, Alert, Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { useHistory, useParams, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import config from "../../config";
import Card from "../../components/Card/MainCard";
import axios from "axios";
import ConfirmationModal from "../ui-elements/basic/ConfirmationModal";
import { usePermissions } from "../../permissions";
import { create } from "yup/lib/Lazy";

const SamplePage = ({ onCancel }) => {
  const account = useSelector((state) => state.account);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const formRef = useRef(null);
  const [supplimentOptions, setsupplimentOptions] = useState([]);
  const [selectedsuppliment, setSelectedsuppliment] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [supplimentCost, setsupplimentCost] = useState(0);
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [accounts, setAccounts] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { createPermission, deletePermission, editPermission } =
    usePermissions();

  const memoizedApiUrl1 = useMemo(() => {
    return `${apiUrl}/farm`;
  }, [apiUrl]);

  const memoizedApiUrl2 = useMemo(() => {
    return `${apiUrl}/accounts`;
  }, [apiUrl]);

  const memoizedApiUrl3 = useMemo(() => {
    return `${apiUrl}/suppliments`;
  }, [apiUrl]);

  const memoizedApiUrl4 = useMemo(() => {
    return `${apiUrl}/suppliers`;
  }, [apiUrl]);

  const generateTransactionId = () => {
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 1000);
    return `SF-${random}`;
  };

  const [farmOptions, setFarmOptions] = useState([]);
  const [farm, setSelectedFarm] = useState(null);

  useEffect(() => {
    const fetchFarms = async () => {
      try {
        const response = await axios.get(memoizedApiUrl1);
        const farmData = response.data;
        const options = farmData.map((farm) => ({
          value: farm.name,
          label: farm.name,
        }));

        setFarmOptions(options);
      } catch (error) {
        console.error("Error fetching Farms:", error);
      }
    };

    fetchFarms();
  }, [memoizedApiUrl1]);

  const handleInpute = (fieldName, value) => {
    if (fieldName === "farm") {
      setSelectedFarm(value);
      setData((prevData) => ({ ...prevData, [fieldName]: value }));
    } else {
      setData((prevData) => ({ ...prevData, [fieldName]: value }));
    }
    console.log(data);
  };

  useEffect(() => {
    fetchAccounts();
  }, [memoizedApiUrl2]);

  const fetchAccounts = async () => {
    try {
      const response = await axios.get(memoizedApiUrl2);
      const accounts = response.data;

      // const filteredData = accounts.filter((tag) => tag.farm === farm);

      // const filteredAccounts = accounts.filter(account => account.accountName !== 'Income');

      setAccounts(accounts);
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  };

  const handleSelectChange = (name, selectedOption) => {
    setData({
      ...data,
      [name]: selectedOption.value,
      transactionId: generateTransactionId(),
    });
  };

  useEffect(() => {
    const calculateTotalCost = () => {
      const supplimentCost = data.supplimentQuantity * data.supplimentPer;
      setsupplimentCost(supplimentCost);
      setData((prevData) => ({ ...prevData, supplimentCost }));
    };

    calculateTotalCost();
  }, [data.supplimentQuantity, data.supplimentPer]);

  const handleInput = (fieldName, value) => {
    setData((prevData) => ({
      ...prevData,
      [fieldName]: value,
      sup_name: selectedSupplier,
      userName: account.user.firstname,
      userId: account.user.id,
    }));
    console.log(data);
  };

  // console.log(data);
  useEffect(() => {
    const fetchsuppliments = async () => {
      try {
        const response = await axios.get(memoizedApiUrl4);
        const data = response.data;
        // const filteredData = data.filter((tag) => tag.farm === farm);
        const options = data.map((sup) => ({
          value: sup.name,
          label: sup.name,
        }));
        console.log(options);
        setSupplierOptions(options);
      } catch (error) {
        console.error("Error fetching suppliments:", error);
      }
    };

    fetchsuppliments();
  }, [memoizedApiUrl4]);

  const formData = new FormData();
  formData.append("image", selectedImage);
  formData.append("sup_name", selectedSupplier);
  formData.append("supplimentName", data.supplimentName);
  formData.append("supplimentQuantity", data.supplimentQuantity);
  formData.append("supplimentPer", data.supplimentPer);
  formData.append("supplimentCost", data.supplimentCost);
  formData.append("bankAccount", data.bankAccount);
  formData.append("transactionId", data.transactionId);
  formData.append("recorded", data.recorded);
  formData.append("farm", null);
  formData.append("userName", account.user.firstname);
  formData.append("userId", account.user.id);

  const handleMilk = async (e) => {
    e.preventDefault();
    setShowConfirmationModal(true);
  };

  const handleConfirmation = async (e) => {
    if (!createPermission) {
      return;
    }
    try {
      const response = await axios.post(
        `${apiUrl}/suppliment/restock`,
        formData,
      );
      if (response.data) {
        setSuccess(`${data.supplimentName} restocked successfully`);
        setTimeout(() => {
          setSuccess(null);
        }, 10000);
        toast.success(`${data.supplimentName} restocked successfully`);
        formRef.current.reset();
        setSelectedsuppliment(null);
        setsupplimentCost(null);
        setShowConfirmationModal(false);
        onCancel();
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError(`${error.response.data.message}`);
        toast.error(`${error.response.data.message}`);
        setShowConfirmationModal(false);
      } else {
        setError("An error occurred. Please try again later.");
        setShowConfirmationModal(false);
      }
    }
  };

  useEffect(() => {
    const fetchsuppliments = async () => {
      try {
        const response = await axios.get(memoizedApiUrl3);
        const data = response.data;
        // const filteredData = data.filter((tag) => tag.farm === farm);
        const options = data.map((suppliment) => ({
          value: suppliment.suppliment,
          label: suppliment.suppliment,
        }));
        console.log(options);
        setsupplimentOptions(options);
      } catch (error) {
        console.error("Error fetching suppliments:", error);
      }
    };

    fetchsuppliments();
  }, [memoizedApiUrl3]);

  const handleImageChange = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const handleCancelConfirmation = () => {
    // Handle cancel action if needed
    setShowConfirmationModal(false);
  };

  return (
    <React.Fragment>
      <Modal show={true} onHide={onCancel} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Restock Suppliment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              {success && (
                <Alert
                  variant="success"
                  onClose={() => setSuccess(null)}
                  dismissible
                >
                  {success}
                </Alert>
              )}
              {error && (
                <Alert
                  variant="danger"
                  onClose={() => setError(null)}
                  dismissible
                >
                  {error}
                </Alert>
              )}
              <ToastContainer />
            </Col>
          </Row>
          <Form onSubmit={handleMilk} ref={formRef}>
            <Row>
              <Col>
                <Row>
                  {/* <Form.Group as={Col} md={6} sm={12} lg={6} >
                                <Form.Label>Farm</Form.Label>
                                <Select
                                options={farmOptions}
                                onChange={(selectedOption) => handleInpute('farm', selectedOption.value)}
                                isSearchable
                                name="farm"
                                />
                            </Form.Group> */}
                  <Form.Group
                    as={Col}
                    md={6}
                    sm={12}
                    lg={6}
                    controlId="formBasic"
                  >
                    <Form.Label>Supplier</Form.Label>
                    <Select
                      onChange={(selectedOption) =>
                        setSelectedSupplier(selectedOption.value)
                      }
                      options={supplierOptions}
                      isSearchable
                      name="sup_name"
                      placeholder="-- Select Supplier --"
                      required
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={6}
                    sm={12}
                    lg={6}
                    controlId="formBasic"
                  >
                    <Form.Label>Suppliment Name</Form.Label>
                    <Select
                      value={selectedsuppliment}
                      onChange={(selectedOption) =>
                        setSelectedsuppliment(
                          handleInput("supplimentName", selectedOption.value),
                        )
                      }
                      options={supplimentOptions}
                      isSearchable
                      name="supplimentName"
                      placeholder="--Select suppliment--"
                      required
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={6}
                    sm={12}
                    lg={6}
                    controlId="formBasic"
                  >
                    <Form.Label>Amount Kgs/Litres</Form.Label>
                    <Form.Control
                      size="sm"
                      required
                      onChange={(e) =>
                        handleInput("supplimentQuantity", e.target.value)
                      }
                      type="text"
                      name="supplimentQuantity"
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={6}
                    sm={12}
                    lg={6}
                    controlId="formBasic"
                  >
                    <Form.Label>Cost per Kgs/Litres</Form.Label>
                    <Form.Control
                      size="sm"
                      required
                      onChange={(e) =>
                        handleInput("supplimentPer", e.target.value)
                      }
                      type="text"
                      name="supplimentPer"
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={6}
                    sm={12}
                    lg={6}
                    controlId="formBasic"
                  >
                    <Form.Label>Total Cost</Form.Label>
                    <Form.Control
                      size="sm"
                      required
                      onChange={(e) =>
                        handleInput("supplimentCost", e.target.value)
                      }
                      value={supplimentCost}
                      disabled
                      type="number"
                      name="supplimentCost"
                    />
                  </Form.Group>

                  <Col md={6} sm={12} lg={6}>
                    <Form.Group>
                      <Form.Label>Expense Account:</Form.Label>
                      <Select
                        onChange={(selectedOption) =>
                          handleSelectChange("bankAccount", selectedOption)
                        }
                        options={accounts.map((account) => ({
                          value: account.description,
                          label: (account.accountNumber, account.description),
                        }))}
                        placeholder="Select account"
                        required
                      />
                    </Form.Group>
                  </Col>

                  <Form.Group as={Col} md={6} sm={12} lg={6}>
                    <Form.Label>Supporting Document</Form.Label>
                    <Form.Control
                      type="file"
                      onChange={handleImageChange}
                      name="image"
                      accept="image/*"
                      required
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={6}
                    sm={12}
                    lg={6}
                    controlId="formBasic"
                  >
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                      size="sm"
                      required
                      onChange={(e) => handleInput("recorded", e.target.value)}
                      type="date"
                      name="recorded"
                    />
                  </Form.Group>
                </Row>
                <Modal.Footer>
                  {createPermission && <Button type="submit">Submit</Button>}
                  <Button variant="secondary" onClick={onCancel}>
                    Cancel
                  </Button>
                </Modal.Footer>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <ConfirmationModal
        show={showConfirmationModal}
        onHide={handleCancelConfirmation}
        onConfirm={handleConfirmation}
      />
    </React.Fragment>
  );
};

export default SamplePage;
