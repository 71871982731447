import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useHistory, useParams, Link } from "react-router-dom";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "../../../components/Card/MainCard";
import axios from "axios";
import ConfirmationModal from "./ConfirmationModal";
import { usePermissions } from "../../../permissions";
import config from "../../../config";
import DataTable from "react-data-table-component";

const SamplePage = () => {
  const account = useSelector((state) => state.account);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const { createPermission, deletePermission, editPermission } =
    usePermissions();
  const [searchText, setSearchText] = useState("");
  const [activityLog, setActivityLog] = useState([]);

  const memoizedApiUrl1 = useMemo(() => {
    return `${apiUrl}/weaners`;
  }, [apiUrl]);

  useEffect(() => {
    axios
      .get(memoizedApiUrl1)
      .then((response) => {
        // const filteredData = response.data.filter(item => !item.archive);
        const filteredData = response.data;
        setActivityLog(filteredData.reverse());
      })
      .catch((error) => {
        setError(`Error fetching activity log: ${error.message}`);
      });
  }, [apiUrl, activityLog]); // Only re-run the effect if apiUrl changes

  const handleDelete = async (userId, weanersGroup) => {
    try {
      const confirmed = window.confirm(
        `Are you sure you want to archive ${weanersGroup}?`,
      );
      if (confirmed) {
        const response = await axios.put(`${apiUrl}/weaners/${userId}/archive`);
        toast.success(response.data.message);
        // window.location.reload();
      }
    } catch (error) {
      // alert(error.message);
      toast.error(error.response.data.message);
    }
  };

  const columns = [
    {
      name: "Timestamp",
      selector: (row) => new Date(row.createdAt).toLocaleString(),
      sortable: true,
      wrap: true,
      cell: (row) => (
        <Link to={`/weaners/view/${row.weanersGroup}`}>
          {new Date(row.createdAt).toLocaleString()}
        </Link>
      ),
    },
    {
      name: "Name",
      selector: (row) => row.weanersGroup,
      sortable: true,
      wrap: true,
      cell: (row) => (
        <Link to={`/weaners/view/${row.weanersGroup}`}>{row.weanersGroup}</Link>
      ),
      // sortFunction: (a, b) => a.weanersGroup.localeCompare(b.weanersGroup),
    },
    {
      name: "Capacity",
      selector: (row) => row.capacity || "-",
      sortable: true,
      wrap: true,
      cell: (row) => (
        <Link to={`/weaners/view/${row.weanersGroup}`}>
          {row.capacity || "-"}
        </Link>
      ),
    },

    {
      name: "Updated",
      selector: (row) => new Date(row.updatedAt).toLocaleDateString(),
      sortable: true,
      wrap: true,
      cell: (row) => (
        <Link to={`/weaners/view/${row.weanersGroup}`}>
          {new Date(row.updatedAt).toLocaleDateString()}
        </Link>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          {deletePermission &&
            (row.archive === 0 ? (
              <Button
                variant="danger"
                disabled={!deletePermission}
                size="sm"
                onClick={() => handleDelete(row.id, row.weanersGroup)}
              >
                <span className="feather icon-trash-2"></span> Archive
              </Button>
            ) : (
              <Button
                variant="secondary"
                disabled={!deletePermission}
                size="sm"
              >
                <span className="feather icon-trash-2"></span> Archived
              </Button>
            ))}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      // button: true,
      // right: true
    },
  ];

  const filteredData = searchText
    ? activityLog.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchText.toLowerCase()),
        ),
      )
    : activityLog;

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Row>
            <Form.Group
              as={Col}
              md={1}
              sm={12}
              lg={1}
              controlId="formBasicName"
            >
              <Form.Label>Search</Form.Label>
            </Form.Group>
            <Form.Group
              as={Col}
              md={3}
              sm={12}
              lg={3}
              controlId="formBasicName"
            >
              <Form.Control
                type="text"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Form.Group>
          </Row>
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            highlightOnHover
            striped
            // noHeader
            dense
            bordered
            paginationPerPage={20}
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
            paginationComponentOptions={{
              rowsPerPageText: "Rows per page:",
            }}
            paginationResetDefaultPage={true}
            paginationTotalRows={activityLog.length}
            // expandableRows
            selectableRows
            onSelectedRowsChange={(selectedRows) => console.log(selectedRows)}
            customStyles={{
              headRow: {
                style: {
                  backgroundColor: "#279e6c", // Green background
                  color: "#fff", // White text
                  textTransform: "uppercase", // Uppercase text
                  // fontSize: '10px', // Adjust font size for header row
                  minHeight: "1.5rem",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                },
              },
            }}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SamplePage;
