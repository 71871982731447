import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import config from "../../../config";
import Card from "../../../components/Card/MainCard";
import Select from "react-select";
import axios from "axios";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  Row,
  Col,
  Form,
  Button,
  Table,
  Alert,
  Tabs,
  Tab,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import PerfectScrollbar from "react-perfect-scrollbar";
import DataTable from "react-data-table-component";
import socketIOClient from "socket.io-client";
import LoginLogs from "./BasicTabsPills3";

const SamplePage = () => {
  const account = useSelector((state) => state.account);
  const [activeUsers, setActiveUsers] = useState([]);
  const [activityLog, setActivityLog] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [searchText, setSearchText] = useState("");
  const [onlineUsers, setOnlineUsers] = useState([]);
  const userName = account.user.firstname;
  const user_Id = account.user.id;
  const ENDPOINT = `${apiUrl}`;
  // const socket = socketIOClient(ENDPOINT);

  useEffect(() => {
    axios
      .get(`${apiUrl}/activity-log`)
      .then((response) => {
        setActivityLog(response.data);
      })
      .catch((error) => {
        setError("Error fetching activity log:", error);
      });
  }, [apiUrl]);

  const columns = [
    {
      name: "Timestamp",
      selector: (row) => new Date(row.timestamp).toLocaleString(),
      sortable: true,
      wrap: true,
    },
    {
      name: "By",
      selector: (row) => row.user_name,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      wrap: true,
    },
    {
      name: "Description",
      selector: (row) => row.action,
      sortable: true,
      width: "700px",
      wrap: true,
    },

    // {
    //     name: 'Action',
    //     cell: (row) => (
    //         <div>
    //             <Button size="sm" onClick={() => handleUpdate(row.id)}>
    //                 <span className="feather icon-edit"></span> Edit
    //             </Button>{' '}
    //             <Button size="sm" variant="info" onClick={() => handleUpdatePass(row.id)}>
    //                 <span className="feather icon-eye"></span> View
    //             </Button>
    //             <Button variant="danger" size="sm" onClick={() => handleDelete(row.id, row.firstname, row.lastname)}>
    //                 <span className="feather icon-trash-2"></span> Delete
    //             </Button>
    //         </div>
    //     ),
    //     ignoreRowClick: true,
    //     allowOverflow: true
    //     // button: true,
    //     // right: true
    // }
  ];

  const filteredData = searchText
    ? activityLog.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchText.toLowerCase()),
        ),
      )
    : activityLog;

  return (
    <React.Fragment>
      {/* <hr /> */}
      <Tabs defaultActiveKey="timeline">
        <Tab eventKey="timeline" title="ACTIVITY TIMELINE" className="mt-5">
          <Row className="btn-page">
            <Col md="12">
              {/* <Card title="Last 100 Logs" isOption> */}
              <PerfectScrollbar>
                <div className="timeline-container">
                  <VerticalTimeline layout="1-column">
                    {activityLog &&
                      activityLog.length > 0 &&
                      activityLog.slice(0, 100).map((log, index) => (
                        <VerticalTimelineElement
                          key={index}
                          className="vertical-timeline-item"
                          icon={
                            <i className="badge badge-dot badge-dot-xl"> </i>
                          }
                          date={new Date(log.timestamp).toLocaleDateString()}
                        >
                          <h6 className="timeline-title">{log.title}</h6>
                          <p className="pp">{log.action}</p>
                          <p className="pp">
                            By: {log.user_name}, At (
                            {new Date(log.timestamp).toLocaleString()})
                          </p>
                        </VerticalTimelineElement>
                      ))}
                  </VerticalTimeline>
                </div>
              </PerfectScrollbar>
              {/* </Card> */}
            </Col>
          </Row>
        </Tab>

        <Tab eventKey="table" title="ACTIVITY TABLE" className="mt-4">
          <Row>
            <Col>
              {/* <Card title="All Logs" isOption> */}
              <Row>
                <Form.Group
                  as={Col}
                  md={1}
                  sm={12}
                  lg={1}
                  controlId="formBasicName"
                >
                  <Form.Label>Search</Form.Label>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md={3}
                  sm={12}
                  lg={3}
                  controlId="formBasicName"
                >
                  <Form.Control
                    type="text"
                    placeholder="Search..."
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </Form.Group>
              </Row>

              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                highlightOnHover
                striped
                // noHeader
                dense
                bordered
                paginationPerPage={20}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                paginationComponentOptions={{
                  rowsPerPageText: "Rows per page:",
                }}
                paginationResetDefaultPage={true}
                paginationTotalRows={activityLog.length}
                // expandableRows
                selectableRows
                onSelectedRowsChange={(selectedRows) =>
                  console.log(selectedRows)
                }
                customStyles={{
                  headRow: {
                    style: {
                      backgroundColor: "#279e6c", // Green background
                      color: "#fff", // White text
                      textTransform: "uppercase", // Uppercase text
                      minHeight: "1.5rem",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                    },
                  },
                }}
              />
              {/* </Card> */}
            </Col>
          </Row>
        </Tab>

        <Tab eventKey="online" title="LOGIN LOGS" className="mt-4">
          <Row className="btn-page">
            <Col md="12">
              <LoginLogs />
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </React.Fragment>
  );
};

export default SamplePage;
