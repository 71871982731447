import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import {
  Row,
  Col,
  Form,
  Button,
  Table,
  Alert,
  Pagination,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {
  PDFDownloadLink,
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import { CSVLink } from "react-csv";
import Card from "../../components/Card/MainCard";
import config from "../../config";
import MontserratRegular from "./../../fonts/static/Montserrat-Regular.ttf";
import MontserratBold from "./../../fonts/static/Montserrat-Bold.ttf";
import DataTable from "react-data-table-component";

// Font.register({
//     family: 'Montserrat',
//     fonts: [
//         {
//             src: MontserratRegular,
//             fontWeight: 'normal'
//         },
//         {
//             src: MontserratBold,
//             fontWeight: 'bold'
//         }
//     ]
// });
Font.register({
  family: "Nunito",
  fonts: [
    { src: "https://fonts.gstatic.com/s/nunito/v12/XRXV3I6Li01BKofINeaE.ttf" },
    {
      src: "https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofA6sKUYevN.ttf",
      fontWeight: 700,
    },
  ],
});

const TransactionHistory = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [transactionData, setTransactionData] = useState([]);
  const [lastAvailableBalance, setLastAvailableBalance] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const [selectedType, setSelectedType] = useState("");
  const [selectedFarm, setSelectedFarm] = useState(null);
  const { id, loanId } = useParams();
  const [selectedAccount, setSelectedAccount] = useState([]);
  const [allAccounts, setAllAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [account, setAccount] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [farm, setFarm] = useState("");
  const [toDate, setToDate] = useState("");
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [pdfBlob, setPdfBlob] = useState(null);
  const [farmOptions, setFarmOptions] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const fetchFarms = async () => {
      try {
        const response = await axios.get(`${apiUrl}/farm`);
        const farmData = response.data;
        const options = farmData.map((farm) => ({
          value: farm.name,
          label: farm.name,
        }));

        setFarmOptions(options);
      } catch (error) {
        console.error("Error fetching Farms:", error);
      }
    };

    fetchFarms();
  }, [farmOptions, apiUrl]);

  useEffect(() => {
    // Simulate fetching all accounts (you might fetch it from an API in reality)
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/accounts`);
        const data = await response.json();
        setAllAccounts(data);
        setFilteredAccounts(data); // Initialize filteredAccounts with all accounts
      } catch (error) {
        console.error("Error fetching accounts: ", error);
      }
    };

    fetchData();
  }, [apiUrl, allAccounts]);

  // Update the filteredAccounts when selectedFarm changes
  useEffect(() => {
    if (selectedFarm) {
      const filtered = allAccounts;
      // const filtered = allAccounts.filter(account => account.farm === selectedFarm);
      setFiltered(filtered);
    } else {
      // If no farm is selected, show all accounts
      setFilteredAccounts(allAccounts);
    }
  }, [selectedFarm, allAccounts]);

  const handleInpute = (fieldName, value) => {
    if (fieldName === "farm") {
      setSelectedFarm(value);
    }
  };

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  const handleFetchStatement = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${apiUrl}/request_statements`, {
        params: {
          bankAccount: selectedType,
          fromDate,
          toDate,
        },
      });

      if (response.data.length > 0) {
        const lastStatement = response.data[response.data.length - 1];
        setLastAvailableBalance(lastStatement.available_balance);
        console.log(lastAvailableBalance);
      } else {
        setLastAvailableBalance(0);
      }

      const data = response.data[0];

      setTransactionData(response.data);
      setAccount(data.bankAccount);
    } catch (error) {
      console.error(error);
    }
  };

  const generatePdf = () => {
    const blob = new Blob([<PDFContent id={id} />], {
      type: "application/pdf",
    });
    setPdfBlob(blob);
  };

  const exportToCSV = () => {
    const headers = [
      { label: "Date", key: "createdAt" },
      { label: "Reference", key: "transactionId" },
      { label: "Acc/ Name", key: "bankAccount" },
      { label: "Description", key: "details" },
      { label: "Debit", key: "debit" },
      { label: "Credit", key: "credit" },
      // { label: 'Balance', key: 'balance' },
      { label: "Balance", key: "available_balance" },
    ];

    const csvData = [
      headers,
      ...filteredData.map((transaction) => [
        new Date(transaction.createdAt).toLocaleDateString(),
        transaction.transactionId,
        transaction.bankAccount,
        transaction.details,
        transaction.debit !== null
          ? transaction.debit.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "",
        transaction.credit !== null
          ? transaction.credit.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "",
        // transaction.balance !== null
        //     ? transaction.balance.toLocaleString(undefined, {
        //           minimumFractionDigits: 2,
        //           maximumFractionDigits: 2
        //       })
        //     : '',
        transaction.available_balance !== null
          ? transaction.available_balance.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "",
      ]),
    ];

    return (
      <CSVLink
        data={csvData}
        headers={headers}
        filename={`transactions_${filteredData.transactionId}.csv`}
        className="btn btn-primary"
      >
        <span className="feather icon-file"> 'Export CSV'</span>
      </CSVLink>
    );
  };

  const dates = new Date();
  const currentDate = new Date(dates.toLocaleDateString());

  const columns = [
    {
      name: "Timestamp",
      selector: (row) => new Date(row.createdAt).toLocaleString(),
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "Reference",
      selector: (row) => row.transactionId,
      sortable: true,
      minWidth: "100px",
      maxWidth: "200px",
    },

    {
      name: "Object",
      selector: (row) => row?.coInput,
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "Description",
      selector: (row) => row?.details,
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "Debit",
      selector: (row) =>
        row?.debit !== null
          ? row.debit.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "-",
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "Credit",
      selector: (row) =>
        row?.credit !== null
          ? row.credit.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "-",
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },

    {
      name: "Balance",
      selector: (row) =>
        row.available_balance !== null
          ? row.available_balance.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "",
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },
  ];

  const filteredData = searchText
    ? transactionData.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchText.toLowerCase()),
        ),
      )
    : transactionData;

  return (
    <React.Fragment>
      <Row>
        <Col sm={12}>
          {success && (
            <Alert
              variant="success"
              onClose={() => setSuccess(null)}
              dismissible
            >
              {success}
            </Alert>
          )}
          {error && (
            <Alert variant="danger" onClose={() => setError(null)} dismissible>
              {error}
            </Alert>
          )}
          <ToastContainer />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card title="Accounts Statement" isOption>
            <Form onSubmit={handleFetchStatement}>
              <Row>
                <Col md={4} sm={12} lg={4}>
                  <Form.Group>
                    <Form.Label>Account:</Form.Label>
                    <Select
                      onChange={(selectedOption) =>
                        setSelectedType(selectedOption.value)
                      }
                      options={allAccounts.map((option) => ({
                        value: option.description,
                        label: option.description,
                      }))}
                      value={{ value: selectedType, label: selectedType }}
                      required
                      isSearchable
                    />
                  </Form.Group>
                </Col>
                <Col md={4} sm={12} lg={4}>
                  <Form.Group>
                    <Form.Label>From:</Form.Label>
                    <Form.Control
                      type="date"
                      onChange={handleFromDateChange}
                      value={fromDate}
                      required
                      name="fromDate"
                    />
                  </Form.Group>
                </Col>
                <Col md={4} sm={12} lg={4}>
                  <Form.Group>
                    <Form.Label>To:</Form.Label>
                    <Form.Control
                      type="date"
                      onChange={handleToDateChange}
                      value={toDate}
                      required
                      name="toDate"
                    />
                  </Form.Group>
                </Col>
                <Col md={12} sm={12} lg={12}>
                  <Form.Group>
                    <Button type="submit">Fetch Statement</Button>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Row className="btn-page">
            <Col>
              {transactionData && transactionData.length > 0 && (
                <Row>
                  <Form.Group
                    as={Col}
                    md={6}
                    sm={12}
                    lg={6}
                    controlId="formBasicName"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Search..."
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={6}
                    sm={12}
                    lg={6}
                    controlId="formBasicName"
                  >
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      {transactionData.length > 0 && (
                        <div>
                          <PDFDownloadLink
                            className="btn btn-primary mr-2"
                            document={
                              <PDFContent
                                id={id}
                                transactionData={transactionData}
                                lastAvailableBalance={lastAvailableBalance}
                                account={account}
                                fromDate={fromDate}
                                toDate={toDate}
                                selectedFarm={selectedFarm}
                              />
                            }
                            fileName={`${selectedType}_${currentDate}.pdf`}
                          >
                            {({ blob, url, loading, error }) =>
                              loading ? (
                                "Loading..."
                              ) : (
                                <span className="feather icon-file-text">
                                  {" "}
                                  'Export PDF'
                                </span>
                              )
                            }
                          </PDFDownloadLink>
                          {exportToCSV()}
                        </div>
                      )}
                    </div>
                  </Form.Group>
                </Row>
              )}
              <Row>
                <Col>
                  <DataTable
                    columns={columns}
                    data={filteredData}
                    pagination
                    highlightOnHover
                    striped
                    // noHeader
                    dense
                    bordered
                    paginationPerPage={20}
                    paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                    paginationComponentOptions={{
                      rowsPerPageText: "Rows per page:",
                    }}
                    paginationResetDefaultPage={true}
                    paginationTotalRows={transactionData.length}
                    // expandableRows
                    selectableRows
                    onSelectedRowsChange={(selectedRows) =>
                      console.log(selectedRows)
                    }
                    customStyles={{
                      headRow: {
                        style: {
                          backgroundColor: "#279e6c", // Green background
                          color: "#fff", // White text
                          textTransform: "uppercase", // Uppercase text
                          // fontSize: '10px', // Adjust font size for header row
                          minHeight: "1.5rem",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                        },
                      },
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const avatarImage = "/assets/farm-logo.jpg";

// Define the PDFContent component
const PDFContent = ({
  transactionData,
  lastAvailableBalance,
  selectedType,
  account,
  fromDate,
  toDate,
  id,
  selectedFarm,
}) => (
  <Document>
    <Page style={styles.page} size="A4" orientation="portrait">
      <View style={styles.section}>
        <View style={styles.row}>
          <View style={styles.header} fixed>
            <View style={styles.rows}>
              <View style={styles.column}>
                <Image source={avatarImage} style={styles.letterheadLogo} />
              </View>

              <View style={styles.column}></View>
              <View style={styles.column}>
                <Text>SFARMS.CO.KE</Text>
                <Text>P.O BOX 800-222</Text>
                <Text>Naivasha</Text>
                <Text>Phone : 254-787-689-760</Text>
                <Text>Email : info@sfarms.co.ke</Text>
              </View>
            </View>
          </View>
        </View>
        <Text style={styles.title}>SFARMS ACCOUNT STATEMENT</Text>
        <Text style={styles.title}>Account Name: {account}</Text>
        {/* <Text style={styles.title}>FARM: {selectedFarm}</Text> */}
        <Text style={styles.title}>
          Period: {fromDate} - {toDate}
        </Text>
        <Text style={styles.subTitle}>
          Available Balance: KES{" "}
          {lastAvailableBalance &&
            lastAvailableBalance.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
        </Text>
        <Table style={styles.table}>
          <View style={styles.tableHead}>
            <View style={styles.tableCellT}>
              <Text>Date</Text>
            </View>
            <View style={styles.tableCellT}>
              <Text>Reference</Text>
            </View>
            <View style={styles.tableCellT}>
              <Text>From/To</Text>
            </View>
            <View style={styles.tableCellT}>
              <Text>Description</Text>
            </View>
            <View style={styles.tableCellT}>
              <Text>Debit</Text>
            </View>
            <View style={styles.tableCellT}>
              <Text>Credit</Text>
            </View>
            <View style={styles.tableCellT}>
              <Text>Balance</Text>
            </View>
            {/* <View style={styles.tableCellT}>
                            <Text>Available Balance</Text>
                        </View> */}
          </View>
          {transactionData.map((transaction, index) => (
            <View
              style={[
                styles.tableRow,
                index % 2 === 0 ? styles.tableRowEven : styles.tableRowOdd,
              ]}
              key={transaction.id}
            >
              <View style={styles.tableCell}>
                <Text>{new Date(transaction.createdAt).toDateString()}</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>{transaction.transactionId}</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>{transaction.coInput}</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>{transaction.details}</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>
                  {transaction.debit !== null
                    ? transaction.debit.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "-"}
                </Text>
              </View>
              <View style={styles.tableCell}>
                <Text>
                  {transaction.credit !== null
                    ? transaction.credit.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "-"}
                </Text>
              </View>
              {/* <View style={styles.tableCell}>
                                <Text>
                                    {transaction.balance !== null
                                        ? transaction.balance.toLocaleString(undefined, {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2
                                          })
                                        : ''}
                                </Text>
                            </View> */}
              <View style={styles.tableCell}>
                <Text>
                  {transaction.available_balance !== null
                    ? transaction.available_balance.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "-"}
                </Text>
              </View>
            </View>
          ))}
        </Table>
      </View>
      <Footer />
    </Page>
  </Document>
);

const Footer = ({ pageNumber, totalPages }) => (
  <View style={styles.footer} fixed>
    <Text style={styles.footerText}>
      {/* Page {pageNumber} of {totalPages} - This is a computer-generated document and does not require a signature. */}
      SIFLEON FARMS POULTRY AND PIGGERY
    </Text>
  </View>
);

const styles = StyleSheet.create({
  page: {
    fontFamily: "Nunito",
    fontSize: 11,
    backgroundColor: "#ffffff",
    padding: 40,
    width: "100%",
    // color: '#888888',
    color: "#000",
    // marginTop: 30,
    // marginBottom: 40,
  },
  section: {
    flexDirection: "column",
  },
  header: {
    width: "100%",
    alignContent: "center",
  },
  letterheadLogo: {
    width: 80,
    height: 80,
  },
  rows: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  tableRowEven: {
    backgroundColor: "#f2f2f2",
  },
  tableRowOdd: {
    backgroundColor: "#ffffff", // White for odd rows
  },
  title: {
    fontSize: 8,
    marginBottom: 7,
    color: "#000",
    // fontWeight: 'bold',
  },
  subTitle: {
    fontSize: 8.5,
    marginBottom: 14,
    borderBottom: "0.4px solid grey",
    color: "#000",
    fontWeight: "black",
  },
  table: {
    display: "table",
    width: "auto",
    marginVertical: 10,
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableHead: {
    flexDirection: "row",
    color: "white",
    backgroundColor: "#279e6c",
  },
  tableCell: {
    width: "100%",
    // borderWidth: 0.4,
    // borderStyle: 'solid',
    // borderColor: '#000',
    padding: 5,
    fontSize: 7,
  },
  tableCellT: {
    width: "100%",
    // borderWidth: 0.4,
    // borderStyle: 'solid',
    // borderColor: '#fff',
    padding: 5,
    fontSize: 7.5,
  },
  footer: {
    position: "absolute",
    bottom: 20,
    left: 40,
    right: 40,
    textAlign: "center",
    fontSize: 8,
    borderTop: "1px solid #000",
    paddingTop: 5,
  },
  footerText: {
    color: "#000",
  },
});

export default TransactionHistory;
