import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  Row,
  Col,
  Form,
  Button,
  Table,
  Alert,
  Tabs,
  Tab,
  Modal,
  Pagination,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import Card from "../../components/Card/MainCard.js";
import config from "../../config.js";
import Receipt from "./GeneralReceipt01.js";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";

// Register a custom font (optional)
Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  heading: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 5,
  },
  label: {
    fontSize: 12,
    marginBottom: 3,
  },
  value: {
    fontSize: 12,
  },
  footer: {
    fontSize: 12,
    marginTop: 10,
    textAlign: "center",
  },
});

const ReceiptPDF = ({ receiptData, onDownloadButtonClick }) => {
  return (
    <PDFViewer style={{ width: "100%", height: "100vh" }}>
      <Document>
        <Page style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.heading}>SFARMS SALES RECEIPT</Text>
            <Text style={styles.heading}>
              RECEIPT: #{receiptData.transactionId}
            </Text>
            <Text style={styles.heading}>
              DATE/TIME: {new Date(receiptData.createdAt).toLocaleString()}
            </Text>
            {receiptData.cus_name && (
              <View>
                <Text style={styles.label}>Customer:</Text>
                <Text style={styles.value}>{receiptData.cus_name}</Text>
              </View>
            )}
            {receiptData.birds && (
              <View>
                <Text style={styles.label}>No. of Birds:</Text>
                <Text style={styles.value}>{receiptData.birds}</Text>
              </View>
            )}
            {receiptData.trays && (
              <View>
                <Text style={styles.label}>No. of Trays:</Text>
                <Text style={styles.label}>{receiptData.trays}</Text>
              </View>
            )}
            {receiptData.eggs && (
              <View>
                <Text style={styles.label}>No. of Eggs:</Text>
                <Text style={styles.label}>{receiptData.eggs}</Text>
              </View>
            )}
            {receiptData.tagNumber && (
              <View>
                <Text style={styles.label}>Tag No.:</Text>
                <Text style={styles.value}>{receiptData.tagNumber}</Text>
              </View>
            )}
            {receiptData.goats && (
              <View>
                <Text style={styles.label}>No. of Goats:</Text>
                <Text style={styles.value}>{receiptData.goats}</Text>
              </View>
            )}
            {receiptData.pigs && (
              <View>
                <Text style={styles.label}>No. of Pigs:</Text>
                <Text style={styles.value}>{receiptData.pigs}</Text>
              </View>
            )}
            {receiptData.price && (
              <View>
                <Text style={styles.label}>Price:</Text>
                <Text style={styles.value}>
                  Ksh. {receiptData.price.toLocaleString()}
                </Text>
              </View>
            )}
            {receiptData.pay_method && (
              <View>
                <Text style={styles.label}>Payment Mode:</Text>
                <Text style={styles.value}>{receiptData.pay_method}</Text>
              </View>
            )}
            {receiptData.delivery && (
              <View>
                <Text style={styles.label}>Delivery Mode:</Text>
                <Text style={styles.value}>{receiptData.delivery}</Text>
              </View>
            )}
            {receiptData.del_guy && (
              <View>
                <Text style={styles.label}>Delivery Guy:</Text>
                <Text style={styles.value}>{receiptData.del_guy}</Text>
              </View>
            )}
            {receiptData.pay_reference && (
              <View>
                <Text style={styles.label}>Payment Reference:</Text>
                <Text style={styles.value}>{receiptData.pay_reference}</Text>
              </View>
            )}
            {receiptData.transactionId && (
              <View>
                <Text style={styles.label}>Transaction ID :</Text>
                <Text style={styles.value}>{receiptData.transactionId}</Text>
              </View>
            )}
            {receiptData.createdAt && (
              <View>
                <Text style={styles.label}>Date / Time :</Text>
                <Text style={styles.value}>
                  {new Date(receiptData.createdAt).toLocaleString()}
                </Text>
              </View>
            )}
            <View style={styles.footer}>
              <Text>Welcome Again</Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

const DirectExpenses = () => {
  const [error, setError] = useState(null);
  const formRef = useRef(null);
  const history = useHistory();
  const [success, setSuccess] = useState(null);
  const [transactionData, setTransactionData] = useState([]);
  const [showReceipt, setShowReceipt] = useState(false);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [receiptData, setReceiptData] = useState(null);
  const [searchText, setSearchText] = useState("");

  const handleDownloadButtonClick = (fileUrl) => {
    axios
      .get(fileUrl, { responseType: "blob" })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileUrl.split("/").pop());
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
        toast.error("Error downloading file.");
      });
  };

  useEffect(() => {
    const fetchTransactionData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/direct-expenses`);
        setTransactionData(response.data);
        // toast.success('Transaction history fetched successfully.');
      } catch (error) {
        console.error("Error fetching stock history:", error);
        toast.error("Error fetching stock history.");
      }
    };

    fetchTransactionData();
  }, [apiUrl]);

  const csvLinkRef = useRef();

  const exportToCSV = () => {
    const csvData = [
      ["Description", "Supplier", "Amount", "Quantity", "Date"],
      ...filteredData.map((item) => [
        item.description,
        item.supplier,
        item.amount,
        item.quantity,
        item.createdAt,
      ]),
    ];

    const csvContent = csvData.map((row) => row.join(",")).join("\n");
    const blob = new Blob(["\ufeff", csvContent], {
      type: "text/csv;charset=utf-8;",
    });

    const csvUrl = URL.createObjectURL(blob);
    csvLinkRef.current.href = csvUrl;
    csvLinkRef.current.download = "sfarms.csv";
    csvLinkRef.current.click();
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [["Description", "Supplier", "Amount", "Quantity", "Date"]],
      body: filteredData.map((user) => [
        user.description,
        user.supplier,
        user.amount,
        user.quantity,
        new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date(user.createdAt)),
      ]),
    });
    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl);
  };

  const getLabelColorClass = (description) => {
    switch (description) {
      case "Goats Purchase":
        return "label-orange";
      case "Eggs Purchase":
        return "label-blue";
      case "Pigs Purchase":
        return "label-till";
      case "Poultry Purchase":
        return "label-brown";
      case "Broken Eggs":
        return "label-kink";
      default:
        return "";
    }
  };

  const handleView = (id, description) => {
    const transactionToUpdate = transactionData.find(
      (transaction) => transaction.id === id,
    );
    history.push(`stock-expense/view/${id}/${description}`, {
      transaction: transactionToUpdate,
    });
  };

  const handleViewFile = (image) => {
    window.open(`${apiUrl}/uploads/${image}`, "_blank");
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row?.id,
      sortable: true,
      wrap: true,
    },
    {
      name: "Created",
      selector: (row) => new Date(row.createdAt).toLocaleString(),
      sortable: true,
      wrap: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      wrap: true,
    },

    {
      name: "supplier",
      selector: (row) => row?.supplier,
      sortable: true,
      wrap: true,
    },
    {
      name: "Amount",
      selector: (row) =>
        row.amount !== null
          ? row.amount.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "-",
      sortable: true,
      wrap: true,
    },
    // {
    //     name: 'Quantity',
    //     selector: (row) =>
    //         row.quantity !== null
    //             ? row.quantity.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    //             : '-',
    //     sortable: true,
    //     wrap: true
    // },
    {
      name: "Receipt",
      cell: (row) => (
        <div>
          {/* {viewPermissions} */}
          <Button
            size="sm"
            variant="info"
            onClick={() => handleView(row.id, row.description)}
          >
            <i className="feather icon-eye" /> View
          </Button>
          {row.image ? (
            <Button size="sm" onClick={() => handleViewFile(row.image)}>
              <i className="feather icon-download-cloud" /> File
            </Button>
          ) : (
            <Button size="sm" variant="secondary">
              <i className="feather icon-slash" /> File
            </Button>
          )}
        </div>
      ),
      sortable: true,
      wrap: true,
    },
  ];

  const filteredData = searchText
    ? transactionData.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchText.toLowerCase()),
        ),
      )
    : transactionData;

  const ExpandableRow = ({ data }) => {
    return (
      <div style={{ padding: "10px 20px" }}>
        <p className="m-0">
          <strong className="text-c-blue">Remarks:</strong> {data.remarks}
        </p>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Row>
        <Col sm={12}>
          {success && (
            <Alert
              variant="success"
              onClose={() => setSuccess(null)}
              dismissible
            >
              {success}
            </Alert>
          )}
          {error && (
            <Alert variant="danger" onClose={() => setError(null)} dismissible>
              {error}
            </Alert>
          )}
          <ToastContainer />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            <div className="pb-3">
              <Link to="/reports/add-stock">
                <Button variant="primary" className="mt-2">
                  <span className="feather icon-plus-circle"></span> Purchase
                  Food
                </Button>
              </Link>
              <Link to="/reports/add-stock">
                <Button variant="primary" className="mt-2">
                  <span className="feather icon-plus-circle"></span> Purchase
                  Water
                </Button>
              </Link>
            </div>
          </div>
          <Row className="btn-page">
            <Col>
              {transactionData && transactionData.length > 0 && (
                <Row>
                  <Form.Group
                    as={Col}
                    md={6}
                    sm={12}
                    lg={6}
                    controlId="formBasicName"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Search..."
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={6}
                    sm={12}
                    lg={6}
                    controlId="formBasicName"
                  >
                    <div className="d-flex">
                      <Button onClick={exportToCSV}>
                        <span className="feather icon-file"></span> Export CSV
                      </Button>
                      <a ref={csvLinkRef} style={{ display: "none" }} />
                      <a
                        href="#"
                        className="btn btn-primary"
                        onClick={exportToPDF}
                      >
                        <span className="feather icon-file-text"></span> Export
                        PDF
                      </a>
                    </div>
                  </Form.Group>
                </Row>
              )}
              <Row>
                <Col>
                  <DataTable
                    columns={columns}
                    data={filteredData}
                    pagination
                    highlightOnHover
                    striped
                    dense
                    bordered
                    paginationPerPage={20}
                    paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                    paginationComponentOptions={{
                      rowsPerPageText: "Rows per page:",
                    }}
                    expandableRows
                    expandableRowsComponent={ExpandableRow}
                    paginationResetDefaultPage={true}
                    paginationTotalRows={transactionData.length}
                    selectableRows
                    onSelectedRowsChange={(selectedRows) =>
                      console.log(selectedRows)
                    }
                    customStyles={{
                      headRow: {
                        style: {
                          backgroundColor: "#279e6c", // Green background
                          color: "#fff", // White text
                          textTransform: "uppercase", // Uppercase text
                          // fontSize: '10px', // Adjust font size for header row
                          minHeight: "1.5rem",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                        },
                      },
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default DirectExpenses;
