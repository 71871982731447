import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Font,
  Image,
} from "@react-pdf/renderer";
import config from "./../../../config";
import axios from "axios";
import Card from "../../../components/Card/MainCard";
import MontserratRegular from "./../../../fonts/static/Montserrat-Regular.ttf";
import MontserratBold from "./../../../fonts/static/Montserrat-Bold.ttf";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratRegular,
      fontWeight: "normal",
    },
    {
      src: MontserratBold,
      fontWeight: "bold",
    },
  ],
});

const UpdateUser = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [user, setUser] = useState({});
  const { id, description } = useParams();
  const history = useHistory();
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [pdfBlob, setPdfBlob] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/animal-acquisition-history/${id}/${description}`,
        );
        setUser(response.data[0]);
        console.log(response.data[0]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, [id, apiUrl]);

  const generatePdf = () => {
    const blob = new Blob([<PDFContent id={id} />], {
      type: "application/pdf",
    });
    setPdfBlob(blob);
  };

  return (
    <React.Fragment>
      <Row>
        <Col sm={12}>
          <Link to="/reports/animal-acquisition">
            <Button variant="primary" className="mb-3 mt-2">
              <span className="feather icon-arrow-left"></span> Go Back
            </Button>
          </Link>
          <Card title="View Expense" isOption>
            <Row>
              <Col sm={12} md={12} className="border-bottom">
                <div className="row d-flex align-items-center m-b-10">
                  <div className="col-12">
                    <div className="pdf-button-container">
                      <Button onClick={generatePdf} className="no-pdf">
                        <i className="feather icon-printer f-40 text-c-white" />
                      </Button>
                      {pdfBlob && (
                        <PDFDownloadLink
                          document={<PDFContent id={id} user={user} />}
                          fileName={`animal_bought_${id}.pdf`}
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? "Loading..." : "Print PDF"
                          }
                        </PDFDownloadLink>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={12} className="border-bottom">
                <div className="row d-flex align-items-center m-b-10">
                  <div className="col-4">
                    <img
                      src={avatarImage}
                      alt="Comapny Logo"
                      style={{
                        width: "100px",
                        height: "auto",
                        align: "center",
                      }}
                    />
                  </div>
                  <div className="col-4"></div>
                  <div className="col-4">
                    {/* <div>SFARMS.CO.KE</div> */}
                    <div>P.O BOX 800-222</div>
                    <div>Naivasha</div>
                    <div>Phone : 254-787-689-760</div>
                    <div>Email : info@sfarms.co.ke</div>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={12} className="border-bottom">
                <div className="row d-flex align-items-center m-t-20 m-b-20">
                  <div className="col-4">
                    <h6 className="d-block text-uppercase m-1">
                      SFARMS Company
                    </h6>
                    <p className="m-1">P.O BOX 800-222</p>
                    <p className="m-1">Naivasha,</p>
                    <p className="m-1">Phone : 254-787-689-760</p>
                    <p className="m-1">Email : info@sfarms.co.ke</p>
                  </div>
                  <div className="col-4"></div>

                  <div className="col-4">
                    {user.sup_info && (
                      <p className="m-1">Supplier: {user.sup_info}</p>
                    )}
                    {user.sup_name && (
                      <p className="m-1">Supplier: {user.sup_name}</p>
                    )}
                    {user.quantity && (
                      <p className="m-1">Quantity: {user.quantity}</p>
                    )}
                    {user.batch && <p className="m-1">Batch: {user.batch}</p>}
                    {user.siloNumber && (
                      <p className="m-1">Silo No: {user.siloNumber}</p>
                    )}
                    {user.tagNumber && (
                      <p className="m-1">Tag No: {user.tagNumber}</p>
                    )}
                  </div>
                </div>
              </Col>
              <Col sm={12} md={12} className="border-bottom">
                <div className="row d-flex align-items-center m-t-20 m-b-20">
                  <div className="col-4">
                    {user.price && (
                      <p className="m-l-5 m-t-10 m-b-10">
                        Price : Ksh {user.price}
                      </p>
                    )}
                    {user.cost && (
                      <p className="m-l-5 m-t-10 m-b-10">
                        Cost : Ksh {user.cost}
                      </p>
                    )}
                    {user.totalCost && (
                      <p className="m-l-5 m-t-10 m-b-10">
                        Amount : Ksh {user.totalCost}
                      </p>
                    )}
                    {user.type && (
                      <p className="m-l-5 m-t-10 m-b-10">Type : {user.type}</p>
                    )}
                    {user.age && (
                      <p className="m-l-5 m-t-10 m-b-10">Age : {user.age}</p>
                    )}
                    {user.health && (
                      <p className="m-l-5 m-t-10 m-b-10">
                        Health : {user.health}
                      </p>
                    )}
                  </div>
                  <div className="col-4"></div>

                  <div className="col-4">
                    {user.createdAt && (
                      <p className="m-t-10 m-b-10">
                        Recorded Date :{" "}
                        {new Date(user.createdAt).toLocaleString()}
                      </p>
                    )}
                    {user.birth && (
                      <p className="m-t-10 m-b-10">
                        D.O.B : {new Date(user.birth).toLocaleDateString()}
                      </p>
                    )}
                    {user.id && (
                      <p className="m-t-10 m-b-10">#ID : {user.id}</p>
                    )}
                  </div>
                </div>
              </Col>
              <Col sm={12} md={12}>
                <div className="row d-flex align-items-center m-t-20 m-b-20">
                  <div className="col-12">
                    {user.remarks && (
                      <p className="text-uppercase m-1">
                        Notes : {user.remarks}
                      </p>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const avatarImage = "/assets/farm-logo.jpg";

const PDFContent = ({ user }) => (
  <Document>
    <Page style={styles.page} size="A5" orientation="landscape">
      <View style={styles.section}>
        <View style={styles.row}>
          <View style={styles.header} fixed>
            <View style={styles.rows}>
              <View style={styles.column}>
                <Image source={avatarImage} style={styles.letterheadLogo} />
              </View>

              <View style={styles.column}></View>
              <View style={{ ...styles.column, textAlign: "right" }}>
                <Text>SFARMS.CO.KE</Text>
                <Text>P.O BOX 800-222</Text>
                <Text>Naivasha</Text>
                <Text>Phone : 254-787-689-760</Text>
                <Text>Email : info@sfarms.co.ke</Text>
              </View>
            </View>
          </View>
        </View>
        <Text style={styles.transactionId}>Animal Details ID : #{user.id}</Text>

        <View style={styles.row}>
          <View style={styles.column}>
            {user && (
              <>
                {user.price && <Text>Price : Ksh {user.price}</Text>}
                {user.cost && <Text>Cost : Ksh {user.cost}</Text>}
                {user.totalCost && <Text>Amount : Ksh {user.totalCost}</Text>}
                {user.type && <Text>Type : {user.type}</Text>}
                {user.age && <Text>Age : {user.age}</Text>}
                {user.health && <Text>Health : {user.health}</Text>}
              </>
            )}
          </View>

          {user && (
            <View style={{ ...styles.column, textAlign: "right" }}>
              {user.sup_info && <Text>Supplier: {user.sup_info}</Text>}
              {user.sup_name && <Text>Supplier: {user.sup_name}</Text>}
              {user.quantity && <Text>Quantity: {user.quantity}</Text>}
              {user.batch && <Text>Batch: {user.batch}</Text>}
              {user.siloNumber && <Text>Silo No: {user.siloNumber}</Text>}
              {user.tagNumber && <Text>Tag No: {user.tagNumber}</Text>}
            </View>
          )}
        </View>

        <View style={styles.row}>
          {user && (
            <View style={{ ...styles.column }}>
              {user.createdAt && (
                <Text>
                  Recorded Date : {new Date(user.createdAt).toLocaleString()}
                </Text>
              )}
              {user.birth && (
                <Text>D.O.B : {new Date(user.birth).toLocaleDateString()}</Text>
              )}
              {user.id && <Text>#ID : {user.id}</Text>}
            </View>
          )}
        </View>

        <View style={styles.content}>
          {user && user.remarks && (
            <Text style={styles.notes}>Notes : {user.remarks}</Text>
          )}
        </View>
      </View>
    </Page>
  </Document>
);

const styles = StyleSheet.create({
  page: {
    fontFamily: "Montserrat",
    fontSize: 9,
    flexDirection: "row",
    backgroundColor: "#ffffff",
    width: "100%",
    marginTop: 35,
    height: "20px",
  },
  section: {
    marginTop: 5,
    marginLeft: 50,
    marginRight: 50,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    width: "100%",
    alignContent: "center",
  },
  letterheadLogo: {
    width: 100,
    height: 100,
  },
  rows: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  transactionId: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 10,
    // color: '#ffffff',
    borderBottom: "0.5px solid grey",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  column: {
    width: "50%",
  },
  notes: {
    marginTop: 20,
    fontSize: 10,
    borderTop: "0.5px solid grey",
  },
});

export default UpdateUser;
