import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import config from "../../../config";
import axios from "axios";
import { subMonths, startOfMonth, endOfMonth, format } from "date-fns";
import { Button, Card, Form } from "react-bootstrap";

const ProfitLoss = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [activeTab, setActiveTab] = useState("3m");

  useEffect(() => {
    setTabDateRange("3m"); // Set the default range to 3 months
  }, []);

  const setTabDateRange = (range) => {
    const today = new Date();
    let start, end;

    switch (range) {
      case "3m":
        start = subMonths(today, 3);
        end = today;
        break;
      case "6m":
        start = subMonths(today, 6);
        end = today;
        break;
      case "1y":
        start = subMonths(today, 12); // 1 year ago
        end = today;
        break;
      case "2y":
        start = subMonths(today, 24); // 2 years ago
        end = today;
        break;
      default:
        start = startOfMonth(today); // Fallback: Start of the current month
        end = endOfMonth(today); // End of the current month
    }

    setStartDate(format(start, "yyyy-MM-dd"));
    setEndDate(format(end, "yyyy-MM-dd"));
    setActiveTab(range);
  };

  useEffect(() => {
    const fetchProfitLossData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/profit-loss?startDate=${startDate}&endDate=${endDate}`,
        );
        const result = response.data;
        console.log(response.data);
        setData(result);
      } catch (err) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchProfitLossData();
  }, [startDate, endDate]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  // Calculations
  const grossProfit = (data?.operatingIncome || 0) - (data?.COGS || 0);

  const operatingProfit = grossProfit - (data?.operatingExpenses || 0);

  const netProfitLoss =
    operatingProfit +
    (data?.nonOperatingIncome || 0) -
    (data?.nonOperatingExpenses || 0);

  // Define the categories for display
  const categories = [
    {
      title: "Operating Income",
      data: [{ item: "Sales", amount: data?.operatingIncome || 0 }],
    },
    {
      title: "Cost of Goods Sold",
      data: [{ item: "Restock", amount: data?.COGS || 0 }],
      total: { label: "Gross Profit", value: grossProfit },
    },
    {
      title: "Operating Expenses",
      data: [
        { item: "Purchases & Salaries", amount: data?.operatingExpenses || 0 },
      ],
      total: { label: "Operating Profit", value: operatingProfit },
    },
    {
      title: "Non-Operating Income",
      data: [{ item: "Loan Repayment", amount: data?.nonOperatingIncome || 0 }],
    },
    {
      title: "Non-Operating Expenses",
      data: [
        { item: "Loan Expenses", amount: data?.nonOperatingExpenses || 0 },
      ],
      total: { label: "Net Profit/Loss", value: netProfitLoss },
    },
  ];

  // Columns for the DataTable
  const columns = [
    {
      name: "Item(s)",
      selector: (row) => row.item,
      sortable: true,
    },
    {
      name: "Amount (KES)",
      selector: (row) => row.amount,
      sortable: true,
      right: true,
      format: (row) => (
        <span className="font-weight-bold">
          {row.amount.toLocaleString(undefined, {
            minimumFractionDigits: 2,
          })}
        </span>
      ),
    },
  ];

  return (
    <>
      <Card.Text className="align-items-center">
        <div>
          <span className="pr-3">Filters:</span>
          {["3m", "6m", "1y", "2y"].map((range) => (
            <Button
              key={range}
              onClick={() => setTabDateRange(range)}
              style={{
                marginRight: "5px",
                padding: "5px",
                backgroundColor: activeTab === range ? "#007bff" : "#f8f9fa",
                color: activeTab === range ? "white" : "black",
                border: activeTab === range ? "none" : "1px solid #ced4da",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              {range === "3m"
                ? "Last 3 Months"
                : range === "6m"
                ? "Last 6 Months"
                : range === "1y"
                ? "Last 1 Year"
                : "Last 2 Years"}
            </Button>
          ))}
          {/* </div>
                <div> */}
          <span className="px-3">Date Range:</span>
          <Form.Label>
            From:{" "}
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Form.Label>
          <Form.Label style={{ marginLeft: "20px" }}>
            To:{" "}
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Form.Label>

          <span className="px-3">Exports:</span>
        </div>
        <hr />
      </Card.Text>
      <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
        <h6 style={{ textAlign: "center", marginBottom: "20px" }}>
          Profit and Loss Overview
        </h6>

        {/* Display categories in DataTables */}
        {categories.map((category, index) => (
          <div key={index} style={{ marginBottom: "10px" }}>
            <p className="font-weight-bold">{category.title}</p>
            <DataTable
              columns={columns}
              data={category.data || []}
              pagination={false}
              highlightOnHover
              striped
              dense
            />
            {/* Display totals if available */}
            {category.total && (
              <p
                style={{
                  marginTop: "5px",
                  textAlign: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  // fontSize: "1rem",
                  color: category.total.value >= 0 ? "green" : "red",
                }}
              >
                {category.total.label}:{" "}
                {`${category.total.value.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}`}
              </p>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default ProfitLoss;
