import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Table,
  Button,
  Pagination,
  Form,
  Card,
} from "react-bootstrap";
import Select from "react-select";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import axios from "axios";
import config from "../../../config";
import { useParams, useHistory } from "react-router-dom";
import { usePermissions } from "../../../permissions";
// import Card from '../../components/Card/MainCard';
import DataTable from "react-data-table-component";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [Da, setDa] = useState(0);
  const [We, setWe] = useState(0);
  const [Mo, setMo] = useState(0);
  const [DaC, setDaC] = useState(0);
  const [WeC, setWeC] = useState(0);
  const [MoC, setMoC] = useState(0);
  const [ready, setReady] = useState([]);
  const [raw, setRaw] = useState([]);
  const { id } = useParams();
  const history = useHistory();
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(5);
  const [currentPages, setCurrentPages] = useState(1);
  const [usersPerPages, setUsersPerPages] = useState(5);
  const { createPermission, deletePermission, editPermission } =
    usePermissions();
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/weaners_feed_consumption`);
        const data = response.data;
        const rawData = data.filter((item) => item.feed_type === "Raw Feeds");
        const readyData = data.filter(
          (item) => item.feed_type === "Ready Feeds",
        );

        setReady(readyData);
        setRaw(rawData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [apiUrl]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/weaners/feeds/daily`);
        setDa(response.data[0]);
        const responsec = await axios.get(`${apiUrl}/weaners/costs/daily`);
        setDaC(responsec.data[0]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/weaners/feeds/weekly`);
        setWe(response.data[0]);
        const responsec = await axios.get(`${apiUrl}/weaners/costs/weekly`);
        setWeC(responsec.data[0]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/weaners/feeds/monthly`);
        setMo(response.data[0]);
        const responsec = await axios.get(`${apiUrl}/weaners/costs/monthly`);
        setMoC(responsec.data[0]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleUpdate = (userId) => {
    const userToUpdate = users.find((user) => user.id === userId);
    history.push(`update-user/${userId}`, { user: userToUpdate });
  };

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);

    // Get the UTC values for year, month, and day
    const todayUTC = Date.UTC(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
    );
    const birthUTC = Date.UTC(
      birth.getFullYear(),
      birth.getMonth(),
      birth.getDate(),
    );

    // Calculate the age difference in milliseconds
    const ageDiffMillis = todayUTC - birthUTC;

    // Convert milliseconds to years, months, and days
    const yearDiff = Math.floor(ageDiffMillis / (1000 * 60 * 60 * 24 * 365));
    const monthDiff = Math.floor(
      (ageDiffMillis % (1000 * 60 * 60 * 24 * 365)) /
        (1000 * 60 * 60 * 24 * 30),
    );
    const dayDiff = Math.floor(
      (ageDiffMillis % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24),
    );

    if (yearDiff > 0) {
      return yearDiff === 1 ? `${yearDiff} year` : `${yearDiff} years`;
    } else if (monthDiff > 0) {
      return monthDiff === 1 ? `${monthDiff} month` : `${monthDiff} months`;
    } else {
      return dayDiff === 1 ? `${dayDiff} day` : `${dayDiff} days`;
    }
  };

  const csvLinkRef = useRef();

  const exportToCSVs = () => {
    const csvData = [
      [
        "Type",
        "Feed",
        "Total Qty",
        "Total Cost",
        "weaners",
        "Remarks",
        "Date",
        "Farm",
      ],
      ...filteredData.map((item) => [
        item.feed_type,
        item.feed_name,
        item.totalQuantity,
        item.totalCost,
        item.weanersNumber,
        item.remarks,
        item.createdAt,
        item.farm,
      ]),
    ];

    const csvContent = csvData.map((row) => row.join(",")).join("\n");
    const blob = new Blob(["\ufeff", csvContent], {
      type: "text/csv;charset=utf-8;",
    });

    const csvUrl = URL.createObjectURL(blob);
    csvLinkRef.current.href = csvUrl;
    csvLinkRef.current.download = "mpesa_payments.csv";
    csvLinkRef.current.click();
  };

  const exportToPDFs = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        ["Type", "Feed", "Total Qty", "Total Cost", "weaners", "Date", "Farm"],
      ],
      body: filteredData.map((user) => [
        user.feed_type,
        user.feed_name,
        user.totalQuantity,
        user.totalCost,
        user.weanersNumber,
        new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date(user.createdAt)),
        user.farm,
      ]),
    });
    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl);
  };

  const columns = [
    // {
    //     name: 'SIRe Id',
    //     selector: (row) => row.tagNumber,
    //     sortable: true
    // },
    {
      name: "FEED TYPE",
      selector: (row) => row.feed_type,
      // selector: (row) => new Date(row.recorded).toDateString(),
      sortable: true,
      wrap: true,
    },
    {
      name: "FEED",
      selector: (row) => row.feed_name,
      sortable: true,
      wrap: true,
    },
    {
      name: "FEED1",
      selector: (row) => row.feed_name1 || "-",
      sortable: true,
      wrap: true,
    },

    {
      name: "FEED2",
      selector: (row) => row.feed_name2 || "-",
      sortable: true,
    },
    {
      name: "TOTAL QTY",
      selector: (row) =>
        row?.totalQuantity?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      sortable: true,
      wrap: true,
    },
    {
      name: "TOTAL COST",
      selector: (row) =>
        row.totalCost !== null
          ? row.totalCost.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "",
      sortable: true,
      wrap: true,
    },

    {
      name: "WEANERS",
      selector: (row) => row.weanersNumber,
      sortable: true,
      wrap: true,
    },
    {
      name: "REMARKS",
      selector: (row) => row.remarks,
      sortable: true,
      wrap: true,
    },
    {
      name: "DATE",
      selector: (row) => calculateAge(row.createdAt),
      sortable: true,
      wrap: true,
    },
  ];

  const filteredData = searchText
    ? ready.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchText.toLowerCase()),
        ),
      )
    : ready;

  return (
    <React.Fragment>
      <Row className="btn-page">
        <Col>
          {/* <Card title="Pigs Table" isOption> */}
          <Row>
            <Form.Group
              as={Col}
              md={1}
              sm={12}
              lg={1}
              controlId="formBasicName"
            >
              <Form.Label>Search</Form.Label>
            </Form.Group>
            <Form.Group
              as={Col}
              md={3}
              sm={12}
              lg={3}
              controlId="formBasicName"
            >
              <Form.Control
                type="text"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Form.Group>
          </Row>
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            highlightOnHover
            striped
            // noHeader
            dense
            bordered
            paginationPerPage={20}
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
            paginationComponentOptions={{
              rowsPerPageText: "Rows per page:",
            }}
            paginationResetDefaultPage={true}
            paginationTotalRows={raw.length}
            // expandableRows
            // selectableRows
            onSelectedRowsChange={(selectedRows) => console.log(selectedRows)}
            customStyles={{
              headRow: {
                style: {
                  backgroundColor: "#279e6c", // Green background
                  color: "#fff", // White text
                  textTransform: "uppercase", // Uppercase text
                  // fontSize: '10px', // Adjust font size for header row
                  minHeight: "1.5rem",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                },
              },
            }}
          />
          {filteredData && filteredData.length > 0 && (
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex"></div>
              <div className="d-flex">
                <Button onClick={exportToCSVs}>Export as CSV</Button>
                <a ref={csvLinkRef} style={{ display: "none" }} />
                <a href="#" className="btn btn-primary" onClick={exportToPDFs}>
                  Export PDF
                </a>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserList;
