import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Table,
  Row,
  Col,
  Container,
  Spinner,
} from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import config from "../../../config";
import InvoiceFormModal from "./InvoiceFormModal";
// import {  endOfMonth, format } from "date-fns";
import DataTable from "react-data-table-component";
import { usePermissions } from "../../../permissions";
import Card from "../../../components/Card/MainCard";
import { Link } from "react-router-dom";
import { addDays, subMonths, startOfMonth, endOfMonth, format } from "date-fns";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

// Register ChartJS modules
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const Invoice = () => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const [dataMetrics, setDataMetrics] = useState({});
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const { createPermission, deletePermission, editPermission } =
    usePermissions();
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("3m"); // Default tab: 3 months
  useEffect(() => {
    setTabDateRange("3m"); // Set the default range to 3 months
  }, []);

  const setTabDateRange = (range) => {
    const today = new Date();
    let start, end;

    switch (range) {
      case "3m":
        start = subMonths(today, 3);
        end = today;
        break;
      case "6m":
        start = subMonths(today, 6);
        end = today;
        break;
      case "1y":
        start = subMonths(today, 12); // 1 year ago
        end = today;
        break;
      case "2y":
        start = subMonths(today, 24); // 2 years ago
        end = today;
        break;
      default:
        start = startOfMonth(today); // Fallback: Start of the current month
        end = endOfMonth(today); // End of the current month
    }

    setStartDate(format(start, "yyyy-MM-dd"));
    setEndDate(format(end, "yyyy-MM-dd"));
    setActiveTab(range);
  };

  useEffect(() => {
    fetchInvoices();
  }, [data, startDate, endDate]);

  const fetchInvoices = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/invoices`, {
        params: {
          startDate: startDate, // Example start date
          endDate: endDate, // Example end date
        },
      });
      const datas = response.data;
      setData(datas);
    } catch (error) {
      console.error("Error fetching Invoices:", error);
    }
  };

  const onSuccess = () => {
    fetchInvoices();
  };

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/invoices-metrics`, {
          params: {
            startDate: startDate, // Example start date
            endDate: endDate, // Example end date
          },
        });
        const datas = response.data;
        console.log(datas);
        setDataMetrics(datas);
      } catch (error) {
        console.error("Error fetching Invoices:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoices();
  }, [startDate, endDate]);

  const columns = [
    // {
    //     name: 'Id',
    //     selector: (row) => row?.id,
    //     sortable: true,
    //     wrap: true,
    //     maxWidth: '100px',
    //     minWidth: '100px'
    // },

    {
      name: "Invoice",
      selector: (row) => (
        <Link to="/" className="text-color-blue">
          #{row.invoiceNumber}
        </Link>
      ),
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "Status",
      selector: (row) => (
        <span className="text-capitalize">{row.paymentStatus}</span>
      ),
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },

    {
      name: "Amount",
      selector: (row) =>
        row.totalAmount !== null
          ? row.totalAmount.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "-",
      sortable: true,
      wrap: true,
      minWidth: "180px",
    },
    {
      name: "Paid Amount",
      selector: (row) =>
        row.paidAmount !== null
          ? row.paidAmount.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "-",
      sortable: true,
      wrap: true,
      minWidth: "180px",
    },

    {
      name: "Invoice Date",
      selector: (row) => new Date(row.invoiceDate).toLocaleDateString(),
      sortable: true,
      wrap: true,
      minWidth: "180px",
    },

    // {
    //     name: 'Actions',
    //     cell: (row) => (
    //         <td>
    //             {/* <Button size="sm" onClick={() => handleUpdate(row.id)}>
    //                 Edit
    //             </Button>
    //             <Button variant="danger" size="sm" onClick={() => handelDelete(row.id, row.employee)}>
    //                 Delete
    //             </Button> */}
    //         </td>
    //     ),
    //     wrap: true,
    //     minWidth: '150px'
    // }
  ];

  const { overdueInvoices, recipientTypeTotals, overallTotals, monthlyTotals } =
    dataMetrics;

  // Bar chart data configuration
  const chartData = {
    labels: monthlyTotals?.map((item) => item?.month),
    datasets: [
      {
        label: "Total Invoiced",
        data: monthlyTotals?.map((item) => item?.totalInvoiced),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: "Total Paid",
        data: monthlyTotals?.map((item) => item?.totalPaid),
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Months",
        },
      },
      y: {
        title: {
          display: true,
          text: "Amount",
        },
        beginAtZero: true,
      },
    },
  };

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" variant="primary" />
        <p>Loading data...</p>
      </Container>
    );
  }

  return (
    <>
      <Button variant="primary" onClick={() => setShow(true)} className="mb-4">
        Create Invoice
      </Button>
      <InvoiceFormModal
        show={show}
        onHide={() => setShow(false)}
        onSuccess={onSuccess}
      />
      {data && data.length > 0 ? (
        <>
          <Row className="btn-page">
            <Col>
              <Card className="align-items-center" title="Filters:" isOption>
                <div>
                  {["3m", "6m", "1y", "2y"].map((range) => (
                    <Button
                      key={range}
                      onClick={() => setTabDateRange(range)}
                      style={{
                        marginRight: "5px",
                        padding: "5px",
                        backgroundColor:
                          activeTab === range ? "#007bff" : "#f8f9fa",
                        color: activeTab === range ? "white" : "black",
                        border:
                          activeTab === range ? "none" : "1px solid #ced4da",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      {range === "3m"
                        ? "Last 3 Months"
                        : range === "6m"
                        ? "Last 6 Months"
                        : range === "1y"
                        ? "Last 1 Year"
                        : "Last 2 Years"}
                    </Button>
                  ))}
                  {/* </div>
                              <div> */}
                  <span className="px-3">Date Range:</span>
                  <Form.Label>
                    From:{" "}
                    <input
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Form.Label>
                  <Form.Label style={{ marginLeft: "20px" }}>
                    To:{" "}
                    <input
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Form.Label>

                  <span className="px-3">Exports:</span>
                </div>
                {/* <hr /> */}
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card className="align-items-center" title="Overview">
                <div>
                  Total Invoices:{" "}
                  <span className="font-weight-bold">{data?.length}</span>
                </div>
                {/* <div>
                    Total Amount:{" "}
                    <span className="font-weight-bold">{data
                      .reduce((acc, item) => acc + item?.totalAmount, 0)
                      .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                  </div> */}
                <div>
                  Total Invoiced:{" "}
                  <span className="font-weight-bold">
                    {overallTotals?.totalInvoiced?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </Card>
            </Col>

            <Col>
              <Card className="align-items-center" title="Overdue">
                <div>
                  Due Count:{" "}
                  <span className="font-weight-bold">
                    {overdueInvoices?.count?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>

                <div>
                  Total Due:{" "}
                  <span className="font-weight-bold">
                    {overdueInvoices?.amount?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </Card>
            </Col>

            <Col>
              <Card className="align-items-center" title="Totals">
                <div>
                  Total Outstanding:{" "}
                  <span className="font-weight-bold">
                    {overallTotals?.totalOutstanding?.toLocaleString(
                      undefined,
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 },
                    )}
                  </span>
                </div>

                <div>
                  Total Paid:{" "}
                  <span className="font-weight-bold">
                    {overallTotals?.totalPaid?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </Card>
            </Col>

            <Col>
              <Card className="align-items-center" title="Totals">
                {recipientTypeTotals &&
                  recipientTypeTotals?.map((item) => (
                    <div key={item?.recipientType}>
                      <div>
                        <span className="text-capitalize">
                          {item?.recipientType}:
                        </span>{" "}
                        <span className="font-weight-bold">
                          {item?.totalAmount?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </span>
                      </div>
                    </div>
                  ))}
              </Card>
            </Col>

            {/* Chart */}
            <Col xs={12}>
              <Card className="align-items-center" title="Monthly Overview">
                <Bar data={chartData} options={chartOptions} />
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          <h5>No data available</h5>
        </div>
      )}
    </>
  );
};

export default Invoice;
