import React, { useState, useEffect, useMemo } from "react";
import { Modal, Button, Form, Table, Row, Col } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import config from "../../../config";
import InvoicePay from "./InvoicePay";
// import {  endOfMonth, format } from "date-fns";
import DataTable from "react-data-table-component";
import { usePermissions } from "../../../permissions";
import Card from "../../../components/Card/MainCard";
import { Link } from "react-router-dom";
import { addDays, subMonths, startOfMonth, endOfMonth, format } from "date-fns";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import ConfirmationModal from "./ConfirmationModal";
import { toast } from "react-toastify";
import InvoiceFormModal from "./InvoiceFormModal";

// Register ChartJS modules
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const InvoiceList = () => {
  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);
  const [data, setData] = useState({});
  const [invoiceData, setInvoiceData] = useState({});
  const [dataMetrics, setDataMetrics] = useState({});
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const { createPermission, deletePermission, editPermission } =
    usePermissions();
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("3m"); // Default tab: 3 months
  useEffect(() => {
    setTabDateRange("3m"); // Set the default range to 3 months
  }, []);
  const [filteredData, setFilteredData] = useState([]);
  const [dueFilter, setDueFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  const setTabDateRange = (range) => {
    const today = new Date();
    let start, end;

    switch (range) {
      case "3m":
        start = subMonths(today, 3);
        end = today;
        break;
      case "6m":
        start = subMonths(today, 6);
        end = today;
        break;
      case "1y":
        start = subMonths(today, 12); // 1 year ago
        end = today;
        break;
      case "2y":
        start = subMonths(today, 24); // 2 years ago
        end = today;
        break;
      default:
        start = startOfMonth(today); // Fallback: Start of the current month
        end = endOfMonth(today); // End of the current month
    }

    setStartDate(format(start, "yyyy-MM-dd"));
    setEndDate(format(end, "yyyy-MM-dd"));
    setActiveTab(range);
  };

  useEffect(() => {
    fetchInvoices();
  }, [startDate, endDate]);

  const fetchInvoices = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/invoices`, {
        params: {
          startDate: startDate, // Example start date
          endDate: endDate, // Example end date
        },
      });
      const datas = response.data;
      // console.log(datas);

      setData(datas);
    } catch (error) {
      console.error("Error fetching Invoices:", error);
    }
  };

  const handleUpdate = (id) => {
    // console.log('Update invoice:', id);
    setInvoiceData(id);
    setShows(true);
  };

  const onSuccess = () => {
    fetchInvoices();
  };

  // const payStatus = (status) => {
  //     const
  // }

  const columns = [
    {
      name: "Invoice",
      selector: (row) => (
        <Link to={`/invoices/view/${row.id}`} className="text-color-blue">
          #{row.invoiceNumber}
        </Link>
      ),
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "Status",
      selector: (row) => (
        <span className="text-capitalize">{row.paymentStatus}</span>
      ),
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },

    {
      name: "Amount",
      selector: (row) =>
        row.totalAmount !== null
          ? row.totalAmount.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "-",
      sortable: true,
      wrap: true,
      minWidth: "180px",
    },
    {
      name: "Paid Amount",
      selector: (row) =>
        row.paidAmount !== null
          ? row.paidAmount.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "-",
      sortable: true,
      wrap: true,
      minWidth: "180px",
    },

    {
      name: "Invoice Date",
      selector: (row) => new Date(row.invoiceDate).toLocaleDateString(),
      sortable: true,
      wrap: true,
      minWidth: "180px",
    },

    {
      name: "Actions",
      cell: (row) => (
        <td>
          <Button size="sm" onClick={() => handleUpdate(row)}>
            Payment
          </Button>
          <Button
            variant="info"
            size="sm"
            //   onClick={() => handelDelete(row.id, row.employee)}
          >
            Send Email
          </Button>
        </td>
      ),
      wrap: true,
      minWidth: "250px",
    },
  ];

  //   const filteredData = searchText
  //       ? data.filter((item) =>
  //             Object.values(item).some((value) => typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase()))
  //         )
  //       : data;
  // Extract unique types from data
  const dataArray = useMemo(() => (Array.isArray(data) ? data : []), [data]);

  // Extract unique types for the dropdown
  const uniqueTypes = [
    { value: "", label: "Filter by Type" }, // Default option to show all
    ...[...new Set(dataArray.map((item) => item.recipientType))].map(
      (type) => ({
        value: type,
        label: type,
      }),
    ),
  ];

  // Filter data based on selected type
  //  useEffect(() => {
  //      if (searchText) {
  //          setFilteredData(dataArray.filter((item) => item.recipientType === searchText));
  //      } else {
  //          setFilteredData(dataArray); // Show all by default
  //      }
  //  }, [searchText, dataArray]);

  const dueOptions = [
    { value: "", label: "Filter by Due Status" }, // Default option to show all invoices
    { value: "due", label: "Due" }, // Filter for overdue invoices
    { value: "not_due", label: "Not Due" }, // Filter for invoices that are not overdue
  ];

  // Filter data based on selected type and due status
  useEffect(() => {
    const currentDate = new Date(); // Get current date

    let filtered = dataArray;

    // Apply type filter
    if (typeFilter) {
      filtered = filtered.filter((item) => item.recipientType === typeFilter);
    }

    // Apply due status filter
    if (dueFilter === "due") {
      filtered = filtered.filter(
        (item) => new Date(item.dueDate) < currentDate, // Overdue invoices
      );
    } else if (dueFilter === "not_due") {
      filtered = filtered.filter(
        (item) => new Date(item.dueDate) >= currentDate, // Invoices not overdue
      );
    }

    // if (searchText) {
    //      filtered = filtered.filter((item) => Object.values(item).some((value) => typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())))
    //  } else {
    //      filtered = dataArray; // Show all by default
    //  }

    setFilteredData(filtered);
  }, [typeFilter, dueFilter, dataArray, searchText]);

  return (
    <>
      <Button variant="primary" onClick={() => setShow(true)} className="mb-4">
        Create Invoice
      </Button>
      <InvoiceFormModal show={show} onHide={() => setShow(false)} />
      {data && data.length > 0 ? (
        <>
          <Row className="btn-page">
            <Col>
              <Card className="align-items-center" title="Filters:" isOption>
                <div>
                  {["3m", "6m", "1y", "2y"].map((range) => (
                    <Button
                      key={range}
                      onClick={() => setTabDateRange(range)}
                      style={{
                        marginRight: "5px",
                        padding: "5px",
                        backgroundColor:
                          activeTab === range ? "#007bff" : "#f8f9fa",
                        color: activeTab === range ? "white" : "black",
                        border:
                          activeTab === range ? "none" : "1px solid #ced4da",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      {range === "3m"
                        ? "Last 3 Months"
                        : range === "6m"
                        ? "Last 6 Months"
                        : range === "1y"
                        ? "Last 1 Year"
                        : "Last 2 Years"}
                    </Button>
                  ))}
                  {/* </div>
                              <div> */}
                  <span className="px-3">Date Range:</span>
                  <Form.Label>
                    From:{" "}
                    <input
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Form.Label>
                  <Form.Label style={{ marginLeft: "20px" }}>
                    To:{" "}
                    <input
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Form.Label>

                  <span className="px-3">Exports:</span>
                </div>
                {/* <hr /> */}
              </Card>
            </Col>
          </Row>

          <Row className="btn-page">
            <Col>
              <Card title="Invoices List" isOption>
                <Row>
                  <Form.Group
                    as={Col}
                    md={4}
                    sm={12}
                    lg={4}
                    controlId="formBasicName"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Search..."
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={4}
                    sm={12}
                    lg={4}
                    controlId="formBasicName"
                  >
                    <Select
                      options={uniqueTypes}
                      value={uniqueTypes?.find(
                        (option) => option.value === searchText,
                      )}
                      onChange={(selectedOption) =>
                        setTypeFilter(selectedOption.value)
                      }
                      placeholder="Filter by Type"
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={4}
                    sm={12}
                    lg={4}
                    controlId="formBasicName"
                  >
                    <Select
                      options={dueOptions}
                      value={dueOptions.find(
                        (option) => option.value === dueFilter,
                      )}
                      onChange={(selectedOption) =>
                        setDueFilter(selectedOption.value)
                      }
                      placeholder="Filter by Due Status"
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={6}
                    sm={12}
                    lg={6}
                    controlId="formBasicName"
                  >
                    {/* <div className="d-flex">
                      <Button onClick={exportToCSV}>
                          <span className="feather icon-file"></span> Export CSV
                      </Button>
                      <a ref={csvLinkRef} style={{ display: 'none' }} />
                      <a href="#" className="btn btn-primary" onClick={exportToPDF}>
                          <span className="feather icon-file-text"></span> Export PDF
                      </a>
                  </div> */}
                  </Form.Group>
                </Row>

                <Row>
                  <Col>
                    <DataTable
                      columns={columns}
                      data={filteredData}
                      pagination
                      highlightOnHover
                      striped
                      // noHeader
                      dense
                      bordered
                      paginationPerPage={20}
                      paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                      paginationComponentOptions={{
                        rowsPerPageText: "Rows per page:",
                      }}
                      paginationResetDefaultPage={true}
                      paginationTotalRows={data.length}
                      // expandableRows
                      // expandableRowsComponent={ExpandableRow}
                      selectableRows
                      onSelectedRowsChange={(selectedRows) =>
                        console.log(selectedRows)
                      }
                      customStyles={{
                        headRow: {
                          style: {
                            backgroundColor: "#279e6c", // Green background
                            color: "#fff", // White text
                            textTransform: "uppercase", // Uppercase text
                            // fontSize: '10px', // Adjust font size for header row
                            minHeight: "1.5rem",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                          },
                        },
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          <h5>No data available</h5>
        </div>
      )}

      {/* <ConfirmationModal show={showConfirmationModal} onHide={handleCancelConfirmation} onConfirm={handleConfirmation} /> */}
      <InvoicePay
        show={shows}
        onHide={() => setShows(false)}
        invoiceData={invoiceData}
        onSuccess={onSuccess}
      />
    </>
  );
};

export default InvoiceList;
