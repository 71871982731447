import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Table, Button, Pagination, Form } from "react-bootstrap";
import Select from "react-select";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import axios from "axios";
import config from "../../config";
import { useParams, useHistory } from "react-router-dom";
import Card from "../../components/Card/MainCard";
import { usePermissions } from "../../permissions";
import DataTable from "react-data-table-component";

const WaterList = () => {
  const [users, setUsers] = useState([]);
  const { id } = useParams();
  const history = useHistory();
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const { createPermission, deletePermission, editPermission } =
    usePermissions();
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/water`);
        setUsers(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [users, apiUrl]);

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);

    // Get the UTC values for year, month, and day
    const todayUTC = Date.UTC(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
    );
    const birthUTC = Date.UTC(
      birth.getFullYear(),
      birth.getMonth(),
      birth.getDate(),
    );

    // Calculate the age difference in milliseconds
    const ageDiffMillis = todayUTC - birthUTC;

    // Convert milliseconds to years, months, and days
    const yearDiff = Math.floor(ageDiffMillis / (1000 * 60 * 60 * 24 * 365));
    const monthDiff = Math.floor(
      (ageDiffMillis % (1000 * 60 * 60 * 24 * 365)) /
        (1000 * 60 * 60 * 24 * 30),
    );
    const dayDiff = Math.floor(
      (ageDiffMillis % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24),
    );

    if (yearDiff > 0) {
      return yearDiff === 1 ? `${yearDiff} year ago` : `${yearDiff} years ago`;
    } else if (monthDiff > 0) {
      return monthDiff === 1
        ? `${monthDiff} month ago`
        : `${monthDiff} months ago`;
    } else if (dayDiff > 0) {
      return dayDiff === 1 ? `${dayDiff} day ago` : `${dayDiff} days ago`;
    } else {
      return dayDiff === 0 ? `Today` : `${dayDiff} days ago`;
    }
  };

  const csvLinkRef = useRef();

  const exportToCSV = () => {
    const csvData = [
      ["Supplier Name", "WAter User", "Quanity", "Cost", "Date"],
      ...filteredData.map((item) => [
        item.sup_name,
        item.water_user,
        item.capacity,
        item.cost,
        item.createdAt,
      ]),
    ];

    const csvContent = csvData.map((row) => row.join(",")).join("\n");
    const blob = new Blob(["\ufeff", csvContent], {
      type: "text/csv;charset=utf-8;",
    });

    const csvUrl = URL.createObjectURL(blob);
    csvLinkRef.current.href = csvUrl;
    csvLinkRef.current.download = "sfarms.csv";
    csvLinkRef.current.click();
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [["Supplier Name", "WAter User", "Quanity", "Cost", "Date"]],
      body: filteredData.map((user) => [
        user.sup_name,
        user.water_user,
        user.capacity,
        user.cost.toLocaleString(),
        new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date(user.createdAt)),
      ]),
    });
    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl);
  };

  const handleViewFile = (image) => {
    window.open(`${apiUrl}/uploads/${image}`, "_blank");
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },

    {
      name: "Supplier Name",
      selector: (row) => row?.sup_name,
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "WAter User",
      selector: (row) => row?.water_user,
      sortable: true,
      wrap: true,
      minWidth: "200px",
    },
    {
      name: "TOTAL COST",
      selector: (row) =>
        row?.cost?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      sortable: true,
      wrap: true,
    },

    {
      name: "DEscription",
      selector: (row) => row.remarks,
      sortable: true,
      wrap: true,
      minWidth: "400px",
    },

    {
      name: "Date",
      selector: (row) => new Date(row?.recorded)?.toLocaleDateString(),
      sortable: true,
      wrap: true,
    },
    {
      name: "Timestamp",
      selector: (row) => calculateAge(row.createdAt),
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "Action",
      cell: (row) =>
        row.image ? (
          <Button
            size="sm"
            variant="info"
            onClick={() => handleViewFile(row.image)}
          >
            <i className="feather icon-download-cloud" /> View
          </Button>
        ) : (
          <Button size="sm" variant="secondary">
            <i className="feather icon-slash" /> Null
          </Button>
        ),
      wrap: true,
      minWidth: "100px",
    },
  ];

  const filteredData = searchText
    ? users.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchText.toLowerCase()),
        ),
      )
    : users;

  return (
    <React.Fragment>
      <Row className="btn-page">
        <Col>
          <div className="d-flex justify-content-between align-items-center m-b-10">
            {createPermission && (
              <Link to="/water/water">
                <Button variant="primary" className="">
                  <span className="feather icon-plus-circle"></span> Purchase
                  Water
                </Button>
              </Link>
            )}
          </div>
          <Row className="btn-page">
            <Col>
              {users && users.length > 0 && (
                <Row>
                  <Form.Group
                    as={Col}
                    md={6}
                    sm={12}
                    lg={6}
                    controlId="formBasicName"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Search..."
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={6}
                    sm={12}
                    lg={6}
                    controlId="formBasicName"
                  >
                    <div className="d-flex">
                      <Button onClick={exportToCSV}>
                        <span className="feather icon-file"></span> Export CSV
                      </Button>
                      <a ref={csvLinkRef} style={{ display: "none" }} />
                      <a
                        href="#"
                        className="btn btn-primary"
                        onClick={exportToPDF}
                      >
                        <span className="feather icon-file-text"></span> Export
                        PDF
                      </a>
                    </div>
                  </Form.Group>
                </Row>
              )}
              <Row>
                <Col>
                  <DataTable
                    columns={columns}
                    data={filteredData}
                    pagination
                    highlightOnHover
                    striped
                    // noHeader
                    dense
                    bordered
                    paginationPerPage={20}
                    paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                    paginationComponentOptions={{
                      rowsPerPageText: "Rows per page:",
                    }}
                    paginationResetDefaultPage={true}
                    paginationTotalRows={users.length}
                    // expandableRows
                    selectableRows
                    onSelectedRowsChange={(selectedRows) =>
                      console.log(selectedRows)
                    }
                    customStyles={{
                      headRow: {
                        style: {
                          backgroundColor: "#279e6c", // Green background
                          color: "#fff", // White text
                          textTransform: "uppercase", // Uppercase text
                          // fontSize: '10px', // Adjust font size for header row
                          minHeight: "1.5rem",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                        },
                      },
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default WaterList;
