import React, { useState, useEffect } from "react";
import LeaveForm from "./LeaveForm";
import { Table, Button, Row, Col, Form } from "react-bootstrap";
import axios from "axios";
import config from "./../../../config";
import ReactPaginate from "react-paginate";
import { usePermissions } from "../../../permissions";
import DataTable from "react-data-table-component";
import { useRef } from "react";
// import { XLSX } from 'xlsx';

const LeaveManagement = () => {
  const [leaveData, setLeaveData] = useState([]);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState(null);
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const { createPermission, deletePermission, editPermission } =
    usePermissions();
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    axios
      .get(`${apiUrl}/leave_request`)
      .then((response) => {
        const userData = response.data;
        setLeaveData(userData);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, [leaveData]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/users`)
      .then((response) => {
        const userData = response.data;
        setUsers(userData);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, [users, isPopupVisible, selectedLeave, apiUrl]);

  const csvLinkRef = useRef();

  const handleSaveLeave = (newLeave) => {
    if (selectedLeave) {
      // Handle editing an existing leave
      // Send a PUT request to update the leave on the server
    } else {
      // Handle adding a new leave
      // Send a POST request to create a new leave on the server
    }

    setPopupVisible(false);
  };

  const showPopup = () => {
    setSelectedLeave(null);
    setPopupVisible(true);
  };

  const editLeave = (leave) => {
    setSelectedLeave(leave);
    setPopupVisible(true);
  };

  const hidePopup = () => {
    setSelectedLeave(null);
    setPopupVisible(false);
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const calculateDuration = (startDate, endDate) => {
    // Calculate the duration in days
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = Math.abs(end - start);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 1) return "1 day";
    if (diffDays >= 30) return `${Math.floor(diffDays / 30)} months`;
    return `${diffDays} days`;
  };

  const exportDataToCSV = () => {
    const csvData = filteredData.map((leave) => ({
      Employee: leave.employee,
      "Start Date": new Date(leave.startDate).toLocaleDateString(),
      "End Date": new Date(leave.endDate).toLocaleDateString(),
      Duration: calculateDuration(leave.startDate, leave.endDate),
      Description: leave.description,
      "Leave Type": leave.type,
      "Created On": new Date(leave.createdAt).toLocaleString(),
    }));

    const csvHeaders = Object.keys(csvData[0]);
    const csvContent = [
      csvHeaders.join(","),
      ...csvData.map((row) =>
        csvHeaders.map((header) => row[header]).join(","),
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "leave_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row?.id,
      sortable: true,
      wrap: true,
      maxWidth: "100px",
      minWidth: "100px",
    },

    {
      name: "employee",
      selector: (row) => row.employee,
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },

    {
      name: "Start Date",
      selector: (row) => new Date(row.startDate).toLocaleDateString(),
      sortable: true,
      wrap: true,
      minWidth: "170px",
    },
    {
      name: "End Date",
      selector: (row) => new Date(row.endDate).toLocaleDateString(),
      sortable: true,
      wrap: true,
      minWidth: "200px",
    },
    {
      name: "Duration",
      selector: (row) => row.numberOfDays,
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "Description",
      selector: (row) => row?.description,
      sortable: true,
      wrap: true,
      minWidth: "100px",
    },
    {
      name: "Leave Type",
      selector: (row) => row.type,
      cell: (row) => (
        <span
          className={`${
            row.type === "Sick Leave"
              ? "label-brown"
              : row.type === "Annual Leave"
              ? "label-blue"
              : row.type === "Holidays"
              ? "label-orange"
              : row.type === "Maternity Leave"
              ? "label-till"
              : "label-kink"
          }`}
        >
          {row.type}
        </span>
      ),
      sortable: true,
      wrap: true,
      minWidth: "180px",
    },
    {
      name: "Created",
      selector: (row) => new Date(row.createdAt).toLocaleString(),
      sortable: true,
      wrap: true,
      minWidth: "180px",
    },
    {
      name: "Actions",
      cell: (row) => (
        <td>
          <Button onClick={() => editLeave(row)}>
            <span className="feather icon-edit"></span> View
          </Button>
        </td>
      ),
      wrap: true,
      minWidth: "150px",
    },
  ];

  const filteredData = searchText
    ? leaveData.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchText.toLowerCase()),
        ),
      )
    : leaveData;

  return (
    <div>
      {/* <h4>Leave Management</h4> */}
      {createPermission && (
        <Button onClick={showPopup} className="m-b-10">
          <span className="feather icon-file"></span> Add Leave
        </Button>
      )}
      {isPopupVisible && (
        <LeaveForm
          leave={selectedLeave}
          onSave={handleSaveLeave}
          onCancel={hidePopup}
          users={users} // Pass user data to the LeaveForm component
        />
      )}

      <Row className="btn-page">
        <Col>
          {leaveData && leaveData.length > 0 && (
            <Row>
              <Form.Group
                as={Col}
                md={6}
                sm={12}
                lg={6}
                controlId="formBasicName"
              >
                <Form.Control
                  type="text"
                  placeholder="Search..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                md={6}
                sm={12}
                lg={6}
                controlId="formBasicName"
              >
                <div className="d-flex">
                  <Button onClick={exportDataToCSV}>
                    <span className="feather icon-file"></span> Export CSV
                  </Button>
                  <a ref={csvLinkRef} style={{ display: "none" }} />
                  {/* <a href="#" className="btn btn-primary">
                                                <span className="feather icon-file-text"></span> Export PDF
                                            </a> */}
                </div>
              </Form.Group>
            </Row>
          )}
          <Row>
            <Col>
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                highlightOnHover
                striped
                // noHeader
                dense
                bordered
                paginationPerPage={20}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                paginationComponentOptions={{
                  rowsPerPageText: "Rows per page:",
                }}
                paginationResetDefaultPage={true}
                paginationTotalRows={leaveData.length}
                // expandableRows
                // expandableRowsComponent={ExpandableRow}
                selectableRows
                onSelectedRowsChange={(selectedRows) =>
                  console.log(selectedRows)
                }
                customStyles={{
                  headRow: {
                    style: {
                      backgroundColor: "#279e6c", // Green background
                      color: "#fff", // White text
                      textTransform: "uppercase", // Uppercase text
                      // fontSize: '10px', // Adjust font size for header row
                      minHeight: "1.5rem",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                    },
                  },
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default LeaveManagement;
