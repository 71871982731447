import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../config";
import Card from "../../../components/Card/MainCard";
import DataTable from "react-data-table-component";

const UserList = () => {
  const { roleId } = useParams();
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [searchText, setSearchText] = useState("");
  const [role, setRole] = useState({ id: "", name: "" });
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchRoleAndPermissions = async () => {
      try {
        const roleResponse = await axios.get(`${apiUrl}/roles/${roleId}`);
        const permissionsResponse = await axios.get(
          `${apiUrl}/roles/${roleId}/permissions`,
        );
        setRole(roleResponse.data[0]);
        console.log(roleResponse);
        setPermissions(permissionsResponse.data);
        console.log(permissionsResponse);
      } catch (error) {
        console.error("Error fetching role and permissions:", error);
      }
    };

    fetchRoleAndPermissions();
  }, [roleId]);

  const handleCheckboxChange = async (permissionId, operation, isChecked) => {
    const updatedPermissions = permissions.map((permission) => {
      if (permission.id === permissionId) {
        return {
          ...permission,
          [operation]: isChecked,
        };
      }
      return permission;
    });
    console.log(updatedPermissions);
    setPermissions(updatedPermissions);

    try {
      await axios.put(`${apiUrl}/roles/${roleId}/permissions`, {
        permissions: updatedPermissions,
      });
      console.log("Permissions updated successfully");
    } catch (error) {
      console.error("Error updating permissions:", error);
    }
  };

  const columns = [
    {
      name: "Models",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Create",
      cell: (row) => (
        <input
          type="checkbox"
          checked={row.create_permission}
          onChange={(e) =>
            handleCheckboxChange(row.id, "create_permission", e.target.checked)
          }
          value={row.create}
          disabled
        />
      ),
      sortable: true,
    },
    {
      name: "View",
      cell: (row) => (
        <input
          type="checkbox"
          checked={row.view_permission}
          onChange={(e) =>
            handleCheckboxChange(row.id, "view_permission", e.target.checked)
          }
          disabled
        />
      ),
      sortable: true,
    },
    {
      name: "Edit",
      cell: (row) => (
        <input
          type="checkbox"
          checked={row.edit_permission}
          onChange={(e) =>
            handleCheckboxChange(row.id, "edit_permission", e.target.checked)
          }
          disabled
        />
      ),
      sortable: true,
    },
    {
      name: "Delete",
      cell: (row) => (
        <input
          type="checkbox"
          checked={row.delete_permission}
          onChange={(e) =>
            handleCheckboxChange(row.id, "delete_permission", e.target.checked)
          }
          disabled
        />
      ),
      sortable: true,
    },
  ];

  const handleInput = async (e) => {
    const newName = e.target.value;
    setRole({ ...role, name: newName });

    try {
      await axios.put(`${apiUrl}/roles/${roleId}`, { name: newName });
      console.log("Role name updated successfully");
    } catch (error) {
      console.error("Error updating role name:", error);
    }
  };

  return (
    <React.Fragment>
      <Row className="btn-page">
        <Col>
          <div className="d-flex justify-content-between align-center m-b-10">
            <div>
              <span> </span>
            </div>
            <Link to="/users/roles">
              <Button variant="primary" className="">
                <span className="feather icon-arrow-left"></span> Back
              </Button>
            </Link>
          </div>

          {/* <Card> */}
          <div className="m-b-10">
            Edit Role:{" "}
            <Form.Control
              type="text"
              value={role?.name || ""}
              onChange={handleInput}
              name="name"
              disabled
            />{" "}
          </div>
          {/* </Card> */}

          <Card title="View Role Permissions" isOption>
            {/* <div>
                            <Row>
                                <Form.Group as={Col} md={1} sm={12} lg={1} controlId="formBasicName">
                                    <Form.Label>Search</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md={3} sm={12} lg={3} controlId="formBasicName">
                                    <Form.Control
                                        type="text"
                                        placeholder="Search..."
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                </Form.Group>
                            </Row>
                        </div> */}

            <DataTable
              columns={columns}
              data={permissions}
              loading={loading}
              pagination
              highlightOnHover
              striped
              noHeader
              paginationPerPage={20}
              paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              paginationComponentOptions={{
                rowsPerPageText: "Rows per page:",
              }}
              paginationResetDefaultPage={true}
              paginationTotalRows={permissions.length}
            />
            {/* <Button onClick={handleSavePermissions}>Save Changes</Button> */}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserList;
