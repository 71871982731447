import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Form,
  Tab,
  Tabs,
  Alert,
  Button,
  Modal,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../../config";
import ConfirmationModal from "./ConfirmationModal";
import Card from "../../../components/Card/MainCard";
import axios from "axios";
import { usePermissions } from "../../../permissions";
import Select from "react-select";

const SamplePage = ({ onCancel }) => {
  const account = useSelector((state) => state.account);
  const [farmOptions, setFarmOptions] = useState([]);
  const [farm, setSelectedFarm] = useState(null);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const formRef = useRef(null);
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { createPermission, deletePermission, editPermission } =
    usePermissions();
  const [batchesFarm, setBatchesFarm] = useState("");
  const [batch, setSelectedBatch] = useState(null);
  const [batches, setBatches] = useState([]);

  useEffect(() => {
    const fetchAllBatches = async () => {
      try {
        const response = await axios.get(`${apiUrl}/poultry`);
        const data = response.data;

        const options = data.map((supplier) => ({
          value: supplier.batch,
          label: supplier.batch,
        }));

        setBatches(options);
      } catch (error) {
        console.error("Error fetching batches:", error);
      }
    };

    fetchAllBatches();
  }, [farm, apiUrl]);

  const memoizedApiUrl1 = useMemo(() => {
    return `${apiUrl}/farm`;
  }, [apiUrl]);

  useEffect(() => {
    const fetchFarms = async () => {
      try {
        const response = await axios.get(memoizedApiUrl1);
        const farmData = response.data;
        const options = farmData.map((farm) => ({
          value: farm.name,
          label: farm.name,
        }));

        const filtered = options.filter((b) => b.value !== batchesFarm);

        console.log(batchesFarm, filtered);

        setFarmOptions(filtered);
      } catch (error) {
        console.error("Error fetching Farms:", error);
      }
    };

    fetchFarms();
  }, [memoizedApiUrl1, batchesFarm, batches]);

  useEffect(() => {
    const fetchAllBatches = async () => {
      try {
        const response = await axios.get(`${apiUrl}/poultry`);
        const data = response.data;

        // Filter batches based on selected farm and poultry type
        const filteredData = data.find((b) => b.batch === batch);
        // console.log(data, filteredData, filteredData.farm);

        setBatchesFarm(filteredData.farm);
      } catch (error) {
        console.error("Error fetching batches:", error);
      }
    };

    fetchAllBatches();
  }, [batch, apiUrl]);

  const handleInput = (e) => {
    const { name, value } = e.target;

    setData({
      ...data,
      [name]: value,
      userId: account.user.id,
      userName: account.user.firstname,
      farmFrom: batchesFarm,
    });
    console.log(data);
  };

  const handleGoat = async (e) => {
    e.preventDefault();
    setShowConfirmationModal(true);
  };

  const handleConfirmation = async (e) => {
    if (!createPermission) {
      return;
    }
    try {
      const response = await axios.post(
        `${apiUrl}/poultry-farm-movement`,
        data,
      );

      if (response.data.message) {
        // setSuccess(`${data.batch} moved successfully`);
        // setTimeout(() => {
        //   setSuccess(null);
        // }, 10000);
        toast.success(response.data.message);
        formRef.current.reset();
        onCancel();
        setShowConfirmationModal(false);
      }
    } catch (error) {
      toast.error(error.message);
      // setError(error.message);
      // console.log(error.message);
      setShowConfirmationModal(false);
    }
  };

  const handleCancelConfirmation = () => {
    // Handle cancel action if needed
    setShowConfirmationModal(false);
  };

  const handleInputs = (fieldName, value) => {
    if (fieldName === "farmTo") {
      setSelectedFarm(value);
      setData((prevData) => ({
        ...prevData,
        [fieldName]: value,
        farmFrom: batchesFarm,
        userId: account.user.id,
        userName: account.user.firstname,
      }));
    } else if (fieldName === "batch") {
      setSelectedBatch(value);
      setData((prevData) => ({
        ...prevData,
        [fieldName]: value,
        farmFrom: batchesFarm,
        userId: account.user.id,
        userName: account.user.firstname,
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [fieldName]: value,
        farmFrom: batchesFarm,
        userId: account.user.id,
        userName: account.user.firstname,
      }));
    }
    console.log(data);
  };

  return (
    <React.Fragment>
      <Modal show={true} onHide={onCancel} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Move</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              {success && (
                <>
                  <Alert
                    variant="success"
                    onClose={() => setSuccess(null)}
                    dismissible
                  >
                    {success}
                  </Alert>
                </>
              )}
              {error && (
                <>
                  <Alert
                    variant="danger"
                    onClose={() => setError(null)}
                    dismissible
                  >
                    {error}
                  </Alert>
                </>
              )}
              <ToastContainer />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form onSubmit={handleGoat} ref={formRef}>
                <Row>
                  <Form.Group
                    as={Col}
                    md={4}
                    sm={12}
                    lg={4}
                    controlId="formBasicAddress"
                  >
                    <Form.Label>Batch To Move:</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md={8} sm={12} lg={8}>
                    <Select
                      options={batches}
                      onChange={(selectedOption) =>
                        handleInputs("batch", selectedOption.value)
                      }
                      isSearchable
                      name="batch"
                      required
                    />
                  </Form.Group>
                  {/* </Row> */}
                </Row>
                <Row>
                  <Form.Group
                    as={Col}
                    md={4}
                    sm={12}
                    lg={4}
                    controlId="formBasicAddress"
                  >
                    <Form.Label>Farm From:</Form.Label>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={8}
                    sm={12}
                    lg={8}
                    controlId="formBasicAddress"
                  >
                    <Form.Control
                      required
                      onChange={handleInput}
                      // onChange={handleInputs("farmFrom", batchesFarm)}
                      value={batchesFarm}
                      type="text"
                      name="farmFrom"
                      disabled
                      // required
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group
                    as={Col}
                    md={4}
                    sm={12}
                    lg={4}
                    controlId="formBasicAddress"
                  >
                    <Form.Label>Farm To:</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md={8} sm={12} lg={8}>
                    <Select
                      options={farmOptions}
                      onChange={(selectedOption) =>
                        handleInputs("farmTo", selectedOption.value)
                      }
                      isSearchable
                      name="farmTo"
                      required
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group
                    as={Col}
                    md={4}
                    sm={12}
                    lg={4}
                    controlId="formBasicAddress"
                  >
                    <Form.Label>Date:</Form.Label>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={8}
                    sm={12}
                    lg={8}
                    controlId="formBasicAddress"
                  >
                    <Form.Control
                      required
                      type="date"
                      onChange={(e) => handleInputs("recorded", e.target.value)}
                      name="recorded"
                      // disabled
                      // required
                    />
                  </Form.Group>
                </Row>
                <Modal.Footer>
                  {createPermission && <Button type="submit">Submit</Button>}
                  <Button variant="secondary" onClick={onCancel}>
                    Cancel
                  </Button>
                </Modal.Footer>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <ConfirmationModal
        show={showConfirmationModal}
        onHide={handleCancelConfirmation}
        onConfirm={handleConfirmation}
      />
    </React.Fragment>
  );
};

export default SamplePage;
