import React, { useEffect, useState } from "react";
import { Bar, Line, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Container, Row, Col, Spinner, Form, Button } from "react-bootstrap";
import config from "../../config";
import { subMonths, startOfMonth, endOfMonth, format } from "date-fns";
import axios from "axios";
import Card from "../../components/Card/MainCard";

// Register required components
ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ArcElement,
);

const AssetMetricsChart = () => {
  const [metrics, setMetrics] = useState({});
  const [metricsTotals, setMetricsTotals] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [graphDatas, setGraphDatas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [activeTab, setActiveTab] = useState("3m");

  const [categories, setCategories] = useState([]);
  const [totalCounts, setTotalCounts] = useState([]);
  const [totalCosts, setTotalCosts] = useState([]);
  const [paidTransactions, setPaidTransactions] = useState([]);
  const [unpaidTransactions, setUnPaidTransactions] = useState([]);
  const [pendingPayments, setPendingPayments] = useState([]);

  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;

  useEffect(() => {
    setTabDateRange("3m"); // Default range to last 3 months
  }, []);

  const setTabDateRange = (range) => {
    const today = new Date();
    let start, end;

    switch (range) {
      case "3m":
        start = subMonths(today, 3);
        end = today;
        break;
      case "6m":
        start = subMonths(today, 6);
        end = today;
        break;
      case "1y":
        start = subMonths(today, 12);
        end = today;
        break;
      case "2y":
        start = subMonths(today, 24);
        end = today;
        break;
      default:
        start = startOfMonth(today);
        end = endOfMonth(today);
    }

    setStartDate(format(start, "yyyy-MM-dd"));
    setEndDate(format(end, "yyyy-MM-dd"));
    setActiveTab(range);
  };

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/asset-metrics`, {
          params: {
            startDate: startDate, // Example start date
            endDate: endDate, // Example end date
          },
        });

        setMetrics(response.data?.metrics);
        setMetricsTotals(response.data?.totals);
        setTransactions(response.data?.transactions);
        setGraphDatas(response.data?.graphData);
        console.log(response.data);
      } catch (err) {
        console.error("Error fetching asset metrics", err);
      } finally {
        setLoading(false);
      }
    };

    fetchMetrics();
  }, [startDate, endDate]);

  useEffect(() => {
    if (metrics && metricsTotals && transactions) {
      const extractedCategories = Array.isArray(graphDatas)
        ? graphDatas.map((item) => item.type)
        : [];
      const extractedCounts = Array.isArray(graphDatas)
        ? graphDatas.map((item) => item.totalAssets)
        : [];
      const extractedCosts = Array.isArray(graphDatas)
        ? graphDatas.map((item) => Number(item.totalCost) || 0)
        : [];
      const extractedPaidTransactions = transactions.paid;
      const extractedUnPaidTransactions = transactions.unpaid;
      const extractedPendingPayments = transactions.pending;

      setCategories(extractedCategories);
      setTotalCounts(extractedCounts);
      setTotalCosts(extractedCosts);
      setPaidTransactions(extractedPaidTransactions);
      setUnPaidTransactions(extractedUnPaidTransactions);
      setPendingPayments(extractedPendingPayments);
    }
  }, [metrics, metricsTotals, transactions, startDate, endDate]);

  const assetTransactionData = {
    labels: ["Paid", "Pending", "Unpaid"], // Include "Unpaid"
    datasets: [
      {
        label: "Transactions",
        data: [paidTransactions, pendingPayments, unpaidTransactions], // Include Unpaid
        backgroundColor: ["#4CAF50", "#36A2EB", "#FF6384"], // Different colors
      },
    ],
  };

  const assetData = {
    labels: categories,
    datasets: [
      {
        label: "Total Cost",
        data: totalCosts,
        backgroundColor: "#ff6384",
      },
      {
        label: "Total Quantity",
        data: totalCounts,
        backgroundColor: "#36a2eb",
      },
      {
        label: "Total Vendors",
        data: metrics.totalVendors,
        backgroundColor: "#ffce56",
      },
      {
        label: "Paid Transactions",
        data: paidTransactions,
        backgroundColor: "#4bc0c0",
      },
      {
        label: "Pending Payments",
        data: pendingPayments,
        backgroundColor: "#9966ff",
      },
    ],
  };

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" variant="primary" />
        <p>Loading asset data...</p>
      </Container>
    );
  }

  return (
    <React.Fragment>
      <Row className="btn-page">
        <Col>
          <Card title="Filters">
            <div>
              <span className="pr-3">Filters:</span>
              {["3m", "6m", "1y", "2y"].map((range) => (
                <Button
                  key={range}
                  onClick={() => setTabDateRange(range)}
                  style={{
                    marginRight: "5px",
                    padding: "5px",
                    backgroundColor:
                      activeTab === range ? "#007bff" : "#f8f9fa",
                    color: activeTab === range ? "white" : "black",
                    border: activeTab === range ? "none" : "1px solid #ced4da",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  {range === "3m"
                    ? "Last 3 Months"
                    : range === "6m"
                    ? "Last 6 Months"
                    : range === "1y"
                    ? "Last 1 Year"
                    : "Last 2 Years"}
                </Button>
              ))}
              <span className="px-3">Date Range:</span>
              <Form.Label>
                From:{" "}
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Form.Label>
              <Form.Label style={{ marginLeft: "20px" }}>
                To:{" "}
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Form.Label>
            </div>
            <hr />
          </Card>
          <Row>
            <Col md={3}>
              <Card title="Total Assets">
                <p>{metricsTotals?.totalQuantity.toLocaleString()}</p>
              </Card>
            </Col>
            <Col md={3}>
              <Card title="Total Vendors">
                <p>{metricsTotals?.totalVendors}</p>
              </Card>
            </Col>
            <Col md={3}>
              <Card title="Avg Asset Cost">
                <p>
                  {metricsTotals?.avgAssetCost?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  })}
                </p>
              </Card>
            </Col>
            <Col md={3}>
              <Card title="Highest Priced Asset">
                <p>
                  {metricsTotals?.highestAssetCost?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  })}
                </p>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={6} style={{ display: "flex", flexDirection: "column" }}>
              <Card title="Total Assets Per Category">
                <div
                  style={{
                    flex: 1,
                    height: "100%",
                    maxHeight: "400px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Bar
                    data={{
                      labels: categories,
                      datasets: [
                        {
                          label: "Total Assets",
                          data: totalCounts,
                          backgroundColor: "rgba(75, 192, 192, 0.6)",
                        },
                      ],
                    }}
                  />
                </div>
              </Card>
            </Col>

            <Col md={6} style={{ display: "flex", flexDirection: "column" }}>
              <Card title="Overview">
                <div
                  style={{
                    flex: 1,
                    height: "100%",
                    maxHeight: "400px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Bar data={assetData} />
                </div>
              </Card>
            </Col>

            <Col
              md={6}
              style={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
              }}
            >
              <Card title="Cost Distribution">
                <div
                  style={{
                    flex: 1,
                    height: "100%",
                    maxHeight: "400px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pie
                    data={{
                      labels: categories,
                      datasets: [
                        {
                          label: "Total Cost",
                          data: totalCosts,
                          backgroundColor: [
                            "#ff6384",
                            "#36a2eb",
                            "#ffce56",
                            "#4bc0c0",
                            "#9966ff",
                          ],
                        },
                      ],
                    }}
                  />
                </div>
              </Card>
            </Col>
            <Col
              md={6}
              style={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
              }}
            >
              <Card title="Transactions">
                <div
                  style={{
                    flex: 1,
                    height: "100%",
                    maxHeight: "400px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pie data={assetTransactionData} />
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AssetMetricsChart;
