import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Row, Col, Form } from "react-bootstrap";
import PaymentForm from "./SalaryPay";
import config from "../../../config";
import axios from "axios";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { usePermissions } from "../../../permissions";
import DataTable from "react-data-table-component";

const PaymentManagement = () => {
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [payments, setPayments] = useState([]);
  const [salaryPayments, setSalryPayments] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedTotalPayableSalary, setSelectedTotalPayableSalary] =
    useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const { createPermission, deletePermission, editPermission } =
    usePermissions();
  const [searchText, setSearchText] = useState("");

  const generateTransactionId = () => {
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 1000);
    return `SF-${random}`;
  };

  const openPaymentForm = () => {
    setShowPaymentForm(true);
  };

  const onHide = () => {
    setShowPaymentForm(false);
  };

  const addPayment = (newPayment) => {
    setPayments([...payments, newPayment]);
    setShowPaymentForm(false);
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    axios
      .get(`${apiUrl}/salary_payment`)
      .then((response) => {
        const salaryData = response.data;
        setSalryPayments(salaryData);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, [salaryPayments, apiUrl]);

  const openConfirmModal = (
    paymentId,
    status,
    account,
    totalPayableSalary,
    employee,
  ) => {
    setSelectedPaymentId(paymentId);
    setSelectedAccount(account);
    setSelectedTotalPayableSalary(totalPayableSalary);
    setEmployee(employee);
    setShowConfirmModal(true);
  };

  const closeConfirmModal = () => {
    setSelectedPaymentId(null);
    setShowConfirmModal(false);
  };

  const openRejectModal = (paymentId) => {
    setSelectedPaymentId(paymentId);
    setShowRejectModal(true);
  };

  const closeRejectModal = () => {
    setSelectedPaymentId(null);
    setShowRejectModal(false);
  };

  const confirmPayment = (status, account, totalPayableSalary) => {
    // console.log('Confirm Payment Clicked');
    // console.log('Payment ID:', selectedPaymentId);
    // console.log('Status:', status);
    // console.log('Account:', account);
    // console.log('Total Payable Salary:', totalPayableSalary);
    axios
      .put(`${apiUrl}/salary_payment/confirm/${selectedPaymentId}`, {
        status: "Confirmed",
        account: account,
        totalPayableSalary: totalPayableSalary,
        transactionId: generateTransactionId(),
        employee: employee,
      })
      .then((response) => {
        if (response.data.message) {
          toast.success(response.data.message);
          closeConfirmModal();
        }
        console.log("Payment confirmed:", response.data);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message === "Payment already confirmed"
        ) {
          toast.warning("Payment already confirmed.");
          closeConfirmModal();
        } else if (error.response.data.message) {
          // Display a generic error message for other errors
          toast.error(error.response.data.message);
        }
        console.error("Error confirming payment:", error);
      });
  };

  const handleReject = (status) => {
    axios
      .put(`${apiUrl}/salary_payment/reject/${selectedPaymentId}`, {
        status: "Rejected",
      })
      .then((response) => {
        if (response.data.message) {
          toast.success(response.data.message);
          closeRejectModal();
        }
        console.log("Payment rejected:", response.data);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message === "Payment already confirmed"
        ) {
          toast.warning("Payment is already confirmed and cannot be rejected.");
          closeRejectModal();
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.message === "Payment already rejected"
        ) {
          toast.warning("Payment is already rejected.");
          closeRejectModal();
        } else {
          toast.error("An error occurred. Please try again later.");
        }
        console.error("Error rejecting payment:", error);
      });
  };

  const exportDataToCSV = () => {
    const csvData = filteredData.map((leave) => ({
      "Invoice No": leave.invoiceNumber,
      "Payment Date": new Date(leave.createdAt).toLocaleDateString(),
      Type: leave.paymentType,
      "Payment Month": leave.paymentMonth,
      "Payment Method": leave.payMethod,
      Notes: leave.notes,
      Amount: leave.totalPayableSalary,
      Status: leave.status,
    }));

    const csvHeaders = Object.keys(csvData[0]);
    const csvContent = [
      csvHeaders.join(","),
      ...csvData.map((row) =>
        csvHeaders.map((header) => row[header]).join(","),
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "payment_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const columns = [
    {
      name: "Receipt",
      selector: (row) => row?.invoiceNumber,
      cell: (row) => (
        <Link to={`payroll/salary_payment/${row.id}`}>{row.invoiceNumber}</Link>
      ),
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },

    {
      name: "Payment Date",
      selector: (row) => row.createdAt,
      cell: (row) => (
        <Link to={`payroll/salary_payment/${row.id}`}>
          {new Date(row.createdAt).toLocaleDateString()}
        </Link>
      ),
      sortable: true,
      wrap: true,
      minWidth: "120px",
    },

    {
      name: "Type",
      selector: (row) => row?.paymentType,
      cell: (row) => (
        <Link to={`payroll/salary_payment//${row.id}`}>
          <span
            className={`${
              row.paymentType === "Advance Salary"
                ? "label-blue"
                : row.paymentType === "Monthly Salary"
                ? "label-till"
                : "label-kink"
            }`}
          >
            {row.paymentType}
          </span>{" "}
        </Link>
      ),
      sortable: true,
      wrap: true,
      minWidth: "120px",
    },
    {
      name: "Month",
      selector: (row) => row?.paymentMonth,
      cell: (row) => (
        <Link to={`payroll/salary_payment//${row.id}`}>{row.paymentMonth}</Link>
      ),
      sortable: true,
      wrap: true,
      minWidth: "120px",
    },
    {
      name: "Method",
      selector: (row) => row?.payMethod,
      cell: (row) => (
        <Link to={`payroll/salary_payment//${row.id}`}>{row.payMethod}</Link>
      ),
      sortable: true,
      wrap: true,
      minWidth: "100px",
    },
    {
      name: "To",
      selector: (row) => row?.employee,
      cell: (row) => (
        <Link to={`payroll/salary_payment//${row.id}`}>{row.employee}</Link>
      ),
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "Amount",
      selector: (row) => row.totalPayableSalary,
      cell: (row) => (
        <Link to={`payroll/salary_payment//${row.id}`}>
          KES{" "}
          {row.totalPayableSalary !== null
            ? row.totalPayableSalary.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : ""}
        </Link>
      ),
      sortable: true,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => (
        <Link to={`payroll/salary_payment/${row.id}`}>
          <span
            className={`${
              row.status === "Pending"
                ? "label-kink"
                : row.status === "Rejected"
                ? "label-brown"
                : row.status === "Confirmed"
                ? "label-orange"
                : "label-kink"
            }`}
          >
            {row.status}
          </span>
        </Link>
      ),
      sortable: true,
      wrap: true,
      minWidth: "100px",
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <Button
            size="sm"
            onClick={() =>
              openConfirmModal(
                row.id,
                row.status,
                row.account,
                row.totalPayableSalary,
                row.employee,
              )
            }
          >
            Confirm
          </Button>
          <Button
            size="sm"
            variant="danger"
            onClick={() => openRejectModal(row.id, row.status)}
          >
            Reject
          </Button>
        </div>
      ),
      wrap: true,
      minWidth: "150px",
    },
  ];

  const filteredData = searchText
    ? salaryPayments.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchText.toLowerCase()),
        ),
      )
    : salaryPayments;

  return (
    <div>
      {createPermission && (
        <Button onClick={openPaymentForm} className="m-b-10">
          <span className="feather icon-file"></span> Add Record
        </Button>
      )}
      {showPaymentForm && (
        <PaymentForm
          show={showPaymentForm}
          onCancel={onHide}
          addPayment={addPayment}
        />
      )}

      <Row className="btn-page">
        <Col>
          {salaryPayments && salaryPayments.length > 0 && (
            <Row>
              <Form.Group
                as={Col}
                md={6}
                sm={12}
                lg={6}
                controlId="formBasicName"
              >
                <Form.Control
                  type="text"
                  placeholder="Search..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                md={6}
                sm={12}
                lg={6}
                controlId="formBasicName"
              >
                <div className="d-flex">
                  <Button onClick={exportDataToCSV}>
                    <span className="feather icon-file"></span> Export CSV
                  </Button>
                </div>
              </Form.Group>
            </Row>
          )}
          <Row>
            <Col>
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                highlightOnHover
                striped
                dense
                bordered
                paginationPerPage={20}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                paginationComponentOptions={{
                  rowsPerPageText: "Rows per page:",
                }}
                paginationResetDefaultPage={true}
                paginationTotalRows={salaryPayments.length}
                // expandableRows
                // expandableRowsComponent={ExpandableRow}
                selectableRows
                onSelectedRowsChange={(selectedRows) =>
                  console.log(selectedRows)
                }
                customStyles={{
                  headRow: {
                    style: {
                      backgroundColor: "#279e6c", // Green background
                      color: "#fff", // White text
                      textTransform: "uppercase", // Uppercase text
                      // fontSize: '10px', // Adjust font size for header row
                      minHeight: "1.5rem",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                    },
                  },
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal show={showConfirmModal} onHide={closeConfirmModal} centered>
        <Modal.Body>Are you sure you want to confirm this payment?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeConfirmModal}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() =>
              confirmPayment(
                "Confirmed",
                selectedAccount,
                selectedTotalPayableSalary,
              )
            }
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showRejectModal} onHide={closeRejectModal} centered>
        <Modal.Body>Are you sure you want to reject this payment?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeRejectModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleReject}>
            Reject
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PaymentManagement;
