import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Button,
  Pagination,
  Alert,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useTable, usePagination } from "react-table";
import config from "../../../config";
import { CSVLink } from "react-csv";
import axios from "axios";
import Card from "../../../components/Card/MainCard";
import DataTable from "react-data-table-component";

const SamplePage = () => {
  const { selectedFarm } = useParams();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [expenses, setExpenses] = useState({ totalExpenses: 0 });
  const [earnings, setEarnings] = useState([]);
  const [dailyEarnings, setDailyEarnings] = useState(0);
  const [weeklyEarnings, setWeeklyEarnings] = useState(0);
  const [monthlyEarnings, setMonthlyEarnings] = useState(0);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetch(`${apiUrl}/expenses?farm=${selectedFarm}`)
      .then((response) => response.json())
      .then((data) => setExpenses(data))
      .catch((error) => console.error("Error:", error));
  }, [selectedFarm]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/expenses/stats?period=daily&&farm=${selectedFarm}`)
      .then((response) => {
        setDailyEarnings(response.data.totalExpenses);
      })
      .catch((error) => {
        console.error("Error fetching daily earnings:", error);
      });

    axios
      .get(`${apiUrl}/expenses/stats?period=weekly&&farm=${selectedFarm}`)
      .then((response) => {
        setWeeklyEarnings(response.data.totalExpenses);
        console.log(response.data.totalExpenses);
      })
      .catch((error) => {
        console.error("Error fetching weekly earnings:", error);
      });

    axios
      .get(`${apiUrl}/expenses/stats?period=monthly&&farm=${selectedFarm}`)
      .then((response) => {
        setMonthlyEarnings(response.data.totalExpenses);
      })
      .catch((error) => {
        console.error("Error fetching monthly earnings:", error);
      });
  }, [selectedFarm]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/spend?farm=${selectedFarm}`,
        );
        setEarnings(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching expenses data:", error);
      }
    };

    fetchData();
  }, [selectedFarm]);

  const filteredData = earnings.filter((item) => {
    // Check if the item matches the search text
    const matchesSearchText =
      !searchText ||
      Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchText.toLowerCase()),
      );

    return matchesSearchText;
    // return matchesSearchText && matchesStatusFilter && matchesEventTypeFilter;
  });

  const csvData = filteredData.map((expense) => ({
    "Expense Type": expense.expense_type,
    Date: new Date(expense.createdAt).toLocaleDateString(),
    Description: expense.description,
    Amount: expense.amount,
    Quantity: expense.quantity,
    Account: expense.bankAccount,
  }));

  const columns = [
    {
      name: "Expense Type",
      selector: (row) => row?.expense_type,
      sortable: true,
      wrap: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      wrap: true,
    },
    {
      name: "quantity",
      selector: (row) => row.quantity,
      sortable: true,
      wrap: true,
    },
    {
      name: "Amount",
      selector: (row) =>
        row.amount !== null
          ? row.amount.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "",
      sortable: true,
      wrap: true,
    },
    {
      name: "Account",
      selector: (row) => row.bankAccount,
      sortable: true,
      wrap: true,
    },

    {
      name: "Timestamp",
      selector: (row) => new Date(row.createdAt).toLocaleString(),
      sortable: true,
      wrap: true,
    },
  ];

  return (
    <React.Fragment>
      <Row>
        <Col sm={12}>
          {success && (
            <Alert
              variant="success"
              onClose={() => setSuccess(null)}
              dismissible
            >
              {success}
            </Alert>
          )}
          {error && (
            <Alert variant="danger" onClose={() => setError(null)} dismissible>
              {error}
            </Alert>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col sm={12} md={6} lg={3}>
              <Card title="Today Expenses">
                <div className="row d-flex align-items-center">
                  <div className="col-12">
                    <h6>Ksh {dailyEarnings.toLocaleString()}</h6>
                  </div>
                </div>
              </Card>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <Card title="Weekly Expenses">
                <div className="row d-flex align-items-cente">
                  <div className="col-12">
                    <h6>Ksh {weeklyEarnings.toLocaleString()}</h6>
                  </div>
                </div>
              </Card>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <Card title="Monthly Expenses">
                <div className="row d-flex align-items-cente">
                  <div className="col-12">
                    <h6>Ksh {monthlyEarnings.toLocaleString()}</h6>
                  </div>
                </div>
              </Card>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <Card title="Expenses">
                <div className="row d-flex align-items-cente">
                  <div className="col-12">
                    <h6>Ksh {expenses.totalExpenses.toLocaleString()}</h6>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          <Link to="/farms/manage">
            <Button variant="primary" className="m-b-10">
              <span className="feather icon-arrow-left"></span> Go Back
            </Button>
          </Link>
          <Row>
            <Form.Group
              as={Col}
              md={4}
              sm={12}
              lg={4}
              controlId="formBasicName"
            >
              <Form.Control
                type="text"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Form.Group>
            {csvData.length > 0 && (
              <CSVLink
                data={csvData}
                headers={Object.keys(csvData[0])}
                filename="farm_expense.csv"
              >
                <Button>Export to CSV</Button>
              </CSVLink>
            )}
          </Row>
          <DataTable
            // title="Combined Email Logs"
            columns={columns}
            data={filteredData}
            // expandableRows
            // expandableRowsComponent={ExpandableComponent}
            pagination
            highlightOnHover
            striped
            // noHeader
            dense
            bordered
            paginationPerPage={20}
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100, 250, 500]}
            paginationComponentOptions={{
              rowsPerPageText: "Rows per page:",
            }}
            paginationResetDefaultPage={true}
            paginationTotalRows={earnings.length}
            // expandableRows
            // selectableRows
            onSelectedRowsChange={(selectedRows) => console.log(selectedRows)}
            customStyles={{
              headRow: {
                style: {
                  backgroundColor: "#279e6c", // Green background
                  color: "#fff", // White text
                  textTransform: "uppercase", // Uppercase text
                  // fontSize: '10px', // Adjust font size for header row
                  minHeight: "1.5rem",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                },
              },
            }}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SamplePage;
