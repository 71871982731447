import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTable, usePagination } from "react-table";
import { CSVLink } from "react-csv";
import config from "../../config";
import DataTable from "react-data-table-component";
import {
  Row,
  Col,
  Form,
  Button,
  Table,
  Alert,
  Tabs,
  Tab,
  Modal,
  Pagination,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

const FarmExpenses = () => {
  const environment =
    window.location.hostname === "localhost" ? "development" : "production";
  const apiUrl = config[environment].apiUrl;
  const [expenses, setExpenses] = useState([]);
  const { createdAt } = useParams();
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/spend`);
        setExpenses(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching expenses data:", error);
      }
    };

    fetchData();
  }, [expenses, apiUrl]);

  const filteredData = expenses.filter((item) => {
    // Check if the item matches the search text
    const matchesSearchText =
      !searchText ||
      Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchText.toLowerCase()),
      );

    return matchesSearchText;
    // return matchesSearchText && matchesStatusFilter && matchesEventTypeFilter;
  });

  const csvData = filteredData.map((expense) => ({
    // id: expense.id,
    "Expense Type": expense.expense_type,
    Date: new Date(expense.createdAt).toLocaleDateString(),
    Description: expense.description,
    Amount: expense.amount,
    Quantity: expense.quantity || 0,
    Account: expense.bankAccount,
    // Action: <Button>{expense.image}</Button>,
  }));

  const columns = [
    {
      name: "Expense Type",
      selector: (row) => row.expense_type,
      sortable: true,
      wrap: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      wrap: true,
    },
    {
      name: "quantity",
      selector: (row) => row.quantity,
      sortable: true,
      wrap: true,
    },
    {
      name: "Amount",
      selector: (row) =>
        row.amount !== null
          ? row.amount.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "",
      sortable: true,
      wrap: true,
    },
    {
      name: "Account",
      selector: (row) => (row.bankAccount ? row.bankAccount : "-"),
      sortable: true,
      wrap: true,
    },

    {
      name: "Timestamp",
      selector: (row) => new Date(row.createdAt).toLocaleString(),
      sortable: true,
      wrap: true,
    },
  ];

  return (
    <div>
      <Row>
        <Form.Group as={Col} md={4} sm={12} lg={4} controlId="formBasicName">
          <Form.Control
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Form.Group>
        {csvData.length > 0 && (
          <CSVLink
            data={csvData}
            headers={Object.keys(csvData[0])}
            filename="farm_expense.csv"
          >
            <Button>Export to CSV</Button>
          </CSVLink>
        )}
      </Row>
      <DataTable
        // title="Combined Email Logs"
        columns={columns}
        data={filteredData}
        // expandableRows
        // expandableRowsComponent={ExpandableComponent}
        pagination
        highlightOnHover
        striped
        // noHeader
        dense
        bordered
        paginationPerPage={20}
        paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100, 250, 500]}
        paginationComponentOptions={{
          rowsPerPageText: "Rows per page:",
        }}
        paginationResetDefaultPage={true}
        paginationTotalRows={expenses.length}
        // expandableRows
        // selectableRows
        onSelectedRowsChange={(selectedRows) => console.log(selectedRows)}
        customStyles={{
          headRow: {
            style: {
              backgroundColor: "#279e6c", // Green background
              color: "#fff", // White text
              textTransform: "uppercase", // Uppercase text
              // fontSize: '10px', // Adjust font size for header row
              minHeight: "1.5rem",
              paddingTop: "8px",
              paddingBottom: "8px",
            },
          },
        }}
      />
    </div>
  );
};

export default FarmExpenses;
